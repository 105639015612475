import { SHIPPING_TYPE } from "enums/shipping";
import { fullFormProp } from "components/molecules/order-forms/types";
import { comparativeMoreHeight } from "utils/calculations";

export const calculateAndGenerateItemsArray = (values: any, data: Partial<fullFormProp>, shippingType : SHIPPING_TYPE) => {
  const payable_weight = comparativeMoreHeight(
    values.weight!,
    values.width!,
    values.height!,
    values.depth!,
    shippingType
  );
    return  {payable_weight, newArray : [...data.package_information!]};
};
