import React, { forwardRef, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import TableBody from "./Table.Body";
import { TableProps } from "./Table.models";
import TableHead from "./Table.Head";
import TablePagination from "./Table.Pagination";
import { Paper } from "@mui/material";
import TableSectionHead from "./Table.SectionHead";
// import TableHead from './Table.Head'
// import type { Props } from './Table.types'
// import { lightGrey } from '@design/palette'
// import { useTable } from '@Organisms_Fortress/Table/Table.hooks'

const ShipalTable = forwardRef<HTMLDivElement, TableProps>(
  (
    {
      headers,
      height,
      isLoading,
      tableName,
      rows,
      sx,
      emptyStateMessage = "No items found",
      pagination,
      handleChange,
      listOrdersQuery,
      isCheckable,
      actionButtons,
    },
    ref
  ) => {
    const [selectedCells, setSelectedCell] = useState<string[]>([]);
    const handleSelectAllCells = (event: any) => {
      if (event?.target?.checked) {
        const newSelected = rows.map((n: any) => n._id);
        setSelectedCell(newSelected);
        return;
      }
      setSelectedCell([]);
    };
    const hasSectionHeaders = Boolean(selectedCells?.length);

    return (
      <>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {hasSectionHeaders && (
            <TableSectionHead
              rowsSelected={selectedCells}
              tableName={tableName}
              actionButtons={actionButtons}
            />
          )}
          <TableContainer
            id={`table_${tableName}`}
            sx={{
              height,
              borderRadius: 1,
              pointerEvents: isLoading ? "none" : "auto",
              position: "relative",
            }}
          >
            <MuiTable stickyHeader={true} aria-label="Table">
              <TableHead
                headers={headers}
                isCheckable={isCheckable}
                selectedCells={selectedCells}
                setSelectedCell={setSelectedCell}
                handleSelectAllCells={handleSelectAllCells}
                rowCount={rows.length}
              />
              <TableBody
                rows={rows}
                headers={headers}
                isCheckable={isCheckable}
                selectedCells={selectedCells}
                setSelectedCell={setSelectedCell}

              />
            </MuiTable>
          </TableContainer>
          <TablePagination
            pagination={pagination}
            handleChange={handleChange}
            listOrdersQuery={listOrdersQuery}
          />
        </Paper>
      </>
    );
  }
);

ShipalTable.defaultProps = {
  // footerRow: {},
  headers: [],
  rows: [],
  isLoading: false,
};
ShipalTable.displayName = "Table";

export default ShipalTable;
