import * as yup from "yup";

export const changePasswordSchema = yup.object({
  password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe incluir mínimo 8 caracteres."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir"),
});
