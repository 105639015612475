import { Image } from 'react-bootstrap';
import styled from 'styled-components';


export const ContainerLogo = styled.div`

`

export const StyledImage = styled(Image)`


` 