import { Stack, Typography, Link, Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { shipalLogo5, shopify } from "assets/imgs";
import { OrderType } from "services/serviceV2/orders/types";
import { OrderStatus } from "services/serviceV2/orders/types";
import { useHistory } from "react-router-dom";

type Props = {
  order: {
    _id: string;
    order_type: string;
    client_name: string;
    invoice_number: string;
    createdAt: string;
    order_status: OrderStatus;
    is_international_shippment: boolean;
  };
};

export const OrderInfoTableCell = ({ order }: Props) => {
  const history = useHistory();

  const imageStyles: React.CSSProperties = useMemo(
    () => ({
      maxWidth: "24px",
      objectFit: "contain",
      marginRight: 12,
    }),
    []
  );

  const handleSelectedOrder = useCallback(
    (isNational: boolean, id: string) =>
      history.push(
        `/shipping/${isNational ? "international" : "national"}/${id}`
      ),
    [history]
  );

  const linkStyle: React.CSSProperties = useMemo(
    () => ({
      fontWeight: "bold",
      cursor: "pointer",
      color: "#7E00FB",
    }),
    []
  );

  return (
    <Stack direction="row" alignItems="center" width={"100%"} height={"100%"}>
      <img
        alt="logo del origen de la orden"
        style={imageStyles}
        src={order?.order_type === OrderType.SHOPIFY ? shopify : shipalLogo5}
      />

      <Stack
        direction="column"
        justifyContent="center"
        textAlign="start"
        height={"100%"}
      >
        <Stack direction="row" alignItems="center" sx={{ minHeight: "70%" }}>
          {order.order_status === OrderStatus.ACEPTED ||
          order.order_status === OrderStatus.SCHEDULED ? (
            <Link
              href={`/order/result/${order._id}`}
              variant="caption"
              fontWeight="bold"
              sx={linkStyle}
            >
              #{order?.invoice_number ?? 0}
            </Link>
          ) : (
            <Link
              onClick={() =>
                handleSelectedOrder(order.is_international_shippment, order._id)
              }
              variant="caption"
              fontWeight="bold"
              sx={linkStyle}
            >
              #{order?.invoice_number ?? 0}
            </Link>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          marginTop={0}
          sx={{ minHeight: "30%" }}
        >
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ minHeight: "30%" }}
          >
            {dayjs(order?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
