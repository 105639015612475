import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "commons/form-provider";
import React from "react";
import { useForm } from "react-hook-form";
import { declaredValueSchema } from "./validation";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { InputText } from "commons/input-text";
import { styles } from "components/molecules/order-components/styles";

type Props = {
  declared_value: number;
  currentvalue: string;
  onSave: (values: any) => Promise<any>;
};

export const FormDeclaredValue = ({
  declared_value,
  currentvalue,
  onSave,
}: Props) => {
  const method = useForm({
    defaultValues: { declared_value },
    mode: "onChange",
    resolver: yupResolver(declaredValueSchema),
  });

  const { handleSubmit } = method;

  return (
    <FormProvider methods={method} onSubmit={handleSubmit(onSave)}>
        <DialogContent sx = {{padding : '2rem' , width: '22rem'}}>
        <Typography sx={styles.modal.title}>Valor declarado</Typography>

        <InputText label={currentvalue} size="small" name="declared_value" />
      </DialogContent>
      <DialogActions
        sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            margin: "1rem",
          }}
      
      >
       
          <Button type="submit" sx={{ color: "black", padding: '1rem' }} variant="text">
            Cancelar{" "}
          </Button>
          <Button type="submit" variant="contained" sx={{ padding: '1rem' }}>
            Aplicar{" "}
          </Button>
    
      </DialogActions>
    </FormProvider>
  );
};
