import { types } from "../../types";
const initialState = {
  bills: [],
  error: null,
  loading: true,
};

export const billsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.getbills:
      return {
        ...state,
        bills: payload,
        loading: false,
      };
    default:
      return state;
  }
};
