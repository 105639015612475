import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ShipperProps } from "components/molecules/order-forms/types";
import { labelsSelectByCountries } from "components/molecules/order-components/addressInfoCard/dictionary";
import { getCountries } from "services/serviceV2/orders";
import { FormActions } from "components/molecules/order-components/FormActions";
import { saveSetValueBySelectMultiValue } from "modules/draft/utils/general";
import { AddressBookInfoForm } from "components/molecules/address-book-info-form";
import { ModalTitle } from "components/molecules/order-components/ModalTitle";

import { styles } from "components/molecules/order-components/styles";
import { createAddress, updateAddress } from "services/serviceV2/addressBook";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressSchema } from "./validation";
import FormProvider from "commons/form-provider";
import { 
  Typography,
  Box,
  Dialog,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Checkbox,
  MenuItem
 } from "@mui/material";
 import { ADDRESS_TYPE } from 'enums/address';

interface Props {
  showForm: boolean;
  setToggleForm: () => void;
  currentAddress: Partial<ShipperProps>;
}

export const AddressBookForm = ({ showForm, setToggleForm, currentAddress }: Props) => {
  const queryClient = useQueryClient();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDefaultAddress, setDefaultAddress ] = useState(false);
  const [ addressType, setAddressType ] = useState<ADDRESS_TYPE>(ADDRESS_TYPE.SHIPPER);
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(addressSchema),
    defaultValues: currentAddress,
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

const { mutate, isError } = useMutation(
  async (data: Partial<ShipperProps>) => data._id ?
   updateAddress(data._id, data): createAddress(data),
  {
    onSuccess: (dat: any) =>
      {
        queryClient.invalidateQueries({ queryKey: ["address-book"] });
        setIsLoading(false);
        setToggleForm();
      },
    onError: (errordata) => {
      console.log(errordata)
      setToggleForm();
      setIsLoading(false);
    }
  }
);

if(isError){
  console.log("ERROR!!");
  console.log(isError)
}
const submitFn = (data: Partial<ShipperProps>) =>{
  setIsLoading(true);
  data.address_type = addressType;
  data.is_default_address = isDefaultAddress;
  mutate(data);
};

const { data: countries } = useQuery({
  queryKey: ["countries"],
  queryFn: getCountries,
});

const onChangeSelectByCountry = (_target: any, index: any, isDefault: boolean) => {
  saveSetValueBySelectMultiValue(
    setValue,
    isDefault? index.key: index.key.slice(2),
    labelsSelectByCountries,
    countries!
  );
};

useEffect(() => {
  if(countries) {
    onChangeSelectByCountry({}, {...countries[42], key: 42}, true);
  }
}, [])

const onChangeAddressType = (e: any, value: string) => {
  onChangeSelectByCountry({}, { key: 42 }, true);
  setAddressType(value as ADDRESS_TYPE);
}

// effect runs when user state is updated
useEffect(() => {
  // reset form with user data
  Object.keys(currentAddress).forEach(async (currentKey: string) => {
    const set  = await setValue(currentKey as keyof ShipperProps, currentAddress[currentKey as keyof ShipperProps]);
  })
  setAddressType(() => currentAddress.address_type!);
  setDefaultAddress(() => currentAddress.is_default_address!);
  if(countries) {
    console.log(countries.filter((cur: any) => !cur.countryName))
    if(currentAddress._id && currentAddress._id.length > 0) {
      saveSetValueBySelectMultiValue(
        setValue,
        countries.findIndex((curCountry: any)=> curCountry.countryName === currentAddress.country_name).toString(),
        labelsSelectByCountries,
        countries!
      );
    }
    else {
      onChangeSelectByCountry({}, {...countries[42], key: 42}, true);
    }

  }
}, [currentAddress, setValue, saveSetValueBySelectMultiValue, countries]);

  return (
    <Box>
      <Dialog sx={{ justifyContent: 'center', alignItems: 'center'}}  open={showForm} onClose={setToggleForm}>
          <Card>
            {currentAddress._id ?
                      <ModalTitle title={`Editando direccion: ${currentAddress.company_name}`}/>:
                       <ModalTitle title={'Crear Dirección'}/>
                    }
            <FormProvider  methods={methods} onSubmit={handleSubmit(submitFn)}>
            <Box sx={{  maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll', paddingBottom: 4 }}>
              <Box sx={{ padding: 2,  pl:3  }}>
                <Typography variant="h4">Tipo de Direccion</Typography>
              <RadioGroup
                  row
                  aria-labelledby="address_type"
                  name="address_type"
                  value={addressType}
                  onChange={onChangeAddressType}
                >
                  <FormControlLabel sx={{ mb: 0 }} key={ADDRESS_TYPE.SHIPPER} value={ADDRESS_TYPE.SHIPPER} control={<Radio />} label="Origen" />
                  <FormControlLabel sx={{ mb: 0 }} key={ADDRESS_TYPE.CONSIGNEE} value={ADDRESS_TYPE.CONSIGNEE}  control={<Radio />} label="Destino" />
                  <FormControlLabel sx={{ mb: 0 }} key={ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE} value={ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE} control={<Radio />} label="Ambos" />
                </RadioGroup>
              </Box>
              <Box sx={{ padding: 2,  }}>

                <AddressBookInfoForm methods={methods} addressType={addressType} isforAddressBook={true}  />
                  <Box sx={{ marginBottom: 1, pl:1 }}>
                    <FormControlLabel name="is_default_address"  disabled={addressType === ADDRESS_TYPE.CONSIGNEE} checked={isDefaultAddress} onChange={() => setDefaultAddress( prev=> !prev)}  control={<Checkbox />} label="Guardar como direccion principal" />
                  </Box>
                <FormActions isLoading={isLoading} isValid={methods.formState.isValid} handleClose={() => setToggleForm()}/>
                </Box>
                </Box>
            </FormProvider>
          </Card>
        </Dialog>
    </Box>
  );
};
