import { DrawCreatePage } from "modules/draft/page/create";
import { OrderResult } from "modules/order-result/page/result";
import DrawEditPage from "modules/draft/page/edit";
import DrawViewPage from "modules/draft/page/view";
import { OrdersPage } from "modules/orders/page";
import { AddressBookPage } from "modules/address-book/page";
import { Quoter } from "modules/quoter/page";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect } from "react-router-dom";
import { Loading } from "components/atoms/loading";
import { PageConfirm } from "components/pages/PSE/pageConfirm";
import { Account } from "components/pages/account";
import { QuotePage } from 'components/pages/quote-page';
import { UserWallet } from "components/pages/user-wallet";
import { getUserInfo, logout } from "store/auth/auth.actions";
import { getToken } from "store/auth/auth.selectors";
import { getNotebooks } from 'store/notebook/notebook.actions';
import { Providers,  Notifications, MyPayments } from 'components/pages/setting';
import { SecretKey } from 'components/pages/setting/secretkey'
import { PrivateRoute } from "./private-routes";
import { PublicRoute } from "./public-routes";
import { ActivateAccountPage } from "../components/pages/activation";
import { RecoverPasswordPage } from "../components/pages/recovery-password";
import { RestorePasswordPage } from "../components/pages/change-password";
import { VerifyAccountPage } from "../components/pages/verify";
import { Auth } from "../components/pages/auth";
import { Register } from  "../components/pages/auth/register";
import { hotjar } from 'react-hotjar';

const { REACT_APP_HJ_USER, REACT_APP_HJ_SITE_ID, REACT_APP_HJ_SNIPPET_VERSION, REACT_APP_AWS_BRANCH } = process.env;

export const AppRoutes = () => {
  const [cheking, setCheking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const isAuth = useSelector(getToken)

  useEffect(() => {
    if(isAuth){
      dispatch(getUserInfo());
      setIsLoggedIn(true);
    }else{
      dispatch(logout());
      setIsLoggedIn(false);
    }
    setCheking(false);
    dispatch(getNotebooks(1));

    if(REACT_APP_AWS_BRANCH === "prod") {
      hotjar.initialize(REACT_APP_HJ_SITE_ID, REACT_APP_HJ_SNIPPET_VERSION);

      // Identify the user
      hotjar.identify(REACT_APP_HJ_USER, { userProperty: 'value' });
  
      // Update SPA state
      hotjar.stateChange('/');
  
      // Check if Hotjar has been initialized before calling its methods
      if (hotjar.initialized()) {
        hotjar.identify(REACT_APP_HJ_USER, { userProperty: 'value' });
      }
    }
   
  }, [dispatch, isAuth]);

  if (cheking) {
    return <Loading title="Loading..." />;
  }

  return (
    <BrowserRouter>    
      <PrivateRoute
        exact
        strict
        path="/"
        component={() => <Redirect to="/shipments/realizados" />}
        isLoggedIn={isLoggedIn}
      /> 
      <PrivateRoute 
        path="/address-book"
        isLoggedIn={isLoggedIn}
        component={AddressBookPage}
      />  
      <PrivateRoute 
          isLoggedIn={isLoggedIn}
          path="/providers"
          component={Providers}/>

      <PrivateRoute 
          isLoggedIn={isLoggedIn}
          path="/wallet"
          component={MyPayments}/>
      
      <PrivateRoute 
          isLoggedIn={isLoggedIn}
          path ="/api-credentials"
          component={SecretKey}/>

      <PrivateRoute 
          isLoggedIn={isLoggedIn}
          path ="/notifications"
          component={Notifications}/>

      <PrivateRoute
        path="/account"
        isLoggedIn={isLoggedIn}
        component={Account}
      />
      <PrivateRoute
        path="/quote"
        isLoggedIn={isLoggedIn}
        component={QuotePage}
      />
      <PrivateRoute
        path="/quoter"
        isLoggedIn={isLoggedIn}
        component={Quoter}
      />
      <PrivateRoute
        path="/shipments/:type"
        isLoggedIn={isLoggedIn}
        component={OrdersPage}
      />
        <PrivateRoute
        exact
        path="/order/result/:orderId"
        isLoggedIn={isLoggedIn}
        component={OrderResult}
      />
        <PrivateRoute
        exact
        path="/shipping/:typeShipping"
        isLoggedIn={isLoggedIn}
        component={DrawCreatePage}
      />
      <PrivateRoute
        exact
        path="/shipping/:typeShipping/:orderId"
        isLoggedIn={isLoggedIn}
        component={DrawEditPage}
      />
      <PrivateRoute
        exact
        path="/view/:typeShipping/:orderId"
        isLoggedIn={isLoggedIn}
        component={DrawViewPage}
      />
      <PrivateRoute
        path="/user-wallet"
        isLoggedIn={isLoggedIn}
        component={UserWallet}
      />
      <PrivateRoute
        path="/payment/pse"
        isLoggedIn={isLoggedIn}
        component={PageConfirm}
      />
      <PublicRoute isLoggedIn={isLoggedIn} path="/activate-account/" component={ActivateAccountPage} />
      <PublicRoute isLoggedIn={isLoggedIn} path="/verify-account/" component={VerifyAccountPage} />
      <PublicRoute isLoggedIn={isLoggedIn} path="/recovery-password/" component={RecoverPasswordPage} />
      <PublicRoute isLoggedIn={isLoggedIn} path="/change-password/" component={RestorePasswordPage} />
      <PublicRoute isLoggedIn={isLoggedIn} path="/register/" component={Register} />
      <PublicRoute isLoggedIn={isLoggedIn} path="/login/" component={Auth} />
    </BrowserRouter>
  );
};
