import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

export const StyledRow = styled(Row)`
  font-family: Roboto-Regular;
`;
export const StyledColSwitch = styled(Col)`
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  input {
    cursor: pointer;
    background-color: red;
  }
`;

export const StyledUl = styled.ul`
  margin: 0px;
  padding: 0px;
  text-align: left;
`;
