import React, { forwardRef } from "react";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import { TableBodyContent, TableBodyProps } from "./Table.models";
import { Checkbox } from "@mui/material";

// import { table, blue } from '@design/palette'
// export const getHighlightedCellProps = (index: number) => {
//     const backgroundColor =
//         index % 2 === 0 ? table.highlightEven : table.highlightOdd
//     return { sx: { backgroundColor } }
// }

export const CustomTableCell = ({ row, headers }: TableBodyContent) => {
  return (
    <>
      {headers.map(({ id, renderComponent, colSpan, align, width }, index) => {
        const props = {};
        const content = renderComponent
          ? React.cloneElement(renderComponent(row))
          : "";
        return (
          <MuiTableCell
            key={`tableBodyCell_${id}_${index}`}
            variant="body"
            colSpan={colSpan}
            children={content}
            align={align}
            sx={{
              height: "80px",
              maxHeight: "80px",
              paddingRight: align && "4%",
              width: width,
            }}
          />
        );
      })}
    </>
  );
};
const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ rows, headers, isCheckable, selectedCells, setSelectedCell }) => {
    const isSelected = (name: string) => selectedCells.indexOf(name) !== -1;
    const handleClickSelectOrder = (
      event: React.MouseEvent<unknown>,
      id: string
    ) => {
      const selectedIndex = selectedCells.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selectedCells, id];
      } else {
        newSelected = selectedCells.filter(
          (selectedId: string) => selectedId !== id
        );
      }

      setSelectedCell(newSelected);
    };

    return (
      <MuiTableBody>
        {rows.map((row, index) => (
          <MuiTableRow
            key={`row-${index}`}
            role={isCheckable ? "checkbox" : "dataGrid"}
            aria-checked={isSelected(row._id)}
            selected={isSelected(row._id)}
          >
            <>
              {isCheckable ? (
                <MuiTableCell colSpan={1} padding="checkbox">
                  <Checkbox
                    color="primary"
                    onClick={(event) => handleClickSelectOrder(event, row._id)}
                    checked={isSelected(row._id)}
                    inputProps={{
                      "aria-labelledby": `tableCell-checkbox-${index}`,
                    }}
                    size="small"
                  />
                </MuiTableCell>
              ) : null}

              <CustomTableCell
                row={{ ...row, rowIndex: index }}
                headers={headers}
              />
            </>
          </MuiTableRow>
        ))}
      </MuiTableBody>
    );
  }
);

TableBody.defaultProps = {
  rows: [],
};

TableBody.displayName = "TableBody";
export default TableBody;
