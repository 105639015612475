import { client } from "utils/interceptors";
import { OrderStatus, ParamGetUserOrders } from "../orders/types";

export const getUser = async () =>
  (await client.get("/auth/user/me")).data?.data;

export const getUserOrders = async ({
  page = 1,
  size = 10,
  draftOrders = false,
}: ParamGetUserOrders) => {
  const url = draftOrders
    ? `/orders/my-orders?order_status=${OrderStatus.DRAFT}`
    : `/orders/my-orders?order_status=${OrderStatus.ACEPTED},${OrderStatus.SCHEDULED}`;

  const { data } = await client.get(url, {
    params: {
      limit: size,
      page: page,
    },
  });

  if (!Array.isArray(data.data)) {
    throw new Error("Cant't retrieve orders");
  }

  return data;
};

export const getUserOrdersLast = (page: number) => async () =>
  (
    await client.get(
      `/carriers/dhl/get-my-orders?limit=10&page=${page}`
    )
  ).data?.data;


export const bulkDelete = async(ids: string[]) => client.delete(
  `/orders/many`,
  {data: {
    orders: ids
  }}
);
