import { CardCarrier } from "..";



export const courierSelected = async (selected_carrier: any,couriers: CardCarrier[], setCarrierSelect: (value:any) => void, onSaveShipal :(values: CardCarrier) => Promise<any>  ) => {

    if (selected_carrier){
        const selectedCarrier = couriers.findIndex((courier: any) => courier.delivery! === selected_carrier);
        if (selectedCarrier > -1){
          setCarrierSelect(selectedCarrier)
          await onSaveShipal(couriers[selectedCarrier]);
            return true;
        }
      }
      return false;


}