export class ShipmentService {
    constructor(client) {
      this.client = client;
    }

    startCreateShipment = async (values) => {
      try {
        const { data } = await this.client.post(`/carriers/dhl/createorder`, values);
        return data;
      } catch (error) {
        alert("error");
        console.error();
        throw error;
      }
    };

    createDHLGuide = async (values) => {
      try {
        const { data } = await this.client.post(`/carriers/fedex/create-guide`, values);
        return data;
      } catch (error) {
        alert("error");
        console.error();
        throw error;
      }
    }

    createDraft = async (values) => {
      try {
        const { data } = await this.client.post(`/orders-draft/save-draft`, values);
        return data;
      } catch (error) {
        alert("error");
        console.error();
        throw error;
      }
    }

    getMyDrafts = async () => {
      try {
        const { data } = await this.client.get(`/orders-draft/get-my-drafts`);
        return data;
      } catch (error) {
        alert("error");
        console.log(error.response.data)
        console.error();
        throw error;
      }
    }

    getDraftById = async (id) => {
      try {
        const { data } = await this.client.get(`/orders-draft/get-draft-by-id/${id}`);
        return data;
      } catch (error) {
        alert("error");
        console.error();
        throw error;
      }
    }
  }
  