import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import FormProvider from "commons/form-provider";
import { InputSelect } from "commons/input-select";
import { InputText } from "commons/input-text";
import { exportReasons } from "constant/shippingConstants";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { fullFormProp, Export_declarationProps } from "components/molecules/order-forms/types";
import React, { useState, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { declareExportInternationalSchema } from "./validation";
import type { BoxProps, ButtonProps } from "@mui/material";

type Props = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
  handleModal?: () => void;
  isForQuote?: boolean;
};

export const InternationalDeclareExportForm = ({
  data,
  fnSave,
  handleModal,
  isForQuote
}: Props) => {
  const [ declaredValue, setDeclaredValue ] = useState(0);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ dataWithDeclaredValue, setDataWithDeclaredValue ] = useState<fullFormProp>(data as fullFormProp);
  const [ resData, setResData ] = useState<Export_declarationProps>(data.export_declaration!);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(declareExportInternationalSchema),
    defaultValues: {
      ...data.export_declaration,
      contents: data.contents,
      declared_value: data.declared_value,
    },
  });

  const { setValue, handleSubmit } = methods;
  
  const onSubmit = async (dataForSave: any) => {
    await fnSave({
      ...data, 
      contents: dataForSave.contents,
      declared_value:  dataForSave.declared_value,
    }, "export_declaration", {
      ...resData, 
      shipment_purpose: "COMMERCIAL",
      inter_consignee: "N/A",
      export_reason: dataForSave.export_reason,
      declared_value: dataForSave.declared_value,
      signature_name: data.shipper?.company_name? data.shipper.company_name: "N/A",
      invoice_number: data.invoice_number,
      export_reason_code: dataForSave.export_reason_code
    } as Export_declarationProps);

    handleModal && handleModal();
  }

  const save = async () => {
    await fnSave({
      ...data, 
      declared_value:  declaredValue,
    }, "export_declaration", {
      ...resData, 
      shipment_purpose: "COMMERCIAL",
      inter_consignee: "N/A",
      declared_value: declaredValue,
      signature_name: data.shipper?.company_name? data.shipper.company_name: "N/A",
    } as Export_declarationProps);
  };

  const onChangeSelect = (value: any, key: any) => {
    const exportReasonCode = exportReasons[Number(key.key.slice(2))].code;
    const exportReason = exportReasons[Number(key.key.slice(2))].label;
    setValue("export_reason_code", exportReasonCode);
    setValue("export_reason", exportReason);
  };

  const quoterDeclaredValue =  useCallback(async(event: any) => {
    await setDeclaredValue(() => event.target.value);
    await setDataWithDeclaredValue({
      ...data,
      declared_value: declaredValue,
    } as fullFormProp);
    setResData({
      ...resData,
      shipment_purpose: "COMMERCIAL",
      inter_consignee: "N/A",
      declared_value: declaredValue,
      signature_name: data.consignee?.company_name? data.consignee.company_name: "",
    });
    setIsDisabled(() => false);
  }, [declaredValue, setDeclaredValue])

  const boxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      display: "flex",
      gap: "1rem",
      justifyContent: "right",
    }),
    []
  );

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );
  
  return (
    <>
    {isForQuote?
    <Box display="flex" sx={boxStyles}>
        <InputText
        name="declared_value"
        size="small"
        label="Valor Declarado (USD)"
        required
        value={declaredValue}
        variant="outlined"
        onChange={(e) => {quoterDeclaredValue(e)}}
      />
         <LoadingButton onClick={save}  disabled={isDisabled}  loading={!data} type="button" variant="contained">
                  Aplicar
                </LoadingButton>
      </Box>
              :
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box>
          <Typography>
            Descripción especifica del contenido del paquete (Descripción debe
            ser en inglés)
          </Typography>
          <InputText name="contents" size="small" />
        </Box>
        <Box>
          <InputSelect
            name="export_reason"
            size="small"
            label="Razon del envio"
            onChange={onChangeSelect as any}
          >
            {exportReasons.map((reason, index) => (
              <MenuItem key={index} value={reason.label}>
                {reason.label}
              </MenuItem>
            ))}
          </InputSelect>
        </Box>
        <InputText
          name="declared_value"
          size="small"
          label="Valor Declarado (USD)"
          required
          variant="outlined"
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "end", m: 2 }}>
        {handleModal&&
        <Button type="button" sx={cancelButtonStyles} onClick={() => handleModal()} variant="text">
          Cancelar
        </Button>
        }
          
          <Button type="submit" variant="contained">
            {data.declared_value === 0 ? "Agregar" : "Confirmar"}
          </Button>
        </Box>
    </FormProvider>}
    </>
  );
};
