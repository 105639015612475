// Imports
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { loadScript } from "@paypal/paypal-js";
import { banks, methods, typePerson } from "../../../constant/data";
import { StyledButton, StyledModalHeader } from "./styles";

import { getUserData } from "../../../store/auth/auth.selectors";

// Components
import { BasicCard } from "../../atoms/basic-card";
import FormikInput from "../../molecules/formik-input";
import { MethodsPayments } from "../../organisms/wallet-methods-payments";
import { ResumeAbono } from "../../organisms/wallet-resume-abono";
import { Dashboard } from "../../templates/page-private-dashboard";

// API REDUX && UTILS
import { ApiService } from "../../../services";
import { useModal } from "../../../hooks/useModal";
import { paypalConfig } from "../../../utils/paypal";

export const UserWallet = () => {
  const user = useSelector(getUserData);
  const history = useHistory();
  const balanceAbonar = history.location.state?.value;
  const errMessage = "Campo obligatorio";
  const [show, onToggle, ContainerModal] = useModal(false);
  const [showModalPaypal, onTogglePaypal, ContainerModalPaypal] =
    useModal(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [selectedMethodPSE, setSelectedMethodPSE] = useState(false);
  const [selectedMethodPaypal, setSelectedMethodPaypal] = useState(false);
  const [balanceAbonarPaypal, setBalanceAbonarPaypal] = useState({
    balanaceUsd: 0,
    balanaceFee: 0,
    fee: 0,
    total: 0,
    currency: "USD",
    currencyUSD: 0,
  });
  const paypalFee = 8;

  const PaymentFormSchema = Yup.object().shape({
    bank: Yup.string().required(errMessage),
    value: Yup.string().required(errMessage),
    docType: Yup.string().required(errMessage),
    docNumber: Yup.string().required(errMessage),
    name: Yup.string().required(errMessage),
    lastName: Yup.string().required(errMessage),
    email: Yup.string().email().required(errMessage),
    cellPhone: Yup.string().required(errMessage),
  });

  useEffect(() => {
    const {
      location: {
        state: { value },
      },
    } = history;

    if (!value) {
      history.push("/shipments/realizados");
    }
  }, [history]);

  const getIp = useCallback(async () => {
    const { data } = await axios.get(`https://api.ipify.org?format=json`);
    return data?.ip;
  }, []);

  const handleOnclink = (method) => {
    setSelectedMethod(method);
    if (method.name === "PSE") {
      if (selectedMethodPSE) {
        setSelectedMethodPSE(false);
      } else {
        setSelectedMethodPSE(true);
        setSelectedMethodPaypal(false);
        setBalanceAbonarPaypal({
          balanaceUsd: 0,
          balanaceFee: 0,
          fee: 0,
          total: 0,
          currency: "USD",
          currencyUSD: 0,
        });
      }
    } else {
      setSelectedMethodPSE(false);

      if (!selectedMethodPaypal) {
        ApiService.WalletService.getLastCurrency().then(({ data }) => {
          const { currencyUSD } = data.data;
          const _balancePayUsd =
            Math.round((balanceAbonar / currencyUSD) * 100) / 100;
          const _balanceFee = Math.round(_balancePayUsd * paypalFee) / 100;
          const _totalBalancePaypal =
            Math.round((_balancePayUsd + _balanceFee) * 100) / 100;

          setBalanceAbonarPaypal({
            balanaceUsd: _balancePayUsd,
            balanaceFee: _balanceFee,
            fee: paypalFee,
            total: _totalBalancePaypal,
            currency: "USD",
            currencyUSD: currencyUSD,
          });

          setSelectedMethodPaypal(true);
          console.log(paypalConfig.clientId);
          loadScript({
            "client-id": paypalConfig.clientId,
            currency: "USD",
          })
            .then((paypal) => {
              paypal
                .Buttons({
                  createOrder: function (data, actions) {
                    return actions.order.create({
                      payer: {
                        email_addressstring: user.email,
                        name: {
                          given_name: user.firstName,
                          surname: user.lastName,
                        },
                      },
                      purchase_units: [
                        {
                          amount: {
                            value: _totalBalancePaypal,
                          },
                        },
                      ],
                    });
                  },
                  onApprove: (data, actions) =>
                    actions.order.capture().then(function (details) {
                      ApiService.AuthService.paymentPaypal({
                        transactionId: data.orderID,
                        order: {
                          id: data.orderID,
                          payerId: data.payerID,
                          paymentId: data.paymentID,
                          createTime: details.create_time,
                          updateTime: details.update_time,
                          amount: {
                            currency: "USD",
                            value: balanceAbonarPaypal.total,
                            fee: balanceAbonarPaypal.fee,
                            balanaceFee: balanceAbonarPaypal.balanaceFee,
                          },
                          balancePayment: {
                            currency: "COP",
                            value: balanceAbonar,
                          },
                        },
                        payer: {
                          name: {
                            givenName: details.payer.name.given_name,
                            surname: details.payer.name.surname,
                          },
                          emailAddress: details.payer.email_address,
                          payerId: details.payer.payer_id,
                          address: {
                            addressLine1: details.payer.address.address_line_1,
                            adminArea2: details.payer.address.admin_area_2,
                            adminArea1: details.payer.address.admin_area_1,
                            postalCode: details.payer.address.postal_code,
                            countryCode: details.payer.address.country_code,
                          },
                        },
                        links: details.links,
                      }).then(() => {
                        onTogglePaypal();
                      });
                    }),
                })
                .render("#paypal-container-button")
                .catch((error) => {
                  console.error("failed to render the PayPal Buttons", error);
                });
            })
            .catch((error) => {
              console.error("failed to load the PayPal JS SDK script", error);
            });
        });
      } else {
        let paypalContainer = document.getElementById(
          "paypal-container-button"
        );
        while (paypalContainer.firstChild) {
          paypalContainer.removeChild(paypalContainer.firstChild);
        }
        setSelectedMethodPaypal(false);
      }
    }
  };

  return (
    <Dashboard PageName="Mi Billetera" className="px-5 pt-3">
      <ResumeAbono
        balanceAbonar={balanceAbonar}
        balanceAbonarPaypal={balanceAbonarPaypal}
        paypalFee={paypalFee}
        selectedMethod={selectedMethod}
      />

      <MethodsPayments
        methods={methods.filter(
          (method) =>
            (method.code === "pse" && user?.merchants.pse) ||
            (method.code === "paypal" && user?.merchants.paypal)
        )}
        setSelectedMethod={handleOnclink}
        selectedMethod={selectedMethod}
      />
      {selectedMethodPaypal && <div id="paypal-container-button" />}
      {showModalPaypal && (
        <ContainerModalPaypal
          size="md"
          scrollable={true}
          centered={true}
          animation={true}
          typeModal="information"
        >
          <StyledModalHeader />
          <div
            style={{
              display: "flex",
              flexDirection: "Column",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <h3>
              <strong>Información:</strong>
            </h3>
            <p align="justify">
              Gracias por confiar en <b purple>SHIPAL</b>, estamos verificando
              su pago.
              <br />
              Si la operación fue exitosa el saldo puede tardar{" "}
              <b>hasta 10 min</b> en verse reflejado.
              <br />
              Si tienes alguna pregunta por favor <b>comunícate con soporte</b>.
            </p>
            <StyledButton
              regular
              purple
              type="button"
              onClick={() =>
                window.open(
                  ` https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}&text=Hola!%20%0A%F0%9F%A4%91%20%20Tengo%20dudas%20sobre%20mi%20transacci%C3%B3n%20%F0%9F%93%A6%0A%F0%9F%9A%9A%20%20att%3A%20${user.firstName}%0AId%20de%20usuario%3A%${user._id}`,
                  "_blank"
                )
              }
            >
              Hablar con soporte por whatsapp
            </StyledButton>
            <StyledButton
              regular
              orange
              type="button"
              onClick={
                () =>
                  history.push({
                    pathname: "/users/",
                  }) /* window.location.href = paymentResponse?.urlbanco */
              }
            >
              Volver al inicio
            </StyledButton>
          </div>
        </ContainerModalPaypal>
      )}

      {selectedMethodPSE && (
        <BasicCard height="auto" width="auto">
          <Formik
            initialValues={{
              bank: "1001",
              value: history.location.state?.value,
              docType: "",
              docNumber: "",
              name: "",
              lastName: "",
              email: "",
              cellPhone: "",
            }}
            onSubmit={async (v, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              try {
                let ip = await getIp();
                const res = await ApiService.AuthService.paymentPse({
                  ...v,
                  ip,
                });
                setPaymentResponse(res.data.data.transactionRequest.data);
                onToggle();
                setSubmitting(false);
              } catch (err) {
                console.log(v, err);
                setSubmitting(false);
              }
            }}
            validationSchema={PaymentFormSchema}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <>
                <Row>
                  <FormikInput
                    as="select"
                    InputFullWidth
                    label="Entidad Bancaria"
                    name="bank"
                  >
                    <option value="default">Tu banco</option>
                    {banks.map((item) => (
                      <option key={item.bankCode} value={item.bankCode}>
                        {item.bankName}
                      </option>
                    ))}
                  </FormikInput>
                  <FormikInput
                    InputFullWidth
                    label="Nombre del Titular"
                    type="text"
                    name="name"
                  />
                  <FormikInput
                    InputFullWidth
                    label="Apellido del Titular"
                    type="text"
                    name="lastName"
                  />
                  <FormikInput
                    as="select"
                    InputFullWidth
                    label="Tipo de cliente"
                    name="docType"
                  >
                    <option value="default">Tipo de cliente</option>
                    {typePerson.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </FormikInput>
                  <FormikInput
                    InputFullWidth
                    label="Número Documento"
                    type="text"
                    name="docNumber"
                  />
                  <FormikInput
                    InputFullWidth
                    label="Número Teléfono"
                    type="text"
                    name="cellPhone"
                  />
                  <FormikInput
                    InputFullWidth
                    label="Correo Electrónico"
                    type="email"
                    name="email"
                  />
                </Row>
                <StyledButton
                  disabled={isSubmitting}
                  medium
                  purple
                  type="submit"
                  onClick={handleSubmit}
                >
                  {!isSubmitting ? (
                    "Pagar"
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </StyledButton>
              </>
            )}
          </Formik>
        </BasicCard>
      )}
      {show && (
        <ContainerModal
          size="sm"
          scrollable={true}
          centered={true}
          animation={true}
          typeModal="information"
        >
          <StyledModalHeader closeButton />
          <div
            style={{
              display: "flex",
              flexDirection: "Column",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <h3>Verifica la información</h3>
            <br />
            <>
              <strong>Monto a pagar:</strong>{" "}
              {paymentResponse?.valor || "Payment Response"}
              {paymentResponse?.moneda || "Payment Response"}
            </>
            <br />
            <StyledButton
              regular
              purple
              type="button"
              onClick={() =>
                /* history.push({ pathname: "/payment/pse"}) */ (window.location.href =
                  paymentResponse?.urlbanco)
              }
            >
              Redireccionar al banco
            </StyledButton>
          </div>
        </ContainerModal>
      )}
    </Dashboard>
  );
};
