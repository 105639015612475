import { Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";
import { colors } from '../../../constant/branding';


export const StyledContainer = styled(Tabs)`
  border-bottom: 1px solid #934290;
  .nav-item {
    font-family: Roboto-Medium;
    margin-right: 2rem;
    margin-bottom: 0;
    border: none;
    text-decoration: none;
    color: #000000;
    &:hover {
      border: none;
    }
  }
  .nav-link.active {
    color: ${colors.purpule};
    border-bottom: 11px solid ${colors.purpule};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  .nav-link {
    padding: 0px;
  }
`;

export const StyledTab = styled(Tab)`
  text-decoration: none;
  text-transform: capitalize;
`;
