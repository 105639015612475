import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Link,
  Alert
} from "@mui/material";
import { Due_diligenceProps } from "components/molecules/order-forms/types";
import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import OrderActionsButton from "components/atoms/order-actions-button";
import { OrderActionsAlertDto } from 'components/atoms/order-actions-button/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  currentData:any;
  saving: boolean;
  currentPaths: {
    path: string;
    title: string;
  }[];
};

export const HorizontalBar = ({
  saving,
  currentData,
  currentPaths
}: Props) => {
  const history = useHistory();
  const [, setActiveStep] = useState(0);
  const validationSteps = (value : Due_diligenceProps) : number =>{
    let flag = 0;
    if (value.consignee_completed && value.shipper_completed && value.minimum_items_filled){
      flag = 1;
    }
    if (value.carrier_selected){
      flag = 2;
    }

    return flag;


  }
  const [ actionsState, setActionsState ] = useState<OrderActionsAlertDto>({
    showAlert: false,
    alertType: "warning"
  });

  
  const handleSetActionsState = useCallback((data: OrderActionsAlertDto) => {
    setActionsState({showAlert: data.showAlert, alertMessage: data.alertMessage, alertType: data.alertType});
  }, [actionsState, setActionsState]);

  useEffect(() => {
    
    if (currentData?.due_diligence){
      const nextStep = validationSteps(currentData.due_diligence);
      setActiveStep(nextStep);
    }
    

  }, [currentData])
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={2}
      >
      <Breadcrumbs aria-label="breadcrumb" sx={{
        "& .MuiBreadcrumbs-separator": {
          marginX: "4px",
        },
      }}>
        {currentPaths.map((path, i) =>
          <Link underline="hover" key={i} color="inherit" href={path.path}>
          {path.title}
        </Link>
        )}
      </Breadcrumbs>
    
      <Box
      sx = {{
        display:'flex',
        gap:'1rem'

      }}
      >
        {!saving && (
          <Chip
            avatar={
              <Avatar alt="icon">
                <CircularProgress color="info" size={20} />
              </Avatar>
            }
            label="Saving!"
            variant="outlined"
          />
        ) 
        }
      <Button component="button" onClick={() => history.push("/shipments/realizados")} variant="outlined">Cerrar</Button>
      <Grid item xs={2} textAlign={'right'}>
        <OrderActionsButton orderId={currentData?._id} handleChangeAlert={handleSetActionsState} />
      </Grid>
      </Box>
    </Grid>
    <Grid item xs={7} marginBottom={2} marginTop={2} sx={{ paddingLeft: 0 }}>
        {actionsState.showAlert &&
            <Alert 
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setActionsState(() => ({
                    showAlert: false
                  }));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
              sx={{ marginLeft: 0 }} severity={actionsState.alertType}>{actionsState.alertMessage}</Alert>
          }
      </Grid>
    </Grid>
    
  );
};
