import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ContainerModal = styled(Modal)`
  
  ${({ typeModal }) => {
    switch (typeModal) {
      case "information":
        return `
        .modal-content {
         border-radius: 20px;
         background-clip: inherit;
        }
        `;
      case "form":
        return `
        .modal-content {
          width: 650px;
          border-radius: 20px;
          padding: 0px 2.5em 0px 2.5em;
          background-clip: inherit;
        }
        .modal-dialog-centered {
          justify-content: center;
        }
        
        `;
      default:
        break;
    }
  }}
`;
