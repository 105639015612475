import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, Typography, Alert } from "@mui/material";
import FormProvider from "commons/form-provider";
import { InputSelect } from "commons/input-select";
import { InputText } from "commons/input-text";
import { DEFAULTVALUE, boxTypes } from "constant/shippingConstants";
import { SHIPPING_TYPE } from "enums/shipping";
import { fullFormProp } from "components/molecules/order-forms/types";
import { setValueSave } from "modules/draft/utils/packagesDetails";
import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { styles } from "../styles";
import { calculateAndGenerateItemsArray } from "./tools";
import { PackageDetailSchema } from "./validation";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import type { ButtonProps } from "@mui/material";

type Props = {
  indexEdit: number;
  data: Partial<fullFormProp>;
  shippingType: SHIPPING_TYPE;
  handleModal: () => void;
  fnSave: (items: any) => Promise<any>;
};

export const FormPackageDetails = ({
  data,
  indexEdit,
  shippingType,
  handleModal,
  fnSave,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedPacakgeIndex, setSelectedPackageIndex] = useState(0);

  const defaultValues =
    indexEdit === -1 ? DEFAULTVALUE : data?.cart_items_information![indexEdit];

  const methods = useForm({
    resolver: yupResolver(PackageDetailSchema),
    defaultValues,
  } as any);

  const { reset, setValue, handleSubmit, register, watch } = methods;

  const handleSelectAutoComplete = (
    _value: any,
    key: any,
    isEdit: boolean = false
  ) => {
    const indexPackage = isEdit
      ? data.package_information![key]
      : Number(key.key.slice(2));
    setSelectedPackageIndex(isEdit ? key : indexPackage);
    setValueSave(
      setValue,
      data?.package_information?.length || 0,
      setIsDisabled,
      indexPackage
    );
  };

  const onSubmitEdit = async (values: any) => {
    try {
      const { newArray: newItemInformation, payable_weight } =
        calculateAndGenerateItemsArray(values, data, shippingType);

      const changeItem = {
        ...newItemInformation[indexEdit],
        ...values,
        package_type: boxTypes[selectedPacakgeIndex].package_type,
        payable_weight,
      };
      newItemInformation[indexEdit] = changeItem;
      handleModal();
      reset();
      return await fnSave(newItemInformation);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitCreate = async (values: any) => {
    try {
      const { newArray: newItemInformation, payable_weight } =
        calculateAndGenerateItemsArray(values, data, shippingType);
      console.log(boxTypes);

      newItemInformation.push({
        ...values,
        package_type: boxTypes[selectedPacakgeIndex].package_type,
        payable_weight,
      });
      handleModal();
      reset();
      return await fnSave(newItemInformation);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (indexEdit > -1) {
      handleSelectAutoComplete(
        data?.package_information![indexEdit].piece_contents,
        indexEdit,
        true
      );
    }
  }, [indexEdit]);

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(indexEdit === -1 ? onSubmitCreate : onSubmitEdit)}
    >
      <InputSelect
        label="Tipo de embalaje"
        id="typePackage-select"
        size="small"
        {...register("piece_contents", {
          required: "Seleccione un tipo de embalaje",
        })}
        onChange={handleSelectAutoComplete as any}
      >
        {boxTypes?.map((typePackage, index) => (
          <MenuItem value={typePackage.piece_contents} key={index}>
            {typePackage.piece_contents}
          </MenuItem>
        ))}
      </InputSelect>

      <hr />
      <Box>
        <Typography sx={styles.modal.title}>Medidas </Typography>
        <Box
          sx={{
            ...styles.modal.fieldsRow,
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <InputText
            disabled={isDisabled}
            name="height"
            sx={styles.modal.smallTexfields}
            InputLabelProps={{ shrink: !!watch("height") }}
            label={"Alto (cm)"}
            type="number"
            size="small"
          />
          <Typography>X</Typography>
          <InputText
            disabled={isDisabled}
            name="depth"
            sx={styles.modal.smallTexfields}
            InputLabelProps={{ shrink: !!watch("depth") }}
            label={"Largo (cm)"}
            type="number"
            size="small"
          />
          <Typography>X</Typography>

          <InputText
            disabled={isDisabled}
            name="width"
            sx={styles.modal.smallTexfields}
            label={"Ancho (cm)"}
            type="number"
            size="small"
            InputLabelProps={{ shrink: !!watch("width") }}
          />
        </Box>
        <InputText
          name="weight"
          label="Peso (kg)"
          id="size"
          size="small"
          type="number"
          sx={{ width: "50%" }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "end", m: 2 }}>
        <Button
          variant="text"
          type="button"
          sx={cancelButtonStyles}
          onClick={handleModal}
        >
          Cancelar
        </Button>
        <Button type="submit" variant="contained">
          {indexEdit === -1 ? "Agregar" : "editar"}
        </Button>
      </Box>
      <Alert
        sx={{ mb: 4, background: "#E5F2FA" }}
        icon={<InfoOutlinedIcon fontSize="inherit" />}
        severity="info"
      >
        <Typography>
          <b>¡Recuerda!</b>
        </Typography>
        <Typography mt={1}>
          La información de pesos, medidas y contenido del envío deben ser
          reales y exactas, cualquier error o falta de información puede causar
          retraso en el tránsito del paquete, sobrecargos en el servicio,
          devolución o incluso decomiso.
        </Typography>
        <Typography mt={1}>
          Te recomendamos verificar toda la información antes de realizar la
          guía para evitar cualquier problema con tu envío.
        </Typography>
      </Alert>
    </FormProvider>
  );
};
