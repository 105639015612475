import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
//New Store 
import { authReducer } from "./auth/auth.reducers";
import { notebookReducer } from './notebook/notebook.reducers'
import { shipmentReducer } from './shipment/shipment.reducers';
//Old Store 
import { registerShippingReducer } from "./redux/reducers/registerShipping";
// import { shipmentsReducer } from "./redux/reducers/shipments.reducer";
import { billsReducer } from "./redux/reducers/bills.reducer";
import { ordersReducer } from "./redux/reducers/order.reducer";
import { paymentPSEReducer } from "./redux/reducers/payment.reducer";
import { storeReducer } from "./redux/reducers/store.reducer";
import { packingReducer } from "./redux/reducers/packing.reducer";

export const AppReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    notebooks: notebookReducer,
    shipment: shipmentReducer,
    // Old Store
    registerShipping: registerShippingReducer,
    bills: billsReducer,
    orders: ordersReducer,
    packings: packingReducer,
    stores: storeReducer,
    payment: paymentPSEReducer,
  });
