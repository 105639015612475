import styled from "styled-components";
import { colors } from "../../../constant/branding";
const purple = colors.purpule;
const orange = colors.orange;
const grey = "grey";

export const StyledButton = styled.button`
  ${(props) => {
    if (props.justText) {
      return `border: none`;
    } else if (props.orange) {
      return `border-color: ${orange};`;
    } else if (props.purple) {
      return `border-color: ${purple};`;
    }
  }}
  ${(props) => {
    if (props.disabled) {
      return `color: white;
              background-color: ${grey};`;
    } else if (props.orange && !props.outline) {
      return `background-color: ${orange};
              color: white;`;
    } else if (props.purple && props.outline) {
      return `color:  ${purple};
              background-color: white;`;
    } else if (props.orange && props.outline) {
      return `color: ${orange};
              background-color: white;`;
    } else if (props.purple && !props.outline) {
      return `color: white;
              background-color: ${purple};`;
    }
  }}

  ${(props) => {
    if (props.bold) {
      return `font-family: Roboto-Bold;`;
    } else if (props.italic) {
      return `font-family: Roboto-Italic;`;
    } else if (props.medium) {
      return `font-family: Roboto-Medium;`;
    } else if (props.regular) {
      return `font-family: Roboto-Regular;`;
    }
  }}
  ${(props) => {
    if (props.disabled) return;
    if (props.purple) {
      return `
      &:hover {
        background-color: #6500c9;
        color: white;
      }
      `;
    } else if (props.justText) {
      return `
     &:hover {
        background-color: ${purple};
        color: white;
      }
      `;
    } else {
      return `
     &:hover {
        background-color: #D92A57;
        color: white;
      }
      `;
    }
  }}
  
  font-weight: bold;
  letter-spacing: 0;
  ${(props) => !props.justText && "border: 1px solid;"}
  border-radius: 5px;
`;
