export const exportReasons = [
  {
    label: "Ventas",
    code: "C",
  },
  {
    label: "Muestras",
    code: "S",
  },
  {
    label: "Regalo",
    code: "G",
  },
  {
    label: "Retorno por repararción",
    code: "R",
  },
  {
    label: "Devolución a origen",
    code: "U",
  },
  {
    label: "Uso personal",
    code: "E",
  },
  {
    label: "Exportación temporal",
    code: "T",
  },
  {
    label: "Devolución de bienes de exhibición",
    code: "M",
  },
  {
    label: "Use interno de la empresa",
    code: "I",
  },
  {
    label: "Material para defensa",
    code: "F",
  },
  {
    label: "Bienes diplomaticos",
    code: "D",
  },
  // {
  //   label: 'Permanente',
  //   code: 'P'
  // },
  // {
  //   label: 'Garantía',
  //   code: 'W'
  // },
];

export const boxTypes = [
  {
    piece_ID: "",
    package_type: "OD",
    weight: "0",
    width: "0",
    height: "0",
    depth: "0",
    piece_contents: "Propio embalaje",
  },
  /*   {
    piece_ID: '',
    package_type: 'OD',
    weight: '',
    width: '',
    height: "",
    depth: "",
    piece_contents: "Propio embalaje" 
  }, */
  {
    piece_ID: "",
    package_type: "OD",
    weight: "2",
    width: "29.5",
    height: "40",
    depth: "1",
    piece_contents: "DHL Flyer Small",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "3",
    width: "37.5",
    height: "49",
    depth: "1",
    piece_contents: "DHL Flyer Large",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "1",
    width: "33.7",
    height: "18.2",
    depth: "10",
    piece_contents: "DHL CAJA 2",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "2",
    width: "33.7",
    height: "32.2",
    depth: "10",
    piece_contents: "DHL CAJA 3",
  },
  {
    piece_ID: "OD",
    package_type: "OD",
    weight: "5",
    width: "33.7",
    height: "32.2",
    depth: "18",
    piece_contents: "DHL CAJA 4",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "10",
    width: "33.7",
    height: "32.2",
    depth: "34.5",
    piece_contents: "DHL CAJA 5",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "15",
    width: "41.7",
    height: "36.9",
    depth: "35.9",
    piece_contents: "DHL CAJA 6",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "20",
    width: "48.1",
    height: "40.4",
    depth: "38.9",
    piece_contents: "DHL CAJA 7",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "25",
    width: "54.1",
    height: "44.4",
    depth: "40.9",
    piece_contents: "DHL CAJA 8",
  },
  {
    piece_ID: "",
    package_type: "isPriority",
    weight: "",
    width: "39",
    height: "30.5",
    depth: "0.1",
    piece_contents: "FedexPak",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "",
    width: "29.2",
    height: "26.9",
    depth: "2.8",
    piece_contents: "FedexBox Small",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "",
    width: "29.2",
    height: "33",
    depth: "6",
    piece_contents: "FedexBox Medium",
  },
  {
    piece_ID: "",
    package_type: "OD",
    weight: "",
    width: "31.4",
    height: "44.4",
    depth: "7.6",
    piece_contents: "FedexBox Large",
  },
];

export const DEFAULTVALUE = {
  piece_ID: "",
  package_type: "OD",
  weight: "",
  width: "",
  height: "",
  depth: "",
  piece_contents: "Propio embalaje",
};
