import React, { useState } from "react";
import { Image } from "react-bootstrap";

import { SendRecoverEmail } from "../../../forms/SendRecoverEmail";

import { ApiService } from "../../../services";

import Success from "../../../assets/icons/CheckMark.svg";
import Expired from "../../../assets/icons/LinkExpired.svg";
import { Container } from "./styles";
import { Link } from "react-router-dom";

export const RecoverPassword = ({ setMessage }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const {
        data: { statusCode },
      } = await ApiService.AuthService.forgotPassword(values);
      if (statusCode === 200) {
        setSuccess(true);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else {
        setMessage("Error de conexion, intenta de nuevo");
      }
    }
  };

  if (error)
    return (
      <Container>
        <Image src={Expired} width="150px" />

        <div>
          <p className="title text-center">¡No pudimos validar tu correo!</p>

          <p className="description  text-center">
            <strong>¡Error!</strong> {error}
          </p>
        </div>
      </Container>
    );

  if (success)
    return (
      <Container>
        <Image src={Success} width="200px" />

        <div>
          <p className="title text-center">
            ¡Hemos mandado un link de recuperacion a tu correo!
          </p>
        </div>
      </Container>
    );

  return (
    <Container>
      <div>
        <p className="title text-center">¿Se te olvidó tu contraseña?</p>

        <p className="description  text-center">
          Simplemente danos la dirección de correo electrónico que utilizastes
          para registrar en Shipal, y te enviaremos un código.
        </p>

        <SendRecoverEmail onSubmit={handleSubmit} />
      </div>

      <p className="bottom_info">
        ¿No tienes una cuenta? &nbsp;
        <Link className="link" to="/register">
          ¡Empieza ahora!
        </Link>
      </p>
    </Container>
  );
};
