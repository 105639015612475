import { Card, CardContent, Typography } from "@mui/material";
import { TitleCard } from "components/molecules/order-components/atoms/titleCard";
import { Export_declarationProps } from "components/molecules/order-forms/types";
import React from "react";
import { currentFormat } from "utils/convert";

type Props = {
  handleModal?: () => void;
  body: Partial<Export_declarationProps>;
  declared_value: number;
  title: string;
  contents: string;
};
export const DeclareExportCard = ({
  title,
  handleModal,
  contents,
  declared_value,
}: Props) => {
  return (
    <Card>
      <TitleCard title={title} handleOpenModal={handleModal} />
      <CardContent  sx={{gap: 1, display: "flex", flexDirection:"column", pt: "16px"}}>
        <Typography variant="h4" sx={{ fontSize: "14px !important" }}>
          Descripcion especifica del contenido del paquete (descripcion debe ser
          en español)*
        </Typography>
        <Typography variant="caption" sx={{ fontSize: "16px !important" }}>
          {contents}
        </Typography>
        <Typography variant="h4" sx={{ fontSize: "14px !important" }}>
          Valor declarado*
        </Typography>

        <Typography variant="caption" sx={{ fontSize: "16px !important" }}>
          {currentFormat(declared_value)}
        </Typography>
      </CardContent>
    </Card>
  );
};
