import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import {  Export_declarationProps } from 'components/molecules/order-forms/types';
import { TitleCard } from 'components/molecules/order-components/atoms/titleCard';
import { styles } from './styles';
import { currentFormat } from 'utils/convert';


type Props = {
    handleModal?: () => void;
    body: Partial<Export_declarationProps>;
    declared_value: number;
    contents: string;
    title: string;
  };
export const DeclareExportCard = ({title, handleModal, contents, body,declared_value}:Props) => {
  return (
    <Card>
    <TitleCard title={title} handleOpenModal={handleModal} />
    <CardContent sx={{gap: 1, display: "flex", flexDirection:"column", pt: "16px"}}>
      <Box sx = {styles.labels}>
      <Typography variant="h4" sx={{ fontSize: "0.9rem!important" }}>
        Descripción especifica del contenido del paquete (descripcion debe ser en Inglés)*
      </Typography>
      <Typography variant="caption">
        {contents}
      </Typography>
      </Box>
      <Box sx = {styles.labels}>
    
      <Typography variant="h4" sx={{ fontSize: "0.9rem!important" }}>
        Razon del Envío*
      </Typography>
      <Typography variant="caption">
        {body.export_reason}
      </Typography>
      </Box>
      <Box sx = {styles.labels}>

      <Typography variant="h4" sx={{ fontSize: "0.9rem!important" }}>
        Valor declarado*
      </Typography>
      <Typography variant="caption">
        {currentFormat(parseFloat(declared_value.toString()))}
      </Typography>
      </Box>
    </CardContent>
  </Card>
  )
}
