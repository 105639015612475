import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    padding: 0.6rem 2.4rem 0.6rem 2.4rem;
  }

  .title {
    color: #000000;
    text-align: center;
    letter-spacing: 0;
    line-height: 24px;
    font-family: "Roboto-Regular";
    font-size: 24px !important;
    padding: 0;
    font-weight: 500;
    margin: 2rem 0 2rem 0;
  }

  .description {
    margin-top: 1.5rem;
    text-align: center;
    width: 32em;
  }

  .bottom_info {
    padding-top: 157px;

    .link {
      color: #7e00fb;
      text-decoration: underline;
    }
  }
`;
