import React from 'react'
import { TextField, TextFieldProps } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

type Props = TextFieldProps & {
	isZero?: boolean;
	name: string;
};
export const InputText = ({ name, helperText, isZero, ...other }: Props) => {
	const { control } = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => {
				return (
					<TextField
						{...field}
						fullWidth
						value={field.value}
						error={!!error}
						helperText={error ? error?.message : helperText}
						// InputLabelProps={{
						// 	shrink: true,
						// }}
						{...other}
						variant="outlined"
					/>
				)
			}}
		/>
	)
}
