
export const step = {
  router: [
    {
      name: "URL",
      path: "",
      actualPath: false,
    },
    {
      name: "URL",
      path: "",
      actualPath: false,
    },
    {
      name: "URL",
      path: "",
      actualPath: true,
    },
  ],
  steps: [
    {
      label: "Datos Basicos",
      optional: true,
    },
    {
      label: "Seleccionar Carrier",
      optional: true,
    },
    {
      label: "Pagar",
      optional: true,
    },
  ],
  saving: false,
  activeStep: 0,
};

export const recipent = {
  title: "Recipent",
  data: {
    name: "Jason Brown",
    address: "123 Main St. Raleigh, North Carolina 27737",
    country: {
      name: "United States",
      icon: "🇧🇷",
    },
    phone: "+1 703-555-5555",
    email: "jason@email.com",
  },
  apodos: ["Juan", "Camilo", "Eduardo", "Viviana", "Carmen"],
};

export const sender = {
  title: "Sender",
  // data: {
  //   name: "Laura Chica",
  //   address: "Cra 37 #8a-19 Medellin, 050001",
  //   phone: "+55 627-324-5553",
  //   country: {
  //     name: "Colombia",
  //     icon: "🇨🇴",
  //   },
  //   email: "laura@shipal.co",
  // },
  data: null,
  apodos: ["Juan", "Camilo", "Eduardo", "Viviana", "Carmen"],
  countries: ["Colombia", "Guatemala", "Peru", "Mexico"],
  states: ["Candelaria", "Sincelejo", "Aguas Claras"],
  cities: ["Cali", "Medellin", "Bogota", "Pereira"],
};

export const packageDetails = {
  data: [
    {
      packageType: "DHL Flyer Caja 4",
      sizeReal: "2.0kg",
      sizeVolume: "2.0kg",
      sizePayment: "2.0kg",
    },
  ],

  fetchData: {
    packages: [
      {
        packageType: "DHL Flyer Caja 4",
        sizeReal: "2.0kg",
        sizeVolume: "2.0kg",
        sizePayment: "2.0kg",
      },
    ],
    typepacking: ['DHL Small Flyer 12"x8"x3"'],
  },
};

export const items = {
  items: [
    {
      id: 2,
      itemDescription: "Yellow Esparilla Bag",
      weight: "2.0kg",
      declaredValue: "$156.000",
    },
    {
      id: 1,
      itemDescription: "Purple Beach Bag",
      weight: "2.0kg",
      declaredValue: "$125.000",
    },
  ],
};

export const cheapestOptions = [
  {
    imgCarrier:
      "https://amchamcolombia.co/wp-content/uploads/2020/12/dhl-logo.jpg",
    title: "DHL EXPRESS Global",
    subtitle: "Est. Delivery 4-5 days",
    price: "$110.425 COP",
    selected: true,
  },
  {
    imgCarrier:
      "https://turbologo.com/articles/wp-content/uploads/2019/12/Fedex-logo.png",
    title: "FEDEX Standard Shipping",
    subtitle: "Est. Delivery 7-10 days",
    price: "$239.004 COP",
    selected: false,
  },
  {
    imgCarrier:
      "https://www.parquecomercialguacari.com/web/wp-content/uploads/2020/02/coordinadora-.jpg",
    title: "Coordinadora Standard Shipping",
    subtitle: "Est. Delivery 7-10 days",
    price: "$199.211 COP",
    selected: false,
  },
];

export const total = {
  shipping: "$110,42",
  insure: "$45.600",
  total: "$156.025",
};
