import styled from "styled-components";
import { Link } from "react-router-dom";
import { FormInput } from "../../components/molecules/form-input";
import { colors } from "../../constant/branding";

export const Title = styled.p`
  color: #000000;
  font-family: Roboto-bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledFormInput = styled(FormInput)`
  background-color: red;
  font-weight: bolder;
`;

export const StyledTextRegister = styled.p`
  font-family: Roboto-Regular;
  padding: 0;
  margin: 0;
`;

export const StyledLogin = styled(Link)`
  font-family: Roboto-Bold;
  color: ${colors.purpule};
  text-decoration: none;
  margin-top: 2rem;
  &:hover {
    color: ${colors.purpule};
  }
`;
