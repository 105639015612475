export interface IListHeader {
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify',
  id: string,
  key: number,
  label: string,
  size: 'small' | 'medium',
  pl?: string
  colSpan?: number
}
export const listHeaders: IListHeader[] = [
  {
    id: 'invoice_number',
    key: 1,
    label: 'Orden',
    size: 'small',
    align: 'left',
    colSpan: 1
  },
  {
    id: 'client_name',
    key: 2,
    label: 'Destino',
    size: 'medium',
    align: 'left',
    pl: '4rem',
    colSpan: 3
  },
  {
    id: 'contact_info',
    key: 3,
    label: 'Contact Info',
    size: 'medium',
    align: 'left',
    pl: '4rem',
    colSpan: 1
  },
  {
    id: 'shipments',
    key: 4,
    label: 'Servicio',
    size: 'small',
    align: 'center',
    colSpan: 1
  },
  {
    id: 'Pickup',
    key: 5,
    label: 'Recolección',
    size: 'small',
    align: 'center',
    colSpan: 1
  },
  {
    id: 'Tracking',
    key: 6,
    label: 'Guia',
    size: 'small',
    align: 'center',
    colSpan: 1
  },
  {
    id: 'etiqueta',
    key: 7,
    label: 'Etiqueta',
    size: 'small',
    align: 'center',
    colSpan: 1
  },
];
