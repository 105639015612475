import React, { CSSProperties, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import type { BoxProps, CardContentProps, ChipProps } from "@mui/material";
import { OrderStatus, OrderType } from "services/serviceV2/orders/types";

import { ShipalIcon, ShopifyIcon } from "assets/imgs";
import { useModal } from "hooks/v2/useModal";
import { FormDeclaredValue } from "components/molecules/order-forms/declaredValue";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { fullFormProp } from "components/molecules/order-forms/types";
import { currentFormat } from "utils/convert";
import { ItemsCard } from "components/molecules/order-components/itemsCard";

type Props = {
  fnSave?: fnCreateDrawProps;
  current_data: Partial<fullFormProp>;
};

export const CardOrderStatus = ({ current_data, fnSave }: Props) => {
  const { handleOpenModal, isOpen } = useModal();

  const boxStyles: BoxProps["sx"] = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      color: "grey",
      justifyContent: "center",
      alignItems: "flex-start",
    }),
    []
  );

  const cardContentStyles: CardContentProps["sx"] = useMemo(
    () => ({
      display: "flex",
      gap: "2rem",
      flexDirection: "row",
      justifyContent: "space-between",
    }),
    []
  );

  const imageStyles: CSSProperties = useMemo(
    () => ({
      maxWidth: "6rem",
      objectFit: "contain",
      marginRight: "1rem",
    }),
    []
  );

  const providerLogo = useMemo(
    () => ({
      [OrderType.SHIPAL]: ShipalIcon,
      [OrderType.SHOPIFY]: ShopifyIcon,
    }),
    []
  );

  const chipStates = useMemo<Record<string, ChipProps>>(
    () => ({
      [OrderStatus.ACEPTED]: {
        label: "Orden completa",
        color: "success",
      },
      [OrderStatus.DRAFT]: {
        label: "Orden sin completar",
        color: "warning",
      },
    }),
    []
  );

  const chipProps = useMemo<ChipProps>(
    () => ({
      sx: { color: "white", height: "24px" },
      ...chipStates[current_data.order_status as OrderStatus],
    }),
    [current_data.order_status, chipStates]
  );

  const textFiledStyle = useMemo(
    () => ({
      fontSize: "12px!important",
      color: "#677787",
    }),
    []
  );

  const handleDeclaredValue = async (value: any) => {
    await fnSave!(current_data, "declared_value", value.declared_value);
    handleOpenModal();
  };

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardContent sx={cardContentStyles}>
          <img
            alt="tipo de orden"
            style={imageStyles}
            src={providerLogo[current_data.order_type!]}
          />

          {Boolean(current_data.shipment_date) && (
            <Box sx={boxStyles}>
              <Typography sx={textFiledStyle}>Fecha</Typography>
              <Typography variant="h4" sx={{ fontSize: "4px", color: "RGBA(0, 0, 0, 0.87)" }}>
                {dayjs(current_data.shipment_date).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          )}

          {typeof current_data?.invoice_number === "number" && (
            <Box sx={boxStyles}>
              <Typography sx={textFiledStyle}>Número de orden #</Typography>
              <Typography variant="overline" sx={{ fontWeight: "bold !important", fontSize: "20px", color: "RGBA(0, 0, 0, 0.87)" }}>
                {current_data.invoice_number}
              </Typography>
            </Box>
          )}
          <Box sx={boxStyles}>
            <Typography sx={textFiledStyle}>Valor declarado</Typography>
            <Link onClick={handleOpenModal} sx={{ fontWeight: "bold !important", ":hover": { color: "#000" }, color: "#000", fontSize: "20px !important", cursor: "pointer" }}>
              {currentFormat(current_data.declared_value || 0)}
            </Link>
          </Box>

          <Box sx={boxStyles}>
            <Typography sx={{ ...textFiledStyle, alignSelf: 'start' }}>
              Tipo de Envio
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold !important", color: "#000", fontSize: "20px !important", cursor: "pointer", alignSelf: "center" }}>
              {current_data.is_international_shippment ? 'Internacional' : 'Nacional'}
            </Typography>
            {/* <Chip {...chipProps} /> */}
          </Box>
        </CardContent>
        {current_data.order_type === OrderType.SHOPIFY &&
          <ItemsCard data={current_data.cart_items_information!} orderNumber={current_data.shopify_order_number} shopifyDeclaredValue={current_data.shopify_declared_value} />
        }
      </Card>

      <Dialog open={isOpen} onClose={handleOpenModal}>
        <DialogTitle sx={{ border: "1px solid #dcdcdc" }}>
          Define el valor declarado del envío
        </DialogTitle>

        <FormDeclaredValue
          currentvalue={current_data.is_international_shippment ? "USD" : "COP"}
          declared_value={current_data.declared_value || 0}
          onSave={handleDeclaredValue}
        />
      </Dialog>
    </>
  );
};
