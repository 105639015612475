import { DialogTitle, Typography } from '@mui/material'
import React from 'react'

type Props = {
    title: string
}

export const ModalTitle = ({title}: Props) => {
  return (
    <DialogTitle sx={{
        py: 1,
        backgroundColor: "#FAFAFA",
        borderBottom: "1px solid #E0E0E0",
        mb:2
      }}>
        <Typography sx={{
          fontSize: "1rem !important",
          fontWeight: 600,
        }}>{title}</Typography></DialogTitle>
  )
}
