import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import {
  Description,
  StyledFooter,
  StyledModalBody,
  StyledModalHeader,
} from "./styles";
import { Button } from "../../atoms/button";
import { FormInput } from "../../molecules/form-input";

export const FormPayBalance = ({ onToggle }) => {
  const history = useHistory();
  const [value, setValue] = useState('')
  const handleSubmit = useCallback(() => {
    onToggle();
    history.push({ pathname: "/user-wallet", state: { value } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Fragment>
      <StyledModalHeader closeButton />
      <StyledModalBody>
        <Description>
          Escribe el monto que deseas abonar a tu billetera:
        </Description>
        <Formik initialValues={{ balance: 0 }} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                name="balance"
                placeholder="$COP"
                value={value}
                component={FormInput}
                autofocus
                onChange={(e) => setValue(e.target.value
                  //.replace(/\D/g, "")
                  // .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
                  )}
                
              />
              <StyledFooter>
                <Button purple regular type="submit">
                  Guardar
                </Button>
              </StyledFooter>
            </form>
          )}
        </Formik>
      </StyledModalBody>
    </Fragment>
  );
};
FormPayBalance.propTypes = {
  onToggle: PropTypes.func.isRequired,
  setSaldo: PropTypes.func.isRequired,
};
