import styled from "styled-components";
import { Button } from "../../atoms/button";
import { colors} from '../../../constant/branding';

export const ContainerFormik = styled.div`
  label {
    font-family: Roboto-Bold;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  input {
    color: #aeb5bd;
    font-family: Roboto-Regular;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
`;

export const TextInfo = styled.p`
color: #aeb5bd;
font-family: Roboto-Regular;
margin: 0px;
width: 60%;
text-align:center;
`

export const StyledButton = styled(Button)`
    margin-top: 2rem;
    border-color: ${colors.purpule};
    background-color: white;
    color: ${colors.purpule};
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;
    &:hover {
      background-color: ${colors.purpule};
      color: white;
      border: 1px solid ${colors.purpule};
    }
`;