import { types } from "../types"

const initialState = {
  authenticated: false,
  verification: true, //false,
  verifyEmail: "mattiasbylin@hotmail.com", //null,
  token: null,
  userData: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: null,
    country: null,
    receiveEmails: false,
    acceptedPolicies: [],
    recoveryCode: null,
    createdAt: "",
    updatedAt: "",
    accountMessage: null,
    plan: null,
    followers: [],
    testingFree: false,
    wallet: null,
    isActive: false,
    addresses: [],
    balanceAvailable: 0,
  },
}

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.unVerifyAccount:
      return {
        ...state,
        authenticated: false,
        verification: false,
        verifyEmail: payload,
      }
    case types.VerifyAccount:
      return {
        ...state,
        authenticated: false,
        verification: true,
        verifyEmail: payload,
      }
    case types.login:
      return {
        ...state,
        userData: payload,
        authenticated: true,
      }
    case types.token:
      return {
        ...state,
        token: payload,
        authenticated: true,
      }
    case types.logout:
      return initialState
    case types.accountMessage:
      return {
        ...state,
        accountMessage: payload,
      }
    default:
      return state
  }
}
