export const CREATE_PATH_NATIONAL = [{
    title: "Mis Ordenes",
    path: "/shipments/realizados"
  },
  {
    title: "Mis Ordenes Pendientes",
    path: "/shipments/realizados"
  },
  {
    title: "New Draft",
    path: "/shipping/national"
  }
  ]

  export const CREATE_PATH_INTERNATIONAL = [{
    title: "Mis Ordenes",
    path: "/shipments/realizados"
  },
  {
    title: "Mis Ordenes Pendientes",
    path: "/shipments/realizados"
  },
  {
    title: "New Draft",
    path: "/shipping/international"
  }
  ]