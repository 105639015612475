import React from 'react';
import { ContainerModal } from './styles';

export const ModalDialog = ({
    children,
    size,
    centered,
    animation,
    scrollable,
    contentClassName,
    dialogClassName,
    onToggle,
    openShow,
    typeModal
}) => {
    return (
        <ContainerModal
            typeModal={typeModal}
            size={size}
            show={openShow}
            onHide={onToggle}
            centered={centered}
            animation={animation}
            scrollable={scrollable}
            contentClassName={contentClassName}
            dialogClassName={dialogClassName}
            >
            {children}
        </ContainerModal>
    )
}
