import { useState } from "react";
import { Typography, Tooltip, Link } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { cloneOrder } from "services/serviceV2/orders";
import { OrderActionsAlertDto } from "./types";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "utils/providers/Alert-system";

interface OrderActionsButtonProps {
  orderId: string;
  handleChangeAlert?: (data: OrderActionsAlertDto) => void;
}
export default function OrderActionsButton({
  orderId,
  handleChangeAlert,
}: OrderActionsButtonProps) {
  const [isLoading, setLoader] = useState(false);
  const showAlert = useAlert();

  const handleDuplicateOrder = async () => {
    setLoader(true);
    const cloneCreated = await cloneOrder(orderId);
    const alertContent = (
      <Typography>
        Orden duplicada -{" "}
        <strong>
          La nueva orden{" "}
          <Link
            href={`/shipping/${
              cloneCreated.is_international_shippment
                ? "international"
                : "national"
            }/${cloneCreated._id}`}
            sx={{ color: "#7E00FB" }}
          >
            #{cloneCreated.invoice_number}
          </Link>{" "}
          ha sido creada!
        </strong>{" "}
        podrás verla en la pagina de{" "}
        <Link href="/shipments/realizados" sx={{ color: "#7E00FB" }}>
          Envíos pendientes
        </Link>
      </Typography>
    );
    if (cloneCreated.invoice_number != null) {
      if (handleChangeAlert) {
        handleChangeAlert({
          showAlert: true,
          alertMessage: alertContent,
          alertType: "success",
        });
      } else {
        showAlert(alertContent, "success");
      }
    } else {
      showAlert(
        "Hubo un error al duplicar la orden, vuelve a intentarlo por favor",
        "error"
      );
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  return orderId && orderId !== "" ? (
    <>
      <Tooltip
        title="Ahora, podrás duplicar tus ordenes con un solo click!"
        arrow
      >
        <LoadingButton
          onClick={handleDuplicateOrder}
          sx={{ ":hover": { color: "#7E00FB" } }}
          loading={isLoading}
          variant="outlined"
          startIcon={<ContentCopyOutlinedIcon fontSize="small" />}
        >
          Duplicar
        </LoadingButton>
      </Tooltip>
    </>
  ) : (
    <></>
  );
}
