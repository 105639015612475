import { fullFormProp } from "components/molecules/order-forms/types";
import { client } from "utils/interceptors";
import { QuoteProps } from "../types";
import {
  CitiesResponse,
  CreateGuideResponse,
  Order,
  MyNotebooksResponse,
  Notebook,
  PickupData,
} from "./types";
import axios from "axios";
import { ConsigneeProps, ShipperProps } from "components/molecules/order-forms/types";


export const getMyNotebooks = async () => {
  const { data } = await client.get<MyNotebooksResponse>("/address-book/my-addresses", {
    params: {
      limit: 0,
      type: "both",
      page: 1,
    },
  });

  if (!Array.isArray(data.docs)) {
    throw new Error("Can't retrieve notebooks");
  }

  return data.docs;
};

export const createNotebook = async (data: Partial<ShipperProps | ConsigneeProps>) =>
  await client.post("/address-book/create", data);

export const getCountries = async () => {
  return (await client.get("/pricing-engine/geo/countries")).data
    ?.data;
};

export const getCities = async () => {
  const { data } = await client.get<CitiesResponse>(
    "/pricing-engine/geo/cities"
  );

  if (!Array.isArray(data.data)) {
    throw new Error("Can't retrieve cities");
  }

  return data.data;
};

export const createOrderDraw = async (data: Partial<fullFormProp>) => {
  return (await client.post("orders/create", data)).data?.data;
};

export const cloneOrder = async (orderId: string) => {
  return (await client.post("orders/clone", { orderId })).data?.data;
};

export const getOrderById = (id: string) => async () => {
  return (await client.get(`orders/${id}`)).data?.data;
};

export const getMymonthlyReport = () => async () => {
  return (await client.get(`orders/reports/monthly`)).data?.data;
};

export const putOrder =
  (id: string) => async (values: Partial<fullFormProp>) => {
    return (await client.put(`orders/${id}`, values)).data?.data;
  };

export const findCouriersWithDraft = async (values: QuoteProps | fullFormProp) => {
  const { data } = await client.post(
    "/pricing-engine/quote",
    values
  );

  return data;
};

export const getPickupDays = async (id: string) => {
  const { data } = await client.get(`/pricing-engine/config/range-day`);

  return data.data;
};

export const quote = async (values: QuoteProps | fullFormProp) => {
  const { data } = await client.post(
    "/pricing-engine/quote/v2",
    values
  );

  return data;
};

export const getConfig = async () => {
  const { data } = await client.get(
    "/pricing-engine/config");

  return data;
};

export const createGuide = async (order: string) => {
  try {
    const { status, data } = await client.post<CreateGuideResponse>(
      `orders/shipment`,
      {
        order,
      }
    );

    if (status !== 201) {
      throw new Error("Can't create guide");
    }

  } catch (error) {
    if (error && axios.isAxiosError(error) && error.response) {
      const err = {
        status: error.response?.status,
        listErrors: [],
      };

      if (Array.isArray(error.response?.data?.data?.errors)) {
        err["listErrors"] = error.response?.data?.data?.errors;
      }

      throw err;
    }
  }
};

export const bookPickup = async (data: PickupData) => {
  try {
    const { status } = await client.post<CreateGuideResponse>(
      "/orders/pickup", data
    );

    if (status !== 201) {
      throw new Error("Can't create guide");
    }
  } catch (error) {

    if (error && axios.isAxiosError(error) && error.response) {
      const err = {
        status: error.response?.status,
        listErrors: [],
      };
      if (Array.isArray(error.response?.data?.data?.errors)) {
        err["listErrors"] = error.response?.data?.data?.errors;
      }

      throw err;
    }
  }
};
