export interface IListHeader {
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify',
  id: string,
  key: number,
  label: string,
  size: 'small' | 'medium',
  pl?: string
}
export const listHeaders: IListHeader[] = [
  {
    id: 'select',
    key: 12,
    align: 'left',
    label: 'Seleccionar',
    size: 'small'
  },
  {
    id: 'invoice_number',
    key: 1,
    align: 'left',
    label: 'Orden',
    size: 'small'
  },
  {
    id: 'client_name',
    key: 3,
    align: 'left',
    label: 'Destino',
    size: 'medium',
    pl: '4rem'
  },
  {
    id: 'articles',
    key: 4,
    align: 'left',
    label: 'Artículos',
    size: 'small'
  },
  {
    id: 'shipments',
    key: 6,
    align: 'center',
    label: 'Servicio',
    size: 'small',
    pl: '4rem'
  },
  {
    id: 'label',
    key: 7,
    align: 'center',
    label: 'Crear guía',
    size: 'small'
  },
];
