import styled from 'styled-components';
import { colors } from '../../../constant/branding';

import { Button } from "../../atoms/button";

export const BtnConfirm = styled(Button)`
padding: 0.8rem;
margin: 1%;
background-color: #ff3366;
color: #FFFFFF;
&:hover {
  background-color: white;
  color: #ff3366;
  border: 1px solid #ff3366;
}
`;


export const StyledButton = styled(Button)`
    margin-top: 2rem;
    color: white;
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;
    &:hover {
      background-color: white;
      color: ${colors.purpule};
      border: 1px solid ${colors.purpule};
    }
`;