import axios from "axios";

import { encryptDataV2 } from "../../utils/token";

export class AuthService {
  cities;
  constructor(client) {
    this.client = client;
  }

  me() {
    return this.client.get("/auth/user/me");
  }

  onboarding() {
    return this.client.get("/auth/onboarding/actives");
  }

  updateMe(body) {
    return this.client.put(`/auth/user/update-me`, body);
  }

  login(values) {
    return this.client.post("/auth/user/login", values);
  }

  // Servicio para generar el secret key

  getSecretKeys() {
    return this.client.get(`/auth/credential-api/user/`);
  }

  generateSecretKeys(body) {
    return this.client.post(`/auth/credential-api/register/api`, body);
  }

  shopifyMatch(body, token) {
    return this.client.post(`/auth/shopify/match`, body, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  shopifyMatchAuthenticated(body) {
    return this.client.post(`/auth/shopify/match`, body);
  }

  removeSecretKeys(id) {
    return this.client.delete(`/auth/credential-api/register/api/${id}`);
  }

  register(values, shopifyData) {
    const {
      industryTarget,
      industryTarget2,
      internationalShipmentsEstimated,
      nationalShipmentsEstimated,
      policies,
      confirmPassword,
      ...rest
    } = values;

    return this.client.post("/auth/user/sign-up", {
      ...rest,
      industryTarget:
        industryTarget === "Otro" ? industryTarget2 : industryTarget,
      internationalShipmentsEstimated: parseFloat(
        internationalShipmentsEstimated
      ),
      nationalShipmentsEstimated: parseFloat(nationalShipmentsEstimated),
    });
  }

  sendActivationEmail(email) {
    return this.client.post("/auth/user/send-activation-email", { email });
  }

  activateAccount(token) {
    return this.client.put("/auth/user/activate-user", null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  forgotPassword(values) {
    return this.client.post("/auth/user/forgot-password", values);
  }

  resetPassword(password, token) {
    return this.client.post(
      "/auth/user/reset-password",
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  paymentPse(values) {
    return this.client.post("/wallet/transactions/", values);

  }

  paymentPaypal(values) {
    return this.client.post("/wallet/transactions/paypal", values);
  }

  myNewNotebook(values) {
    return this.client.post("/address-book/create", values);
  }

  editNotebook(id, values) {
    return this.client.patch(`/address-book/update/${id}`, values);
  }

  delieteNotebook(id) {
    return this.client.delete(`/address-book/delete/${id}`);
  }

  myShipments(values) {
    return this.client.get(
      `/carriers/dhl/get-my-orders?limit=10&page=${values}`
    );
  }

  trackShipment(values) {
    return this.client.post("/carriers/dhl/know-tracking", values);
  }

  createLabelCOO(values) {
    return this.client.get(`/carriers/coordinadora/create-label?id=${values}`);
  }

  bookPickUpShipmentDHL(values) {
    return this.client.post("/carriers/dhl/book-pickup", values);
  }

  bookPickUpShipmentCOO(values) {
    return this.client.post("/carriers/coordinadora/book-pickup", values);
  }

  bookPickUpShipmentFEDEX(values) {
    return this.client.post("/carriers/fedex/pickUp", values);
  }

  async getCountries() {
    const { data } = await this.client.get("/pricing-engine/geo/countries");

    if (!Array.isArray(data.data)) {
      throw new Error("Can't retrieve countrylist");
    }

    data.data.find(
      (item) => item.countryName === "Islas Vírgenes"
    ).countryCode = "VI";

    return data.data;
  }

  async getCities() {
    if (this.cities) {
      return this.cities;
    }

    const { data } = await this.client.get("/pricing-engine/geo/cities");

    if (!Array.isArray(data.data)) {
      throw new Error("Can't retrieve cities");
    }

    const sortedCities = data.data.sort((a, b) =>
      a.cityName.localeCompare(b.cityName)
    );

    this.cities = sortedCities;

    return this.cities;
  }

  getPrices(body) {
    return this.client.get("/pricing/price", body);
  }

  shippinCreateEmail = async (values) => {
    try {
      const valuesEncriptados1 = encryptDataV2(values, "SHA-256");
      const valuesEncriptados2 = encryptDataV2(valuesEncriptados1, "MD5", 1);
      const { data } = await this.client.post(`/home/email/create`, {
        token: valuesEncriptados2,
      });
      return data;
    } catch (error) {
      alert("error");
      console.error();
      throw error;
    }
  };

  createdClientEpayco = async (values, plan) => {
    const { cvc, doc_number, doc_type, email, exp_month, exp_year, number } =
      values;

    console.log("values del usuario", values);
    console.log("plan selecionado", plan);
    try {
      // EndPoint para generar tokenización para poder crear un cliente apartir del token

      let data = {
        cardNumber: number,
        exp_year,
        exp_month,
        cvc,
      };

      const valuesEncriptados1 = encryptDataV2(data, "SHA-256");
      const valuesEncriptados2 = encryptDataV2(valuesEncriptados1, "MD5", 1);
      const {
        data: { card },
      } = await this.client.post(`/payment/token/create`, {
        token: valuesEncriptados2,
      });

      // EndPoint para crear un cliente en Epayco
      const {
        data: { cardUp, userUp },
      } = await this.client.post(`/payment/customers/create`, {
        token_card: card?.token_card,
        email,
      });
      console.log("respuesta de crear el cliente ", userUp);

      const valuesSubscrition = {
        id_plan: plan?.id_plan,
        id_customer: userUp?.customerId,
        id_token: cardUp?.token_card,
        doc_type,
        doc_number,
      };
      // servicio para realizar la subscrition del usuario a un plan
      await this.subscribeToPlan(valuesSubscrition);
      // Servicio para paga la subscrition realizada
      await this.paySubscription(valuesSubscrition);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getCustomer = async (idUser) => {
    try {
      // Endpoint para obtener la información del cliente creado.
      const { data } = await this.client.get(
        `/payment/subscription/getByIdUser/${idUser}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  };

  subscribeToPlan = async (values) => {
    try {
      const { data: res } = await this.client.post(
        `/payment/subscription/create`,
        values
      );
      console.log("respuesta a la suscripcion:", res);
      alert("La subscrición al plan se realizó correctamente");
      return res;
    } catch (error) {
      throw error;
    }
  };

  unsubscribeFromToPlan = async (id_subscrition) => {
    try {
      await this.client.post(
        `/payment/subscription/cancelSubscription/${id_subscrition}`
      );
    } catch (error) {
      throw error;
    }
  };

  updateCartToUser = async (idUser) => {
    try {
      // servicio para traer la informacion del usuario de mongo
      const endpoint = "";
      const { data: infoUser } = await this.client.get(endpoint, {
        params: { idUser },
      });
      const { data: response } = await this.client.post(
        `/payment/customers/addDefaultCardCustomer`,
        {
          franchise: infoUser?.nameCard,
          token: infoUser?.tokenCard,
          mask: infoUser?.numberCard,
          customer_id: infoUser?.customerId,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  paySubscription = async (values) => {
    try {
      const res = await axios.get(`https://api.ipify.org?format=json`);

      const { data } = await this.client.post(
        `/payment/subscription/paySubscription`,
        {
          ...values,
          ip: res.data?.ip,
        }
      );
      console.log("RESUESTA DE LA SUBCRITION PAGAR", data);
    } catch (error) {
      throw error;
    }
  };

  getPlans = async () => {
    try {
      const { data } = await this.client.get(`/payment/plans/lists`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  authSignUp = async (values) => {
    const { data } = await this.client.post(`/auth/shipal/signUp`, values);
    console.log(data);
  };

  createDHLGuide = async (values) => {
    try {
      const { data } = await this.client.post(
        `/carriers/dhl/create-guide`,
        values
      );
      return data;
    } catch (error) {
      if (error.response.data.data.status === 202) {
        return Promise.reject({
          data: { err: "No hay saldo suficiente." },
          codeType: "balance",
        });
      }
      return Promise.reject({
        data: {
          err: "Hubo un error conectandose al servidor, por favor comuniquese con servicio al cliente.",
        },
        codeType: "server",
      });
    }
  };

  createCOOGuide = async (values) => {
    try {
      const { data } = await this.client.post(
        `/carriers/coordinadora/create-guide`,
        values
      );
      return data;
    } catch (error) {
      if (error.response.data.data.status === 202) {
        return Promise.reject({
          data: { err: "No hay saldo suficiente." },
          codeType: "balance",
        });
      }
      return Promise.reject({
        data: {
          err: "Hubo un error conectandose al servidor, por favor comuniquese con servicio al cliente.",
        },
        codeType: "server",
      });
    }
  };

  createFEDEXGuide = async (values) => {
    try {
      const { data } = await this.client.post(
        `/carriers/fedex/create-guide`,
        values
      );
      return data;
    } catch (error) {
      if (error.response.data.data.status === 202) {
        return Promise.reject({
          data: { err: "No hay saldo suficiente." },
          codeType: "balance",
        });
      }
      return Promise.reject({
        data: {
          err: "Hubo un error conectandose al servidor, por favor comuniquese con servicio al cliente.",
        },
        codeType: "server",
      });
    }
  };

  createDraft = async (values) => {
    try {
      const { data } = await this.client.post(
        `/orders-draft/save-draft`,
        values
      );
      return data;
    } catch (error) {
      alert("error");
      console.error();
      throw error;
    }
  };

  getMyDrafts = async () => {
    try {
      const { data } = await this.client.get(`/orders-draft/get-my-drafts`);
      return data;
    } catch (error) {
      alert("error");
      console.log(error.response.data);
      console.error();
      throw error;
    }
  };

  getDraftById = async (id) => {
    try {
      const { data } = await this.client.get(
        `/orders-draft/get-draft-by-id/${id}`
      );
      return data;
    } catch (error) {
      alert("error");
      console.error();
      throw error;
    }
  };
}
