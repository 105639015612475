import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { EmptyButtonSenders } from "commons/empty-button-senders";
import { SHIPPING_TYPE } from "enums/shipping";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { Package_informationProps, fullFormProp } from "components/molecules/order-forms/types";
import React, { useState } from "react";
import { PackageDetail } from "../packageDetailCard";
import { styles } from "../styles";
import { FormPackageDetails } from "./form";
import { packageDimensions } from "assets/icons";
import { ModalTitle } from "../ModalTitle";
import { INITIAL_ORDER_FORM } from "components/molecules/order-forms/const";

type Props = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
  shippingType: SHIPPING_TYPE;
  isForQuote?: boolean;
};

export const PackageDetails = ({ data, fnSave, shippingType, isForQuote }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [indexEdit, setIndexEdit] = useState(-1);

  const handleModal = () => {
    setIsOpenModal((state) => !state);
  };

  const applyFnFunction = async (items: any) => {
    try {
      setIndexEdit(-1);
      if (!items.length) {
        data = {
          ...data,
          total_payment: 0,
          shipment_value: 0,
          shipal: INITIAL_ORDER_FORM.shipal
        };
      }
      return await fnSave(
        data,
        "package_information",
        items as Package_informationProps[]
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditItem = (index: number) => {
    setIsOpenModal(true);
    setIndexEdit(index);
  };

  const handleDeleteItem = async (index: number) => {
    const stageItems = [...data.package_information!];
    stageItems.splice(index, 1);
    return await applyFnFunction(stageItems);
  };

  return (
    <Card>
      {data.package_information && data.package_information!.length > 0 ? (
        <> 
          <PackageDetail
            body={data.package_information}
            isShopifyShipment={data.order_type === "SHOPIFY"}
            title="Detalle del paquete"
            actions={[
              {
                name: "Editar",
                handle: handleEditItem,
              },
              {
                name: "Borrar",
                handle: handleDeleteItem,
              },
            ]}
            handleModal={handleModal}
            typeShipping={shippingType}
          />
        </>
      ) : (
        <EmptyButtonSenders
          isforQuote={isForQuote}
          title="Detalle del paquete"
          titleButton="Configurar"
          handleModal={handleModal}
          warningInput="Ingresa las dimensiones del paquete"
          icon={packageDimensions}
        />
      )}
      <Dialog open={isOpenModal} onClose={handleModal}>
        <ModalTitle title={'Informacion del paquete'} />
        <DialogContent>
          <Typography sx={styles.modal.title}>Tipo de empaque</Typography>
          <FormPackageDetails
            data={data}
            fnSave={applyFnFunction}
            indexEdit={indexEdit}
            handleModal={handleModal}
            shippingType={shippingType}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};
