import { Box, Link, Typography, Chip } from "@mui/material";
import React from "react";
import { ValidationProp } from 'components/molecules/order-forms/types';
import { WarningAmberOutlined } from '@mui/icons-material';

type Props = {
  title: string;
  handleOpenModal?: () => void;
  buttonName?: string;
  validations?: ValidationProp;
}


export const TitleCard = ({ handleOpenModal, title, buttonName = "Editar", validations }: Props) => {
  return (
    <Box sx={{ margin: '1rem 24px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="h4" sx={{ fontSize: "18px !important" }}>{title}</Typography>
      {validations && validations?.type && validations.type === "ERROR" &&
        <Chip color="error" variant='outlined' icon={<WarningAmberOutlined />} label={validations.label} />
      }
      {!!handleOpenModal && <Link onClick={handleOpenModal}> {buttonName}</Link>}
    </Box>

  )


}