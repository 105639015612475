import styled from "styled-components";
import { Button } from '../../atoms/button';
import { colors } from '../../../constant/branding';

export const StyledBtn = styled(Button)`
padding: 0.8em 1em;
background-color: #7E00FB;
color: white;
border: 1px solid #7E00FB;
&:hover {
    background-color: #6500c9;
    color: #fff;
}
&:focus {
    outline: none;
  }
`;

export const StyledDiv = styled.div`

`;

export const StyledButton = styled(Button)`
    ${props => props.marginTop ? '' : `margin-top: 2rem;`}
    border-color: ${colors.purpule};
    background-color: ${colors.purpule};
    color: white;
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;
    &:hover {
      background-color: white;
      color: ${colors.purpule};
      border: 1px solid ${colors.purpule};
    }
`;
