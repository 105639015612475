import React, { useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import LinkAction from "components/molecules/Link-action";
import { useHistory } from "react-router-dom";
import { Props } from "../contactInfo";

export const ConsigneeTableCell = ({ order }: Props) => {
  const history = useHistory();
  const handleSelectedOrder = useCallback(
    (isNational: boolean, id: string) =>
      history.push(
        `/shipping/${isNational ? "international" : "national"}/${id}`
      ),
    [history]
  );
  const imgStyles: React.CSSProperties = useMemo(
    () => ({
      width: "32px",
      objectFit: "contain",
    }),
    []
  );
  const isComplete = () => order.due_diligence.consignee_completed;
  const isInternational = order.is_international_shippment;

  return (
    <>
      {isComplete() ? (
        <Stack width={"100%"} height={"100%"}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ minHeight: "70%" }}
          >
            {order.consignee.country_code && (
              <img
                alt="Bandera de pais"
                style={imgStyles}
                src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${order.consignee.country_code}.svg`}
              />
            )}
            <Typography variant="caption" fontWeight="bold">
              {`${order?.consignee?.person_name?.toUpperCase()} 
                  ${
                    order.consignee?.company_name
                      ? `(${order.consignee?.company_name.toUpperCase()})`
                      : ""
                  }`}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            marginTop={0}
            sx={{ minHeight: "30%" }}
          >
            <Typography variant="caption">
              {`${order.consignee?.country_code} 
                ${
                  order.consignee?.city
                    ? order.consignee?.city?.toUpperCase()
                    : ""
                }`}
              {isInternational && (
                <>
                  {`${
                    order.consignee?.state ? `, ${order.consignee.state}` : ""
                  } ${order.consignee?.postal_code ?? ""}`}
                </>
              )}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <LinkAction
          text="AGREGAR DESTINO"
          action={() =>
            handleSelectedOrder(order.is_international_shippment, order._id)
          }
        />
      )}
    </>
  );
};
