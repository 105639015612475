import { Form, Formik } from "formik";
import React from "react";
import { Col } from "react-bootstrap";
import { Button } from "../../components/atoms/button";
import FormikInput from "../../components/molecules/formik-input";
import { changePasswordSchema } from "./validations";

export const ChangePassword = ({ onSubmit }) => {
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={changePasswordSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikInput
            type="password"
            label="Contraseña nueva"
            name="password"
            InputFullWidth
          />
          <FormikInput
            type="password"
            label="Confirmar Contraseña"
            name="confirmPassword"
            InputFullWidth
          />

          <Col xs="auto" className="pt-3 d-flex justify-content-center">
            <Button type="submit" purple disabled={isSubmitting} Regular>
              Restablecer
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
