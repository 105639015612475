import React, { useState } from "react";
import { Row } from "react-bootstrap";

import { RecoverPassword } from "../../organisms/RecoverPassword";
import { LoginMessage } from "../../molecules/login-message";
import { SectionImg } from "../../organisms/section-img";

export const RecoverPasswordPage = () => {
  const [modalMessage, setMessage] = useState(null);

  return (
    <>
      <Row className="p-0 m-0">

          <SectionImg
            imgUrl={""}
            showLogo
            showButtons
            screen="recovery"
            fullWidth
          >
            <RecoverPassword setMessage={(msj) => setMessage(msj)} />          
          </SectionImg>
      </Row>
      <LoginMessage
        message={modalMessage}
        handleMessage={() => setMessage(null)}
      />
    </>
  );
};
