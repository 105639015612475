import CurrencyFormat from "react-currency-format";

export const Currency = ({ prefix = "$", ammount, sufix = "COP" }) => (
  <CurrencyFormat
    value={ammount}
    displayType={"text"}
    thousandSeparator={true}
    prefix={prefix}
    suffix={` ${sufix}`}
    renderText={(value, props) => <b {...props}>{value}</b>}
  />
);
