import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import type { BoxProps, ButtonProps } from "@mui/material";

interface Props {
  isValid: boolean;
  isLoading?: boolean;
  handleClose: () => void;
}

export const FormActions = ({ isValid, handleClose, isLoading }: Props) => {
  const boxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      display: "flex",
      gap: "1rem",
      justifyContent: "right",
    }),
    []
  );

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );

  return (
    <Box display="flex" sx={boxStyles}>
      <Button
        type="button"
        variant="text"
        onClick={() => handleClose()}
        sx={cancelButtonStyles}
      >
        Cancelar
      </Button>

      <LoadingButton  loading={isLoading} type="submit" disabled={!isValid} variant="contained">
        Confirmar
      </LoadingButton>
    </Box>
  );
};
