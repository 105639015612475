import React, { useEffect, useState } from "react";
import Expired from "../../../assets/icons/LinkExpired.svg";
import Verified from "../../../assets/icons/EmailVerifiedIcon.svg";
import { ApiService } from "../../../services";
import { useLocation, useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Container } from "./styles";
import { Button } from "../../atoms/button";

export const ActivateAcount = ({ setMessage }) => {
  const [status, setStatus] = useState("loading");
  const [disabledButton, setDisabledButton] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);

  const logos = {
    fail: Expired,
    success: Verified,
  };

  const description = {
    fail: "Por favor, revisa tu bandeja de entrada o la carpeta de correos no deseados y sigue los pasos del correo de verificación para continuar con la activación de tu cuenta.",
    success:
      "Nos encanta que puedas empezar HOY a disfrutar de los mejores beneficios para tus envíos alrededor del mundo",
  };

  const title = {
    fail: "¡Tu correo no ha sido verificado!",
    success: "¡Listo! Hemos verificado tu correo",
  };

  useEffect(() => {
    if (params.get("token")) {
      activateAccount(params.get("token"));
    }
  }, []);

  const activateAccount = async (token) => {
    try {
      const { status } = await ApiService.AuthService.activateAccount(token);

      if (status === 200) {
        setStatus("success");
      }
    } catch (error) {
      if (error.response.status === 409) {
        setDisabledButton(true);
      }

      if (error.response.data.message) {
        setMessage(error.response.data.message);
      }

      setStatus("fail");
    }
  };

  const resendActivationEmail = async () => {
    try {
      setDisabledButton(true);

      await ApiService.AuthService.sendActivationEmail(params.get("email"));
    } catch (error) {
      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage(
          "Fallo al enviar correo de activacion, reintenta nuevamente"
        );
      }
    } finally {
      setDisabledButton(false);
    }
  };

  return (
    <Container>
      {status === "loading" ? (
        <p className="title">Verificando cuenta...</p>
      ) : (
        <>
          <Image
            src={logos[status]}
            width={status === "fail" ? "150px" : "200px"}
          />
          <p className="title">{title[status]}</p>
          <p className="description">{description[status]}</p>

          {status === "fail" && (
            <Button
              purple
              outline
              Regular
              className="send_again"
              disabled={!params.get("email") || disabledButton}
              onClick={resendActivationEmail}
            >
              Reenviar
            </Button>
          )}

          {status === "success" && (
            <Button
              purple
              outline
              Regular
              className="send_again"
              onClick={() => history.push("/login")}
            >
              Ir a login
            </Button>
          )}
        </>
      )}
    </Container>
  );
};
