import { useField } from "formik";
import React from "react";
import { Form, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { StyledDatePicker } from "./style";

const FormikInput = ({
  label,
  date = false,
  InputFullWidth,
  InputThreeWidth,
  disabled= false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6;
  };
  const moveToWeekDay = (date) => {
    let day = new Date(date);
    if (day.getDay() === 0) {
      const newDate = day.setDate(day.getDate() + 2);
      return new Date(newDate);
    } else if (day.getDay() === 6) {
      const newDate = day.setDate(day.getDate() + 2);
      return new Date(newDate);
    } else {
      return date;
    }
  };
  const handleChange = (date) => {
    const day = new Date(date).getDay();
    if (day !== 0 && day !== 6) helpers.setValue(date);
  };
  const dateValue = new Date(field.value);

  if (date && typeof field.value === "object") {
    return (
      <Col
        xs="12"
        md={InputThreeWidth ? "3" : InputFullWidth ? "12" : "6"}
        style={{
          minHeight: props.type !== "checkbox" && "82px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        {label && <Form.Label style={{ fontWeight: 700 }}>{label}</Form.Label>}
        <StyledDatePicker
          selected={
            typeof field.value === "object"
              ? new Date(props.min) > dateValue
                ? moveToWeekDay(props.min)
                : moveToWeekDay(field.value)
              : moveToWeekDay(props.min)
          }
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecciona una fecha"
          filterDate={isWeekday}
          minDate={new Date(props.min)}
          maxDate={new Date(props.max)}
          onChange={handleChange} //only when value has changed
          {...props}
        />

        {meta.touched && meta.error && typeof meta.error !== "object" && (
          <Form.Text style={{ color: "#dc3545" }}>{meta.error}</Form.Text>
        )}
      </Col>
    );
  }

  if (date && typeof field.value === "string") {
    const fieldValue =
      field.value.length !== 0 ? field.value.split(/-/) : field.value;
    const year = parseInt(fieldValue[0]);
    const month = parseInt(fieldValue[1]) - 1;
    const day = parseInt(fieldValue[2]);
    const finalStringDate =
      field.value.length !== 0
        ? new Date(year, month, day)
        : new Date(field.value);
    return (
      <Col
        xs="12"
        md={InputThreeWidth ? "3" : InputFullWidth ? "12" : "6"}
        style={{
          minHeight: props.type !== "checkbox" && "82px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        {label && <Form.Label style={{ fontWeight: 700 }}>{label}</Form.Label>}
        <StyledDatePicker
        disabled={disabled}
          selected={field.value && finalStringDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecciona una fecha"
          minDate={new Date(props.min)}
          maxDate={new Date(props.max)}
          onChange={handleChange}
          {...props}
        />

        {meta.touched && meta.error && typeof meta.error !== "object" && (
          <Form.Text style={{ color: "#dc3545" }}>{meta.error}</Form.Text>
        )}
      </Col>
    );
  }

  return (
    <Col
      xs="12"
      md={InputThreeWidth ? "3" : InputFullWidth ? "12" : "6"}
      style={{
        minHeight: props.type !== "checkbox" && "82px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        position: "relative",
      }}
    >
      {label && <Form.Label style={{ fontWeight: 700 }}>{label}</Form.Label>}
      <Form.Control {...field} {...props} disabled={disabled} />
      {meta.touched && meta.error && typeof meta.error !== "object" && (
        <Form.Text style={{ color: "#dc3545" }}>{meta.error}</Form.Text>
      )}
    </Col>
  );
};

export default FormikInput;
