import axios from "axios";
import { getToken } from "../store/auth/auth.selectors";
import { logout } from "../store/auth/auth.actions";

const api = {
  // develop: "http://localhost:3001",
  develop: "https://api-dev.shipal.co",
  prod: "https://api-v2.shipal.co",
};

export const client = axios.create();

export const applyInterceptors = (store, REACT_APP_AWS_BRANCH) => {
  const apiBaseUrl = api[REACT_APP_AWS_BRANCH || "develop"] ? api[REACT_APP_AWS_BRANCH || "develop"]: api["develop"];

  client.interceptors.request.use(async (config) => {
    const reduxState = store.getState();
    const newConfig = { 
      ...config,
      baseURL:
        apiBaseUrl
     };
    const authToken = getToken(reduxState);
    if (authToken) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    return newConfig;
  });

  client.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== "object") {
        throw Error("Wrong data format from backend");
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};
