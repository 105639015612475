import { AxiosStatic } from "axios";
import { fullFormProp } from "components/molecules/order-forms/types";

export class DrawService {
  private client: AxiosStatic;
  constructor(client: AxiosStatic) {
    this.client = client;
  }

  createDraw = async (values: Partial<fullFormProp>) => {
    try {
      const { data } = await this.client.post(`/create`, values);
      return data;
    } catch (error) {
      alert("error");
      console.error();
      throw error;
    }
  };


}
