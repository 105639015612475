import styled from 'styled-components';
import { Container, Card,  Modal, ModalBody } from "react-bootstrap";
import { CardCustom } from '../../atoms/card';
import { Button } from "../../atoms/button";
import { colors } from '../../../constant/branding';

export const ContainerTitle = styled.div`
p, hr{
    margin: 0px;
    padding: 0px;
}

p:nth-child(1){
    padding-bottom: 0.5em;
}

`

export const StyledCardTitle = styled.p`
  font-weight: bold;
`;


export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const StyledCardCustom = styled(CardCustom)`
width: inherit;
&:hover{
    border: 1px solid purple;
}
&:focus {
    border: 1px solid purple;
}
@media (max-width: 500px){
    width: 100%;
    margin: 0.5rem auto;
}
`

export const ContainerStyled = styled(Container)`
  max-width: 100%;
  width: 80%;
  margin: 0px;
  padding: 0px;
  padding-top: 2rem;
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
  }
`;

export const CardStyled = styled(Card)`
  border-radius: 10px;
  background-color: #fcfcfc;
  border: 1px solid #dee2e6;
  width: inherit;
  overflow: hidden;
  margin-bottom: 1rem;
`;


export const CardBodyStyled = styled(Card.Body)`
  p {
    margin-bottom: 12px;
  }
  .containerText {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div:nth-child(3) {
    p {
      margin: 0px;
    }
  }
  div:nth-child(4) {
    color: purple;
    p {
      margin: 0px;
    }
  }
`;

export const BtnConfirm = styled(Button)`
  padding: 0.8rem;
  margin: 1%;
  &:hover {
    background-color: white;
    color: ${colors.purpule};
    border: 1px solid ${colors.purpule};
  }
`;

export const BtnCancel = styled(Button)`
  padding: 0.8rem;
  margin: 1%;
  background-color: #ff3366;
  color: #FFFFFF;
  &:hover {
    background-color: white;
    color: #ff3366;
    border: 1px solid #ff3366;
  }
`;

export const StyledButton = styled(Button)`
    margin-top: 2rem;
    border-color: ${colors.purpule};
    background-color: ${colors.purpule};
    color: white;
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;
    &:hover {
      background-color: white;
      color: ${colors.purpule};
      border: 1px solid ${colors.purpule};
    }
`;

export const StyledButton2 = styled(Button)`
    margin-top: 2rem;
    border-color: ${colors.purpule};
    background-color: ${colors.purpule};
    color: white;
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;
    &:hover {
      background-color: white;
      color: ${colors.purpule};
      border: 1px solid ${colors.purpule};
    }
`;

export const StyledModalHeader = styled(Modal.Header)`
border-bottom-color: transparent;
`;

export const Description = styled.p`
text-align: center;
color: black;
font-family: Roboto-Regular;
padding-bottom:1rem;
`;

export const StyledModalBody = styled(ModalBody)`
padding: 0px 1em 1em 1em;
input[type=number] {
    border-radius: 20px;
    border-color: purple;
    &:focus{
        outline: none;
        box-shadow: none;
    }

}
`;