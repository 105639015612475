import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import FormProvider from "commons/form-provider";
import { InputText } from "commons/input-text";
import { fnCreateDrawProps } from "components/molecules/order-forms/types";
import { fullFormProp, Export_declarationProps } from "components/molecules/order-forms/types";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { declareExportNationalSchema } from "./validation";
import type { BoxProps, ButtonProps } from "@mui/material";

type Props = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
  handleModal: () => void;
  isForQuote?: boolean;
};

export const NationalDeclareExportForm = ({
  data,
  fnSave,
  handleModal,
  isForQuote
}: Props) => {
  const [ declaredValue, setDeclaredValue ] = useState(0);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ dataWithDeclaredValue, setDataWithDeclaredValue ] = useState<fullFormProp>();
  const [ resData, setResData ] = useState<any>();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(declareExportNationalSchema),
    defaultValues: {
      ...data.export_declaration,
      contents: data.contents,
      declared_value: data.declared_value,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (dataForSave: any) => {
    await fnSave({
      ...data, 
      contents: dataForSave.contents,
      declared_value:  dataForSave.declared_value,
    }, "export_declaration", {
      ...resData, 
      ...dataForSave
    } as Export_declarationProps);

    handleModal && handleModal();
  };

  const save = async () => {
    await fnSave({
      ...data, 
      declared_value:  declaredValue,
    }, "export_declaration", {
      ...resData, 
      declared_value: declaredValue,
      signature_name: data.shipper?.company_name? data.shipper.company_name: "",
    } as Export_declarationProps);
  };

  const quoterDeclaredValue = useCallback(async(event: any) => {

    await setDeclaredValue(() => event.target.value);

    await setDataWithDeclaredValue({
      ...data,
      declared_value: declaredValue,
    } as fullFormProp);
    setResData({
      ...resData,
      shipment_purpose: "COMMERCIAL",
      declared_value: declaredValue,
      signature_name: data.consignee?.company_name,
    });
    setIsDisabled(() => false);
  }, [declaredValue, setDeclaredValue])

  useEffect(() => {
  }, [declaredValue]);

  const boxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      display: "flex",
      gap: "1rem",
      justifyContent: "right",
    }),
    []
  );

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );

  return (
    <>
    {!isForQuote ?
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box>
          <Typography>
            Descripcion especifica del contenido del paquete (Descripcion en
            español)
          </Typography>
          <InputText name="contents" size="small" />
        </Box>
        <InputText
          name="declared_value"
          size="small"
          label="Valor Declarado (COP)"
          required
          variant="outlined"
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "end", m: 2 }}>
          <Button type="button" sx={cancelButtonStyles} onClick={() => handleModal()} variant="text">
            Cancelar
          </Button>
          <Button type="submit" variant="contained">
            {data.declared_value === 0 ? "Agregar" : "Confirmar"}
          </Button>
        </Box>
      
    </FormProvider>
    :
    <Box display="flex" sx={boxStyles}>
    <InputText
    name="declared_value"
    size="small"
    onChange={(e) => {quoterDeclaredValue(e)}}
    label="Valor Declarado (COP)"
    required
    value={declaredValue}
    variant="outlined"
  />
  <LoadingButton onClick={save} disabled={isDisabled} loading={!data} type="button" variant="contained">
                  Aplicar
                </LoadingButton>
      </Box>
  }
  </>
  );
};
