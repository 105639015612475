import styled from 'styled-components';
import { Modal, ModalBody } from 'react-bootstrap';

export const StyledModalBody = styled(ModalBody)`
padding: 0px 1em 1em 1em;
input[type=number] {
    border-radius: 20px;
    border-color: purple;
    &:focus{
        outline: none;
        box-shadow: none;
    }

}
`

export const StyledModalHeader = styled(Modal.Header)`
border-bottom-color: transparent;
`

export const Description = styled.p`
text-align: center;
color: black;
font-family: Roboto-Regular;
padding-bottom:1rem;

`
export const StyledFooter = styled.div`
display: flex;
justify-content:center;
align-items:center;
width: 100%;
button {
    width: 60%;
    border-radius: 15px;
    margin-top: 1rem;
    padding: 0.5em;
    &:hover {
        background-color: white;
        color: purple;
        border: 1px solid purple;
    }
}

`