export class WalletService {
  constructor(client) {
    this.client = client;
  }

  getWallet(id) {
    return this.client.get(`/wallet/history`);
  }

  getLastCurrency() {
    return this.client.get("/currency/find-currency");
  }

  getCOOQuote(body) {
    return this.client.post("/pricing/price-coord", body);
  }

  async quote(shipmentData) {
    const { data, status } = await this.client.post("/quote", shipmentData);

    if (status !== 201 || !Array.isArray(data.data)) {
      throw new Error("Can't quote the shipment");
    }

    return data.data;
  }
}
