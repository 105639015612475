import { typesShipment } from "./shipment.types";

const initialState = {
  isNational: null,
  isLoading: false,

  Consignee: {
    City: "",
    ColombianZone: "",
    ColombianZoneCode: "",
    PostalCode: "",
    CompanyName: "",
    PersonName: "",
    PhoneNumber: "",
    Email: "",
    Address: "",

    CountryCode: "",
    CountryName: "",
    StateOrProvinceCode: "",
  },

  Shipper: {
    City: "",
    ColombianZone: "",
    ColombianZoneCode: "",
    PostalCode: "",
    CompanyName: "",
    PersonName: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
  },

  newShipment: {
    totalPayment: "0",
    ratePayForShipment: "0",
    Shipment: {
      IsInternationalShippment: false,
      Dutiable: {
        DeclaredValue: "",
        DeclaredCurrency: "",
        ShipperEIN: "",
      },
      ShipmentDetails: {
        PiecesArray: [],
        Date: "",
        Contents: "",
        CurrencyCode: "",
      },
      ExportDeclaration: {
        InterConsignee: "Cosg32424", // Nombre Consignee No preguntar
        SignatureName: "Madison May", // Nombre Consignee No preguntar
        ExportReason: "Ventas",
        ExportReasonCode: "P",
        InvoiceNumber: "N/A",
        InvoiceDate: "2021-12-31",
        ExportLineItemsArray: [
          {
            LineNumber: "1",
            Quantity: "1",
            Description: "",
            Value: "56.525", // VAlor declarado
            CommodityCode: "N/A",
            Weight: {
              Weight: "0.200", // Peso total
            },
            GrossWeight: {
              Weight: "0.250", // Peso total
            },
            ManufactureCountryCode: "CO",
            ImportCommodityCode: "N/A",
            CustomsPaperworkArray: [
              {
                CustomsPaperworkType: "INV",
                CustomsPaperworkID: "MyDHLAPI - LN#1-CUSDOC-001",
              },
            ],
          },
        ],
        PlaceOfIncoterm: "GAILDORF PORT", // Peso total
        ShipmentPurpose: "COMMERCIAL",
        InvoiceTotalNetWeight: "0.400",
        InvoiceTotalGrossWeight: "0.500",
      },
    },
    Shipal: {
      Insurance: false,
      InsuranceValue: 0,
      ShipmentValue: 2000,
      ShipalAccount: "",
      SelectedCurrier: "",
      Currency: "",
      ShipmentDate: "",
      PayableWeight: 0,
    },
  },
};

export const shipmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case typesShipment.SET_SHIPPER_INFO:
      return {
        ...state,
        Shipper: {
          ...state.Shipper,
          ...payload,
        },
      };

    case typesShipment.SET_CONSIGNEE_INFO:
      return {
        ...state,
        Consignee: {
          ...state.Consignee,
          ...payload,
        },
      };

    case typesShipment.SET_TYPE_SHIPPING:
      return {
        ...state,
        isNational: payload,
      };

    case typesShipment.loadingShipment:
      return {
        ...state,
        isLoading: payload,
      };
    case typesShipment.updateShipment:
      return {
        ...state,
        newShipment: payload,
      };

    case typesShipment.updateShipmentStep1:
      return {
        ...state,
        Consignee: payload.Consignee,
        Shipper: payload.Shipper,
      };
    case typesShipment.updateShipmentStep2:
      return {
        ...state,
        newShipment: {
          ...state.newShipment,
          Shipment: {
            ...state.newShipment.Shipment,
            IsInternationalShippment: false,
            ShipmentDetails: payload.ShipmentDetails,
            Dutiable: {
              DeclaredValue: payload.Dutiable.DeclaredValue.toString(),
              ShipperEIN: "",
            },
          },
          Shipal: {
            ...state.newShipment.Shipal,
            InsuranceValue: payload.Dutiable.DeclaredValue,
            Insurance: false,
            ShipmentDate: payload.ShipmentDetails.Date,
            PayableWeight: payload.Shipal.PayableWeight,
          },
        },
      };
    case typesShipment.updateShipmentStep3:
      return {
        ...state,
        newShipment: {
          ...state.newShipment,
          Shipment: {
            ...state.newShipment.Shipment,
            ExportDeclaration: { ...payload },
            ShipmentDetails: {
              ...state.newShipment.Shipment.ShipmentDetails,
              Contents: payload.ExportLineItemsArray[0].Description,
            },
          },
        },
      };

    case typesShipment.updateShipmentStep5:
      return {
        ...state,
        newShipment: {
          ...state.newShipment,
          totalPayment: payload.ratePayForShipment.toString(),
          ratePayForShipment: payload.ratePayForShipment.toString(),
          Shipment: {
            ...state.newShipment.Shipment,
            IsInternationalShippment: !payload.national,
            Dutiable: {
              ...state.newShipment.Shipment.Dutiable,
              DeclaredCurrency: payload.duitableCurrency,
            },
          },
          Shipal: {
            ...state.newShipment.Shipal,
            Currency: payload.Currency,
            ShipmentValue: parseInt(payload.ratePayForShipment),
            InsuranceValue: payload.InsuranceValue.toString(),
            Insurance: payload.Insurance,
            ShipalAccount: payload.ShipalAccount,
            SelectedCurrier: payload.SelectedCurrier,
            needCarrierMark: payload.needCarrierMark,
          },
        },
      };

    case typesShipment.resetShipment:
      return {
        ...initialState,
        isNational: state.isNational,
      };

    default:
      return state;
  }
};
