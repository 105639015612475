import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import { LoginMessage } from "../../molecules/login-message";
import { SectionImg } from "../../organisms/section-img";
import RegisterForm from "../../../forms/Signup";
import { useQueryString } from "hooks/v2/useQueryString";

import { ApiService } from "../../../services";

export const Register = () => {
  const [modalMessage, setMessage] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [search] = useQueryString();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if((search.access_token && search.access_token !== null) && (search.shop && search.shop !== null)) {
      sessionStorage.setItem('access_token', search.access_token);
      sessionStorage.setItem('shop', search.shop);
      queryParams.delete('access_token');
      queryParams.delete('shop');
      history.replace({
        search: queryParams.toString(),
      })
    }
    }, [search, history, location]
  );
  const handleRegister = async (values) => {
    try {
      let shopifyData = null;
      if((sessionStorage.getItem('access_token') && sessionStorage.getItem('access_token') !== null) && (sessionStorage.getItem('shop') && sessionStorage.getItem('shop') !== null)) {
        shopifyData = {
          shop: sessionStorage.getItem('shop'),
          access_token: sessionStorage.getItem('access_token')
        }
      }
      const { status, data } = await ApiService.AuthService.register(values);

      if(shopifyData && shopifyData !== null) {
        await ApiService.AuthService.shopifyMatch({ shop: shopifyData.shop, accessToken: shopifyData.access_token }, data.data.token);
      }

      if (status === 201) {
        history.push(`/verify-account?email=${values.email}`);
      }
    } catch (error) {
      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage("No pudimos registrar tu cuenta, intentalo de nuevo");
      }
    }
  };

  const handleStyles = { overflow: "scroll", height: "100vh" };

  return (
    <>
      <Row className="p-0 m-0">
            <Col style={handleStyles}>
              <RegisterForm onSubmit={handleRegister} />
            </Col>
            <SectionImg hiddeMobile imgUrl={""} screen="register" />

      </Row>
      <LoginMessage
        message={modalMessage}
        handleMessage={() => setMessage(null)}
      />
    </>
  );
};
