import React from 'react';
import { Form } from "react-bootstrap";

export const FormControl = ({children, ...props}) => {
    return (
        <Form.Control {...props}>
            {children}
        </Form.Control>
    )
}
