import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export const OrderTag = ({ order, generateLabelLink }: any) => {
  const handleButtonClick = () => {
    window.open(generateLabelLink(order.label_image_id), "_blank");
  };
  return (
    <Tooltip title="Haz click para descargar tu guia" placement="top">
      <IconButton aria-label="copy" color="primary" onClick={handleButtonClick}>
        <FileDownloadOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
