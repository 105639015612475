import { ErrorMessage, useField } from "formik";
import { Form, FormControl } from "react-bootstrap";

export const SelectField = ({
  label,
  name,
  defaultText,
  options = [],
  ...rest
}) => {
  const [field] = useField(name);
  return (
    <Form.Group className="m-2">
      {label && <Form.Label className="font-weight-bold">{label}</Form.Label>}

      <FormControl as="select" {...field} {...rest}>
        <option value="" defaultValue>
          {defaultText}
        </option>

        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </FormControl>

      <ErrorMessage name={name}>
        {(msj) => <Form.Text className="text-danger">{msj}</Form.Text>}
      </ErrorMessage>
    </Form.Group>
  );
};
