import React from "react";
import DynamicDataTable from "@langleyfoxall/react-dynamic-data-table";
import { Image } from "react-bootstrap";
import { Loading } from "../../atoms/loading";
import InssurenceIcon from "../../../assets/icons/InsurrenceIcon.svg";
import {
  StyledQuoteDiv,
  InssurenceBoxTr,
  InssurenceTextBox,
  StyledButton,
  InssurenceRadio,
} from "./style";
import { Currency } from "../../atoms/Currency";

const tableColumnNames = {
  service: "Servicio",
  precio: "Valor",
  urlImg: "Courier",
};

const reformatValueByField = (field, value) => {
  switch (field) {
    case "urlImg":
      return <Image src={value} width="100px" alt="Shipal Currier Img" />;
    case "Contact":
      return value.PersonName;
    case "precio":
      return <span style={{ width: "95px" }}>{value}</span>;
    default:
      return value;
  }
};

export const Table = ({
  quote,
  isQuote = false,
  safe,
  buttons = [],
  handleChangeInsurance,
  values = {
    toggle: false,
  },
  currency,
}) => {
  if (isQuote)
    return (
      <StyledQuoteDiv variant={!values.toggle ? "primary" : "secondary"}>
        <div
          style={{
            borderColor: "#7E00FB",
            color: "#7E00FB",
            backgroundColor: "white",
            borderRadius: "5px",
            margin: "1em",
          }}
        >
          <DynamicDataTable
            className="table table-sm"
            rows={quote}
            orderByDirection={"asc"}
            fieldMap={{
              delivery: "Carrier",
              safe: "Valor de seguro (opcional)",
              price: "Valor de envío",
            }}
            dataItemManipulator={reformatValueByField}
            buttons={[]}
            noDataComponent={
              <div
                style={{
                  padding: "1em",
                }}
              >
                <h5>
                  Revisar los precios de los envíos y las diferentes opciones
                  que ofrecemos.
                </h5>
              </div>
            }
            loading={false}
            loadingComponent={<Loading title="Cargando..." />}
          />
        </div>
      </StyledQuoteDiv>
    );

  return (
    <StyledQuoteDiv variant={!values.toggle ? "primary" : "secondary"}>
      <div
        style={{
          borderColor: "#7E00FB",
          color: "#7E00FB",
          backgroundColor: "white",
          borderRadius: "5px",
          margin: "1em",
        }}
      >
        <DynamicDataTable
          className="table table-sm"
          // renderCheckboxes
          rows={quote}
          orderByDirection={"asc"}
          fieldsToExclude={[
            /_?id/,
            "name",
            "isActive",
            "isEnabled",
            "isNational",
            "idDoc",
            "durationsDays",
            "pricing",
            "safe",
            "currency",
          ]}
          fieldMap={tableColumnNames}
          dataItemManipulator={reformatValueByField}
          buttons={buttons}
          noDataComponent={
            <div
              style={{
                padding: "1em",
              }}
            >
              <h5>
                Revisar los precios de los envíos y las diferentes opciones que
                ofrecemos.
              </h5>
            </div>
          }
          loading={false}
          footer={(footer) => (
            <>
              <br />
              <br />
              <h4>Seguro</h4>
              <InssurenceBoxTr
                variant={values.toggle ? "primary" : "secondary"}
              >
                <td>
                  <Image
                    src={InssurenceIcon}
                    alt="Shipal Insurence Icon"
                    width="100px"
                  />
                </td>
                <td colSpan="2">
                  <InssurenceTextBox>
                    <h4>Mejor estar seguro! ☔ </h4>
                    <p>
                      Te gustaría asegurar tu envío contra pérdidas, daños o
                      robo por solo <Currency sufix={currency} ammount={safe} />
                    </p>
                  </InssurenceTextBox>
                </td>
                <td>
                  {!values.toggle ? (
                    <StyledButton
                      className="insurrenceBtn"
                      variant="primary"
                      type="button"
                      onClick={() => handleChangeInsurance(safe)}
                    >
                      <InssurenceRadio variant="primary" />
                      <div className="insurrenceBtnText">
                        <p>
                          Asegurar envío <br />
                          por + <Currency sufix={currency} ammount={safe} />
                        </p>
                      </div>
                    </StyledButton>
                  ) : (
                    <StyledButton
                      className="insurrenceBtn"
                      variant="secondary"
                      type="button"
                      onClick={() => handleChangeInsurance(0)}
                    >
                      <InssurenceRadio variant="secondary" />
                      <div className="insurrenceBtnText">
                        <p>
                          Envío asegurado <br />
                          por + <Currency sufix={currency} ammount={safe} />
                        </p>
                      </div>
                    </StyledButton>
                  )}
                </td>
              </InssurenceBoxTr>
            </>
          )}
          loadingComponent={<Loading title="Cargando..." />}
        />
      </div>
    </StyledQuoteDiv>
  );
};
