import React from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  FormHelperText,
  FormControl,
  Skeleton,
  MenuItem,
  Typography,
  Link,
  Alert
} from "@mui/material";

import type { SelectChangeEvent } from "@mui/material";
import { Notebook } from "services/serviceV2/orders/types";
import { useFormContext } from "react-hook-form";
import { getUserAddressBookList } from "services/serviceV2/addressBook";
import { setValueWithNoteBook } from "modules/draft/utils/recipentAndSender";
import { ConsigneeProps, ShipperProps } from "components/molecules/order-forms/types";
import { InputSelect } from "commons/input-select";
import { ADDRESS_TYPE } from 'enums/address';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  addressType: ADDRESS_TYPE;
}

interface AddressDTO {
  address_line: string;
  address_type: string;
  city: string;
  company_name: string;
  country_code: string;
  country_name: string;
  createdAt: string;
  email: string;
  is_default_address: boolean;
  person_name: string;
  phone_number: string;
  postal_code: string;
  updatedAt: string;
  user: string;
  _id: string;
}

interface DataDocsDto { 
  docs: AddressDTO[],
  limit: number;
  page: number;
  status: number;
  total: number;
  totalPages: number;
}

export const NotebookList = ({ addressType }: Props) => {
  const { setValue } = useFormContext<ShipperProps | ConsigneeProps>();

  const { data, isLoading, isError }: UseQueryResult<DataDocsDto> = useQuery(
    ["address-book", addressType],
    () => getUserAddressBookList(addressType, 1, 200)
   );


  const handleSelectAutoComplete = (
    event: SelectChangeEvent<Notebook["_id"]> | SelectChangeEvent<ShipperProps["_id"]>
  ) => {
    const notebookData = data?.docs?.find(
      (notebook) => notebook._id === event.target.value
    );
    if (notebookData) setValueWithNoteBook(setValue, notebookData);
  };

  if (isLoading)
    return (
      <Skeleton
        sx={{
          height: "5rem",
          width: "100%",
        }}
      />
    );

  if (isError)
    return (
      <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} sx={{ textAlign: 'center' }} severity="warning">
        Actualmente no tienes contactos guardados, por favor revisa tu directorio. 
        <Link href="/address-book">
          <b>
           Ve a tu directorio.
          </b>
        </Link>
      </Alert>
    );

  return (
    <FormControl fullWidth size="small">
      <InputSelect
        name="tt"
        onChange={handleSelectAutoComplete as any}
        defaultValue=""
        size="small"
        label="Tu Empresa"
        fullWidth
        InputLabelProps={{ shrink: true }}
      >
        {data?.docs?.map((notebook) => (
          <MenuItem key={notebook._id} value={notebook._id}>
            {`${notebook.person_name} ${notebook.company_name && '-' } ${notebook.company_name}`}
          </MenuItem>
        ))}
      </InputSelect>

      <FormHelperText>
        Puedes auto rellenar con los contactos almacenados
      </FormHelperText>
    </FormControl>
  );
};
