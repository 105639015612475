import * as Yup from "yup";

const defaultStringSchema = Yup.string().required("Campo obligatorio");

export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required("El nombre es obligatorio"),
  lastName: Yup.string().required("El apellido es obligatorio"),
  email: Yup.string()
    .email("Debe ser un correo valido")
    .required("El correo es obligatorio"),
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe incluir mínimo 8 caracteres."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Las contraseñas deben coincidir"
  ),
  policies: Yup.bool().oneOf(
    [true],
    "Deben aceptarse los términos y condiciones."
  ),
  phone: Yup.string().required("El número celular es obligatorio"),
  enterpriseName: defaultStringSchema,
  documentType: defaultStringSchema,
  document: defaultStringSchema,
  industryTarget: defaultStringSchema,
  industryTarget2: Yup.string().when("industryTarget", {
    is: (industryTarget) => industryTarget === "Otro",
    then: defaultStringSchema,
  }),
  employeesNumber: defaultStringSchema,
  internationalShipmentsEstimated: Yup.number()
    .typeError("Debe ser un numero")
    .required("Campo obligatorio"),
  nationalShipmentsEstimated: Yup.number()
    .typeError("Debe ser un numero")
    .required("Campo obligatorio"),
});
