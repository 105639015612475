import { types } from "../types";

/* 
    oldActions:
    1. notebookSetIsLoandig,
    2. startAddNotebook, => newDirectory
        form-notebook
    3. startGetAllNotebooks
        home-national-international
        mynotebook
    4. startDeleteNotebook
        setting-notebooks-list
    5. startEditNotebook
        form-notebook
    6. startSelectedNotebbok
        setting-notebooks-list

*/

export const notebookSetIsLoandig = (isLoading) => ({
    type: "notebookSetIsLoading",
    payload: isLoading,
});


export const getNotebooks = (page) => {
    return async (dispatch, _, { NotebookService }) => {
      dispatch(notebookSetIsLoandig(true));
      try {
        const { data } = await NotebookService.getMyNotebooks(page);
        // console.log('Notebooks', data);
        dispatch({
            type: types.getAllNotebooks,
            payload: data,
        })
        dispatch(notebookSetIsLoandig(false));
      } catch (error) {
        dispatch(notebookSetIsLoandig(false));
        console.log(error);
      }
    };
  };




// FUNCIONES VIEJAS 
/* import {
  addNotebook,
  getAllNotebooks,
  deleteNotebook,
  editNotebook,
} from "../../../services/notebook";
import { types } from "../../types";

export const notebookSetIsLoandig = (isLoading) => ({
  type: "notebookSetIsLoading",
  payload: isLoading,
});

export const startAddNotebook = (data) => {
  return async (dispatch) => {
    dispatch(notebookSetIsLoandig(true));
    try {
      dispatch({
        type: types.addNotebook,
      });
      setTimeout(() => {
        dispatch(notebookSetIsLoandig(false));
      }, 3000);
      await addNotebook(data);
    } catch (error) {
      console.log(error);
    }
  };
};

export const startGetAllNotebooks = (uid) => {
  return (dispatch) => {
    dispatch(notebookSetIsLoandig(true));
    try {
      getAllNotebooks(uid, (orders) => {
        const array = [];
        orders.docs.forEach((doc) => {
          array.push({
            idDoc: doc.id,
            ...doc.data(),
          });
        });
        dispatch({
          type: types.getAllNotebooks,
          payload: array,
        });
        dispatch(notebookSetIsLoandig(false));
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
};

export const startDeleteNotebook = (idNotebook) => {
  return async (dispatch) => {
    dispatch(notebookSetIsLoandig(true));
    try {
      await deleteNotebook(idNotebook);
      setTimeout(() => {
        dispatch(notebookSetIsLoandig(false));
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };
};

export const startEditNotebook = (idNotebook, newData) => {
  return async (dispatch) => {
    dispatch(notebookSetIsLoandig(true));
    try {
      await editNotebook(idNotebook, newData);
      dispatch({
        type: types.editNotebook,
      });
      dispatch(notebookSetIsLoandig(false));
    } catch (error) {
      console.log(error);
    }
  };
};

export const startSelectedNotebbok = (notebookSelected) => {
  return (dispatch) => {
    dispatch(notebookSetIsLoandig(true));
    try {
      dispatch({
        type: types.seledctedNotebook,
        payload: notebookSelected,
      });
      dispatch(notebookSetIsLoandig(false));
    } catch (error) {
      console.log(error);
    }
  };
};
 */