import React from "react";
import {
  Card,
  CardContent,
  Checkbox,
  Stack,
  Typography,
  Alert,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { fnCreateDrawProps } from "components/molecules/order-forms/types";
import type { fullFormProp } from "components/molecules/order-forms/types";
import { currentFormat } from "utils/convert";
import { insuranceShield } from "assets/icons";

type Props = {
  data: Partial<fullFormProp>;
  fnSave: fnCreateDrawProps;
};

export const Insurance = ({ data, fnSave }: Props) => {
  const onSelectInsurance = async () => {
    const shipalWithInsurance = {
      ...data.shipal,
      insurance: !data.shipal?.insurance,
    };
    let totalPayment = data.total_payment ?? 0;
    if (shipalWithInsurance.insurance) {
      totalPayment = totalPayment + (shipalWithInsurance.insurance_value ?? 0);
    } else {
      totalPayment = totalPayment - (shipalWithInsurance.insurance_value ?? 0);
    }
    const newData = { ...data, total_payment: totalPayment };

    return await fnSave(newData, "shipal", shipalWithInsurance);
  };

  return (
    <>
    <Card sx={{ background: "#FAFAFA" }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: "12px !important",
        }}
      >
      <img src={insuranceShield} />
      <Stack direction="column">
        <Typography sx={{ fontSize: "1rem!important", fontWeight: "bold" }}>
          Agregar seguro
        </Typography>
        
          {(data.shipal && data.shipal.insurance_value  && data.shipal.insurance_value > 0) ? 
          <Typography
          sx={{
            fontSize: "12px!important",
            width: "150px",
            color: "#677787",
          }}
        >
          Te gustaria asegurar tu envío contra perdidas?
          </Typography>:
          <Typography
            sx={{
            fontSize: "12px!important",
            width: "150px",
            color: "#677787",
            }}
          >
            Protege tu envío de pérdidas o daños.
          </Typography>
        }
      </Stack>
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyItems: "center" }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px!important",
            fontweight: "bold",
          }}
        >
          {(data.shipal && data.shipal.insurance_value  && data.shipal.insurance_value > 0) ? 
          <Stack
          direction="row"
          sx={{ alignItems: "center", justifyItems: "center" }}
           >
            <Typography
              variant="h3"
              sx={{
                fontSize: "14px!important",
                fontweight: "bold",
              }}
            >
              {data?.shipal?.insurance_value && data?.shipal?.currency
                ? `${currentFormat(data?.shipal?.insurance_value)} ${
                    data?.shipal?.currency
                  }`
                : "$0 COP"}
            </Typography>
            <Checkbox
              disabled={!data?.shipal?.insurance_value || !data?.shipal?.currency}
              checked={data.shipal?.insurance}
              onClick={onSelectInsurance}
            />
          </Stack>
          :
          <Alert sx={{ background: "#E5F2FA" }} icon={<InfoOutlinedIcon fontSize="inherit" />} severity="info">Valor declarado requerido</Alert>
          }
        </Typography>
      </Stack>
    </CardContent>
  </Card>
    </>
  );
};
