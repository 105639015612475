import { types } from "../../types";

const initialState = {
  isLoading: false,
  packings: [],
  selectedPacking: null,
};

export const packingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "packingSetIsLoading":
      return {
        ...state,
        isLoading: payload,
      };

    case types.getAllPackings:
      return {
        ...state,
        packings: payload,
      };
    case types.selectedPacking:
      return {
        ...state,
        selectedPacking: payload,
      };
    case types.editPacking:
      return {
        ...state,
        selectedPacking: null,
      };

    default:
      return state;
  }
};
