import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SHIPPING_TYPE } from "enums/shipping";
import { createNotebook } from "services/serviceV2/orders";
import { ConsigneeProps, ShipperProps } from "components/molecules/order-forms/types";
import { useFormContext } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';

export const AddNotebookButton = () => {
  const queryClient = useQueryClient();

  const {
    getValues,
    formState: { isValid },
  } = useFormContext<ShipperProps | ConsigneeProps>();

  const { typeShipping } = useParams<{
    typeShipping: SHIPPING_TYPE.NATIONAL | SHIPPING_TYPE.INTERNATIONAL;
  }>();

  const { mutate, isLoading } = useMutation({
    mutationFn: (notebookBody: Partial<ShipperProps | ConsigneeProps>) =>
      createNotebook(notebookBody),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["myNotebooks"] }),
    onError: (error) => console.log(error),
  });

  const handleNewNotebook = useCallback(() => {
    mutate(getValues());
  }, [getValues, mutate, typeShipping]);

  const addNotebookStatus = useMemo(
    () => isLoading || !isValid,
    [isValid, isLoading]
  );

  return (
    <LoadingButton
      type="button"
      variant="text"
      loading={isLoading}
      sx={{ alignSelf: "baseline", justifySelf: "center" }}
      onClick={handleNewNotebook}
      disabled={addNotebookStatus}
    >
      + Guardar en la agenda
    </LoadingButton>
  );
};
