import React, { useState } from "react";
import { Button, Stack, Typography, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Image from "components/molecules/Image";
import { BannerMain, BannerShopify, BannerApp } from "assets/imgs";

export const ShopifyBanner = () => {
  const [open, setOpen] = useState(true);
  const bannerStatus = localStorage.getItem("shopifyBanner");
  const [showBanner] = useState(JSON.parse(bannerStatus || "true"));

  const hideAlert = () => {
    setOpen(false);
    localStorage.setItem("shopifyBanner", "false");
  };

  return (
    <>
      {open && showBanner && (
        <Paper
          elevation={4}
          sx={{
            backgroundColor: "rgba(221, 244, 230, 1)",
            width: "1000px",
            height: "280px",
            overflow: "hidden",
            borderRadius: "20px",
            marginBottom: 1,
          }}
        >
          <Stack
            direction={"row"}
            sx={{ position: "relative", width: "100%", height: "100%" }}
          >
            <Stack
              direction="column"
              width="40%"
              pl={6}
              pr={2}
              spacing={1.5}
              mt="3%"
            >
              <Typography variant="subtitle1" fontSize="20px">
                Conecta tu tienda 🙌
              </Typography>
              <Image height={33} src={BannerShopify} width={110} />
              <Typography
                variant="body2"
                fontSize="14px !important"
                color="text.secondary"
              >
                ¡Por fin disponible! Envía automáticamente las órdenes de tu
                tienda de Shopify a la aplicación de Shipal. Mejora tu
                productividad, ahorrando tiempo en la creación de las guías de
                tu negocio.
              </Typography>
              <Stack direction={"row"} spacing={2} alignItems="center">
                <Image
                  height={50}
                  src={BannerApp}
                  width={"fit-content"}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://apps.shopify.com/shipal?locale=es",
                      "_blank"
                    )
                  }
                />
                <Button
                  variant="text"
                  sx={{ height: "30px", padding: "15px 10px" }}
                  onClick={() =>
                    window.open(
                      "https://shipal.co/integracion-con-shopify/",
                      "_blank"
                    )
                  }
                >
                  <Typography variant="caption" fontWeight="bold">
                    VER MAS
                  </Typography>
                </Button>
              </Stack>
            </Stack>
            <Stack
              width="60%"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              <Image
                src={BannerMain}
                height={"fit-content"}
                sx={{
                  borderRadius: "20px 0 0 0",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                }}
              />
            </Stack>

            <IconButton
              size="small"
              aria-label="close"
              sx={{
                position: "absolute",
                top: 15,
                right: 20,
                zIndex: 1000,
              }}
              onClick={hideAlert}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>
        </Paper>
      )}
    </>
  );
};
