import styled from 'styled-components';
import { Button } from '../../atoms/button';
import { Link } from "react-router-dom";
import { FormInput } from "../../molecules/form-input";
import { colors } from '../../../constant/branding';


export const Title = styled.p`
    color: #000000;
    font-family: Roboto-bold;
    letter-spacing: 0;
    line-height: 24px;
`;

export const StyledFormInput = styled(FormInput)`
  background-color: red;
  font-weight: bolder;
`

export const StyledTextRegister = styled.p`
    font-family: Roboto-Regular;
    padding:0;
    margin:0;
`

export const StyledLogin = styled(Link) `
    font-family: Roboto-Bold;
    color: ${colors.purpule};
    text-decoration: none;
    margin-top: 2rem;
    &:hover{
      color:${colors.purpule};
    }
`

export const StyledButton = styled(Button)`
    margin-top: 2rem;
    border-color: ${props => props.variant === 'primary' ? colors.purpule : '#fff'};
    background-color: ${props => props.variant === 'primary' ? colors.purpule : '#fff'};
    color: ${props => props.variant === 'primary' ? '#fff' : colors.purpule};
    font-family: Roboto-Regular;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 5px;
    padding: 0.5rem;
    width: 106px;

    &:hover {
      background-color: ${props => props.variant === 'primary' ? '#6500c9' : colors.purpule};
      color: ${props => props.variant === 'primary' ? colors.purpule : '#fff'};
      border: 1px solid ${colors.purpule};
    }
    
`;
export const StyledQuoteDiv = styled.div`
padding: 5px;
overflow: scroll;
width: 100%;
.table {
  overflow: scroll;
}
.table-responsive{
  overflow-x: auto;
  width: 100%;
}
.table th {
  border: none;
}
.table tr {
  height: 80px;
}
.table th {
  /* border-top: 1px solid #dee2e6; */
  vertical-align: middle;
}
.table td {
  /* border-top: 1px solid #dee2e6; */
  vertical-align: middle;
  min-width: 95px;
  width: 250px;
}
.table td > button {
  width: 200px !important;
  text-align: center;
}
.rddt-action-cell{
  text-align: center;
}
`;

export const InssurenceBoxTr = styled.tr`
& > td > p > span {
  font-weight: bold !important;
}
& > td > button {
  margin: 15px;
}
& > td > h4 {
  font-weight: 900;
}
.insurrenceBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  align-content: center;
  padding: 1em;
  &:nth-child(){
    width: 386px;
  }
  & > .insurrenceBtnText{
    display: flex;
    flex-direction: column;
    & > p {
      margin: 0 !important;
    }
    & > b > span {
      font-weight: bold !important;
    }
  }
  & > .insurrenceBtnRadio{
    height: 20px;
    width: 20px;
    background-color: ${props  => props.variant !== 'secondary' ? '#FFF' : colors.purpule};
    border-radius: 50%;
    border: ${props  => props.variant !== 'secondary' ? '1px solid rgb(225, 225, 225);' : `1px solid ${colors.purpule};`};
    margin: auto;
  }
  width: 200px !important;
  text-align:left !important;

  background-color: #FFF !important;
  border: ${props  => props.variant === 'secondary' ? '1px solid rgb(225, 225, 225);' : `1px solid ${colors.purpule};`}
  color: black !important;
}
`;

export const InssurenceRadio = styled.div`
    height: 20px;
    width: 20px;
    background-color: ${props  => props.variant !== 'secondary' ? '#FFF' : colors.purpule};
    border-radius: 50%;
    border: ${props  => props.variant !== 'secondary' ? '1px solid rgb(225, 225, 225);' : `1px solid ${colors.purpule};`};
    margin: auto;
`;

export const InssurenceBox = styled.div`
  border: 1px solid rgb(225, 225, 225);
  background-color: white;
  border-radius: 5px;
  margin: 1em;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  & > p > span {
    font-weight: bold !important;
  }
  & > button {
    margin: 15px;
  }
  &> h4 {
    font-weight: 900;
  }
  &> .insurrenceBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    &:nth-child(){
      width: 386px;
    }
    & > .insurrenceBtnText{
      display: flex;
      flex-direction: column;
      & > p {
        margin: 0 !important;
      }
      & > b > span {
        font-weight: bold !important;
      }
    }
    & > .insurrenceBtnRadio{
      height: 20px;
      width: 20px;
      background-color: ${props  => props.variant === 'secondary' ? 'rgb(245, 245, 245)' : colors.purpule};
      border-radius: 50%;
      border: ${props  => props.variant === 'secondary' ? '1px solid rgb(225, 225, 225);' : `1px solid ${colors.purpule};`};
      margin: auto;
    }
    width: 200px !important;
    text-align:left !important;
    background-color: rgb(245, 245, 245) !important;
    color: black !important;
  }
`;

export const InssurenceTextBox = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

`;

export const StyledBtn = styled(Button)`
padding: 0.8em 1em;
background-color: #7E00FB;
color: white;
border: 1px solid #7E00FB;
&:hover {
    background-color: #6500c9;
    color: #fff;
}
&:focus {
    outline: none;
  }
`;

export const MainHomeDiv = styled.div``;