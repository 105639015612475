import { ADDRESS_TYPE } from "enums/address";
import { OrderStatus, OrderType } from "services/serviceV2/orders/types";

export const INITIAL_ORDER_FORM = {
    shipment_date: '',
    shipment_value: 0,
    declared_value: 0,
    total_payment: 0,
    shipper_account: '',
    is_international_shippment: false,
    contents: '',
    package_type: '',
    order_status: OrderStatus.DRAFT,
    order_type: OrderType.SHIPAL,
    invoice_number: 0,
    due_diligence: {
        consignee_completed: true,
        shipper_completed: true,
        carrier_selected: true,
        minimum_items_filled: true,
        export_declaration_required: true,
        export_declaration_completed: true,
    },
    shipper: {
        address_line: '',
        address_type: ADDRESS_TYPE.SHIPPER,
        document_number: '',
        city: '',
        country_name: "Colombia",
        country_code: "CO",
        company_name: '',
        postal_code: "0",
        state: '',
        email: '',
        person_name: '',
        phone_number: '',
        dane_code: '',
        is_default_address: false,
    },
    consignee: {
        address_line: '',
        address_type: ADDRESS_TYPE.CONSIGNEE,
        document_number: '',
        city: '',
        country_name: '',
        country_code: 'typeShipping === SHIPPING_TYPE.INTERNATIONAL ? " " : "CO"',
        company_name: '',
        postal_code: "0",
        state: '',
        email: '',
        person_name: '',
        phone_number: '',
        dane_code: '',
    },
    shipal: {
        currency: '',
        insurance: false,
        company_name: '',
        need_insurance_carrier_mark: '',
        insurance_value: 0,
        selected_carrier: '',
        shipal_account: '',
        declared_currency: "COP",
    },
    export_declaration: {
        export_reason: '',
        export_reason_code: '',
        inter_consignee: "N/A",
        declared_value: 0,
        invoice_date: '',
        invoice_number: 0,
        invoiceTotal_gross_weight: 0,
        invoiceTotal_net_weight: 0,
        shipment_purpose: '',
        signature_name: "N/A",
    },
    package_information: [],
    cart_items_information: []
}