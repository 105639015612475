import React, { useCallback, useMemo } from "react";
import { Providers } from "../providers";
import { Notifications } from "../notifications";
import { showToast } from "../../../../helpers/show-message";
import { MyPayments } from "../mypayments";
import { Dashboard } from "../../../templates/page-private-dashboard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserData } from "../../../../store/auth/auth.selectors";
import { ApiService } from "../../../../services";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import FormProvider from "commons/form-provider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText } from "commons/input-text";
import { FormActions } from "components/molecules/order-components/FormActions";
import { SubHeader } from "components/molecules/sub-header";
import {
  Box,
  Typography,
  Button,
  Stack,
  Grid,
  Modal,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  CardActionArea,
  CardContent,
  Card,
  Chip
} from "@mui/material";
import type { CardProps, BoxProps, ButtonProps } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { ContentCopyOutlined, DeleteForeverOutlined, RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { generateKeySchema } from './validation';
import { shipalLogo5, shopifyLogo } from "assets/imgs";
import { OrderType } from "services/serviceV2/orders/types";

interface apiKeys {
  _id: string;
  name: string;
  apiKey:  string;
  apiSecret:  string;
  isVisible?: boolean;
  webhookStatus: string;
};

enum HOOK_STATUS {
  "DISABLED"= "Inactivo",
  "CONNECTED"= "Activo",
  "DISCONNECTED"= "Desconectado"
}

enum HOOK_STATUS_COLORS {
  "DISABLED"= "error",
  "CONNECTED"= "success",
  "DISCONNECTED"= "warning"
}
export { Providers, MyPayments, Notifications };
export const SecretKey = () => {
  const user = useSelector(getUserData);
  const [open, setOpen] = React.useState(false);
  const { copy } = useCopyToClipboard();
  const [secretKeys, setSecretKeys] = useState<apiKeys[]>([]);
  const [currentCreationStep, setCurrentCreationStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [webStore, setWebStore] = useState(OrderType.SHOPIFY);


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(generateKeySchema),
    defaultValues: {
      name: ''
    }
  });

  const {
    setValue,
    handleSubmit,
    formState: { isValid }
  } = methods;


  const boxStyles = useMemo(
    () => ({
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "white",
      padding: "1rem",
      borderRadius: "1rem",
    }),
    []
  );

  const generatekey = async (values: any) => {
    await setIsLoading(() => true);
    const { data } = await ApiService.AuthService.generateSecretKeys(values);

    console.log("🚀 ~ file: index.jsx:29 ~ handleSecretKey ~ data:", data);
    await toggleModal();
    await methods.reset();
    await setCurrentCreationStep(() => 1);
    fetchCredentials();
  };

  const handleCopy = async (type: string, value: string) => {
    await copy(value);
    showToast({
      id: value,
      message: `📋 ${type} | Copiado en porta papeles`,
      type: "info",
    });
  };

  const setVisibility = async (data: apiKeys) => {
    const newState = secretKeys.map((apikey => {
      if(apikey._id === data._id) {
        apikey.isVisible = data.isVisible? false: true;
      }
      return apikey;
    }));
    await setSecretKeys(newState);
  };

  const toggleModal = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const fetchCredentials = async () => {
    try {
      await setIsLoading(() => true);
      const { data } = await ApiService.AuthService.getSecretKeys();

      await setSecretKeys(data as apiKeys[]);
      await setIsLoading(() => false);
    } catch (err) {
      await setSecretKeys([]);
      await setIsLoading(() => false);
      console.log(err);
    }
  };

  useEffect(() => {
      fetchCredentials();
  }, []);
  // const secretKeyList = Object.values(secretKey);

  const handleDelete = async (id: string) => {
    await ApiService.AuthService.removeSecretKeys(id);
    
    await fetchCredentials();
  };

  const selectedStyles = useMemo<CardProps["sx"]>(
    () => ({
      border: 2,
      borderColor: "#7E00FB",
    }),
    []
  );

  const imageStyles: React.CSSProperties = useMemo(
    () => ({
      width: "60%",
      marginLeft: "20%"
    }),
    []
  );

  const customBoxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      display: "flex",
      gap: "1rem",
      justifyContent: "right",
    }),
    []
  );

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );
  
  const renderChoiceCard = (cardType: OrderType) => {

    return (<Card sx={
      (cardType === webStore) ? 
      selectedStyles: {}}>
              <CardActionArea 
                onClick={() => setWebStore(OrderType.SHOPIFY)}
                sx={{ minHeight: "7rem", background: cardType === webStore? '#FCFAFF' : '' }} 
                >
                <CardContent sx={{ textAlign: 'center', padding: 2, justifyContent: 'center' }}>
                  <img
                        alt="logo del origen de la orden"
                        style={imageStyles}
                        src={
                          cardType === OrderType.SHOPIFY
                            ? shopifyLogo
                            : shipalLogo5
                        }
                    />
                  <Typography variant="body2" marginTop={3} component="p">
                    <b>Shopify</b>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>);
}

  return (
    <Dashboard>
      <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyles}>
        <Typography sx={{ m: 2 }} variant="h4">
          Generar nueva Llave de API
        </Typography>

        <Grid
          container
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(generatekey)}>
            {currentCreationStep === 1 &&
              <Box mt={2} mb={4}>
                {renderChoiceCard(OrderType.SHOPIFY)}
                <Box mt={4} display="flex" sx={customBoxStyles}>
                  <Button
                    type="button"
                    variant="text"
                    onClick={async () => {
                      await setCurrentCreationStep(() => 1);
                      await methods.reset();
                      await toggleModal();
                    }}
                    sx={cancelButtonStyles}
                  >
                    Cancelar
                  </Button>

                  <LoadingButton  type="button" onClick={() => setCurrentCreationStep(() => 2)} variant="contained">
                    Continuar
                  </LoadingButton>
                </Box>
              </Box>
            }
            {currentCreationStep === 2 &&
            <Box>
                <Box sx={{ 
                
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: 2,
                  width: "100%"
              }}>
              <InputText name="name" size="small" label="Nombre descriptivo" required variant="outlined"/>
              </Box>
              <FormActions isLoading={isLoading} isValid={isValid} handleClose={async () => {
                      await setCurrentCreationStep(() => 1);
                      await methods.reset();
                      await toggleModal();
                    }} />
            </Box>
          }
          </FormProvider>
        </Grid>
      </Box>
    </Modal>

       <Grid container flexDirection="column" pr="15px !important">
        <SubHeader />
        <Box
          sx={{
            marginTop: "1rem",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Stack flexDirection="row" justifyContent={'space-between'} gap={2} pb={2}>
            <Stack flexDirection="column" alignItems="start">
              <Typography variant="h3" sx={{ fontSize: '1.75rem!important' }}>
                Conexiones de API
              </Typography>
              <Typography variant="body2" maxWidth={400}>
                  Nos complace ofrecerle la posibilidad perfecta de conectar su tienda de comercio electrónico a Shipal.
                  Simplemente siga los pasos a continuación y comience con una solución de envío más automatizada.
              </Typography>
            </Stack>
            <Stack flexDirection="column" alignItems="center" justifyContent={'center'}>
              {secretKeys.length > 1 &&
                <Button 
                  type="button"
                  variant="outlined"
                  sx={{ alignSelf: "baseline", justifySelf: "center" }}
                  onClick={() => toggleModal()}
                >
                  + GENERAR
                </Button>
              }
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
        </Grid>


    <TableContainer>
      <Table>
        <TableHead>
        <TableRow
                sx={{
                  borderBottom: "1px solid #D9D9D9",
                }}
        >
            <TableCell 
              sx={{
                backgroundColor: "white",
                }}>Nombre</TableCell>
            <TableCell 
              sx={{
                backgroundColor: "white",
                }}>Llave</TableCell>
            <TableCell 
              sx={{
                backgroundColor: "white",
                }}>Secreto</TableCell>
            <TableCell 
              sx={{
                textAlign: "center",
                backgroundColor: "white",
                }}>Estatus</TableCell>
            <TableCell 
              align="center"
              sx={{
                backgroundColor: "white",
                }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        {secretKeys.length > 0 &&
        <TableBody>
         {secretKeys.map((row: apiKeys) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell 
                component="th"
                scope="row"
                sx={{ display: "flex", justifyContent: "space-between", textAlign: "center", alignItems: "center" }}
                >
                  {isLoading?
                    <Skeleton variant="rectangular" />:
                    row.name
                  }

                <Button 
                    type="button"
                    variant="text"
                    sx={{ alignSelf: "baseline", justifySelf: "center" }}
                    onClick={() => setVisibility(row)}
                  >
                  {row.isVisible ? <RemoveRedEyeOutlined />: <VisibilityOffOutlined />}
                </Button>
              </TableCell>
              <TableCell>
                  {isLoading?
                    <Skeleton variant="rectangular" />:
                    row.isVisible? row.apiKey: '***************'
                  }
                  <ContentCopyOutlined onClick={() => handleCopy("Llave de api",row.apiKey)} sx={{ ml: 2,  cursor: 'pointer' }} />
              </TableCell>
              <TableCell>
                  {isLoading?
                    <Skeleton variant="rectangular" />:
                    row.isVisible? row.apiSecret: '***************'
                  }
                  <ContentCopyOutlined onClick={() => handleCopy("Secreto de api",row.apiSecret)} sx={{ ml: 2,  cursor: 'pointer' }} />
              </TableCell>
              <TableCell align="center">
                <Chip variant="outlined" label={HOOK_STATUS[row.webhookStatus as keyof typeof HOOK_STATUS]} color={HOOK_STATUS_COLORS[row.webhookStatus as keyof typeof HOOK_STATUS_COLORS]} />
              </TableCell>
              <TableCell align="center"> 

                <LoadingButton 
                  type="button"
                  startIcon={<DeleteForeverOutlined />}
                  variant="outlined"
                  color="error"
                  sx={{ alignSelf: "baseline", justifySelf: "center" }}
                  onClick={() => handleDelete(row._id)}
                  loading={isLoading}
                >
                  Borrar
                </LoadingButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
      </Table>
    </TableContainer>
    {secretKeys.length < 1 &&
            <Box 
              sx={{
                textAlign: "center",
                minHeight: "300px",
                background: "#b9babcd6",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}>
                <Box>
                  <Typography variant="h3" sx={{ fontSize: '1rem!important', marginBottom: 4 }}>
                    Crea tu conjunto de claves
                  </Typography>
                </Box>
                <Box>
                  <Button 
                    type="button"
                    variant="contained"
                    sx={{ alignSelf: "baseline", justifySelf: "center" }}
                    onClick={() => toggleModal()}
                  >
                    + GENERAR
                  </Button>
                </Box>
            </Box>
          };
    </Dashboard>
  );
};
