import { InputSelect } from "commons/input-select";
import React, { useCallback, useEffect } from "react";
import { MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getCountries } from "services/serviceV2/orders";
import { countriesWithStates } from "constant/data";
import { InputSmallForm } from "commons/small-input-form";
import { useFormContext } from "react-hook-form";
import { ADDRESS_TYPE } from 'enums/address';
import type { ConsigneeProps, ShipperProps, fullFormProp } from "components/molecules/order-forms/types";


type Props = {
  onChangeSelectByCountry: (_target: any, index: any, isDefault: boolean) => void;
  onChangeSelectByState?: (data: any) => void;
  addressType: string;
  id: string;
  isForQuote?: boolean;
  valuesforQuote?: Partial<ShipperProps | ConsigneeProps>;
  isInternationalShippment?: boolean
};

export const CountriesStatesSelector = ({ onChangeSelectByCountry, onChangeSelectByState, addressType, id = "", isForQuote, valuesforQuote, isInternationalShippment }: Props) => {
  const { getValues } = useFormContext();
  console.log("VALUES-------");
  console.log(getValues());
  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  const listStates = useCallback(
    (countryCode: string) =>
      countriesWithStates.find((country) => country.countryCode === countryCode)
        ?.states,
    []
  );


  const onChangeSelectByCountryFn = async(event: any) => {
    if(onChangeSelectByState){
      await onChangeSelectByState({
        ...valuesforQuote,
        state: event.target.value
      })
    }
  }

  return (
    <>
    {!isForQuote?
      <>
      {(addressType !== ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE && addressType !== ADDRESS_TYPE.SHIPPER)?
      <InputSelect
      sx={{ width: "50%" }}
      name={`${id}country_name`}
      id={`${id}country_name`}
      size="small"
      label="Pais"
      required
      value={getValues().country_name}
      onChange={onChangeSelectByCountry as any}
    >
      {
        countries?.map((country: any, key: number) => (<MenuItem value={country.countryName} key={key}>
          {country.countryName}
        </MenuItem>))
      }
      
    </InputSelect>:
    <InputSmallForm disabled id={`${id}country_name`} name={`${id}country_name`} defaultValue={'Colombia'} value={'Colombia'} label="Pais" />}
      {listStates(getValues().country_code) ? (
        <InputSelect
          name={`${id}state`}
          size="small"
          id={`${id}state`}
          label="Estado o Provincia"
          sx={{ width: "50%" }}
          required
          error={getValues().location_validation?.state?.color === "RED"}
          helperText={getValues().location_validation?.state?.label || ''} 
        >
          <MenuItem value="">Seleccionar estado</MenuItem>
          {listStates(getValues().country_code)!.map((state) => (
            <MenuItem key={state.abbreviation} value={state.abbreviation}>
              {state.name}
            </MenuItem>
          ))}
        </InputSelect>
      ) : 
      (
        <InputSmallForm
          id={`${id}state`}
          name={`${id}state`}
          disabled
          value={getValues().state}
          label="Estado o Provincia"
          error={getValues().location_validation?.state?.color === "RED"}
          helperText={getValues().location_validation?.state?.label || ''} />
      )}
      </>:
      <>
      {(addressType !== ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE && addressType !== ADDRESS_TYPE.SHIPPER)?
      <InputSelect
      sx={{ width: "50%" }}
      name={`${id}country_name`}
      id={`${id}country_name`}
      size="small"
      label="Pais"
      value={valuesforQuote?.country_name}
      onChange={onChangeSelectByCountry as any}
    >
      {
        countries?.map((country: any, key: number) => (<MenuItem value={country.countryName} key={key}>
          {country.countryName}
        </MenuItem>))
      }
      
    </InputSelect>:
    <InputSmallForm disabled id={`${id}country_name`} name={`${id}country_name`} defaultValue={'Colombia'} value={'Colombia'} label="Pais" />}
      {listStates(valuesforQuote?.country_code!) && 
        (<InputSelect
          name={`${id}state`}
          size="small"
          id={`${id}state`}
          label="Estado o Provincia"
          sx={{ width: "50%", ml: 4 }}
          required
          onChange={onChangeSelectByCountryFn as any}
        >
          <MenuItem value="">Seleccionar estado</MenuItem>
          {valuesforQuote?.country_code && listStates(valuesforQuote?.country_code)!.map((state) => (
            <MenuItem key={state.abbreviation} value={state.abbreviation}>
              {state.name}
            </MenuItem>
          ))}
        </InputSelect>)}
      </>
    }
    </>
  );
};
