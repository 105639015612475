import styled from "styled-components";
import { Button } from "../../atoms/button";
import { colors } from '../../../constant/branding';

export const SectionWelcome = styled.div`
  font-weight: 700;
  margin-bottom: 1rem;
  p:first-child {
    color: ${colors.purpule};
    margin: 0px;
  }
  .logged-in{
    color: green;
  }
  .logged-out{
    color: red;
  }
`;

export const SectionSaldo = styled.div`
  font-weight: 700;
  p:first-child {
    margin: 0px;
  }
  p {
    margin-bottom: 10px;
  }
`;

export const ContainerButtons = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerButtonChat = styled.div`
  width: 100%;
  margin-top: auto;
  button {
    padding: 0.6rem;
    width: 106px;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: white;
      color: #ff3366;
      border: 1px solid #ff3366;
    }
  }
`;

export const SideBarContainer = styled.aside`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 210px;
  justify-content: start;
  min-height: 100vh;
`;

export const BtnSaldo = styled(Button)`
  padding: 0.8em 1em;
  background-color: ${colors.purpule};
  color: white;
  border: 1px solid ${colors.purpule};
  &:hover {
    background-color: #6500c9;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
`;

export const BtnLogout = styled(Button)`
  padding: 0.5rem 0px;
  margin: 5px 0px;
  color: #212529;
  border: none;
  width: 106px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: #212529;
  }
`;

export const BtnSoporte = styled(Button)`
  padding: 0.5rem;
  width: 106px;
  &:hover {
    background-color: white;
    color: ${colors.purpule};
    border: 1px solid ${colors.purpule};
  }
`;
