import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import { firebaseConfig } from './config';


class Firebase {

  constructor() {
    let app = null;
    if (!firebase.apps.length) {
      app = firebase.initializeApp(firebaseConfig)
      app.firestore().enablePersistence()
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics()
  }

}

const fb = new Firebase();


export default fb;
export { firebase }