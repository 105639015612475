const { REACT_APP_AWS_BRANCH } =  process.env;
const externalAppUrl = REACT_APP_AWS_BRANCH === 'prod' ? '/' : 'https://develop.shipal.co'

export const sideLinks = (token) => [
  {
    name: "Envíos",
    path:
      REACT_APP_AWS_BRANCH === "prod"
        ? "/shipments/realizados"
        : `${externalAppUrl}/orders?token=${token}`,
  },
  {
    name: "Directorio",
    path: "/address-book",
  },
  {
    name: "Mi billetera",
    path: "/wallet",
  },
  {
    name: "Cuenta",
    path:
      REACT_APP_AWS_BRANCH === "prod"
        ? "/account"
        : `${externalAppUrl}/account?token=${token}`,
  },
  {
    name: "Cotizar envío",
    path: "/quoter",
  },
  // {
  //   name: "Reportes",
  //   path: "/reports",
  // }
];
