import React, { useEffect } from "react";
import PropTypes from 'prop-types';       
import { Route, Redirect } from "react-router-dom";
import { useQueryString } from "hooks/v2/useQueryString";
import { useHistory, useLocation } from "react-router-dom";

export const PrivateRoute = ({ isLoggedIn, component: Component, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const [search] = useQueryString();

  useEffect(() => {
    if((search.access_token && search.access_token !== null) && (search.shop && search.shop !== null)) {
      sessionStorage.setItem('access_token', search.access_token);
      sessionStorage.setItem('shop', search.shop);
    }
    }, [search, history, location]
  );
  return (
    <Route
      {...rest}
      component={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={`/login/${(sessionStorage.getItem('access_token') && sessionStorage.getItem('access_token') !== null) && (sessionStorage.getItem('shop') && sessionStorage.getItem('shop') !== null)? `?access_token=${sessionStorage.getItem('access_token')}&shop=${sessionStorage.getItem('shop')}`: ''}`} />
      }
    />
  );
};

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  component: PropTypes.func,
}