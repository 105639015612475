import React from 'react';
import Row from "react-bootstrap/Row";
import { StyledListGroupItem } from './styles';

export const BasicCardListItem = ({ children }) => {
    return (
        <StyledListGroupItem>
          <Row style={{ marginTop: '1em', marginBottom: '1em' }}>
              {children }
            </Row>
        </StyledListGroupItem>
    )
};