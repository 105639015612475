export class PriceZoneService {
  constructor(client) {
    this.client = client;
  }

  getPriceZone = (nameZone, type) => {
    try {
      const { data } = this.client.get(
        `/settings/getPriceZone/${nameZone}/${type}`
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
