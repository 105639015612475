import { boxTypes } from "constant/shippingConstants";
import { FieldValues, UseFormSetValue } from "react-hook-form";

export const setValueSave = (
  setValue: UseFormSetValue<FieldValues>,
  lengthItemsInformation: number,
  setIsDisabled: any,
  valuesOrIndex: any
) => {
  const boxKeysValidations = ["height", "depth", "width"];
  const typeValue = typeof valuesOrIndex == "number";

  boxKeysValidations.forEach((key: string) => {
    setValue(
      key,
      !typeValue
        ? valuesOrIndex[key]
        : boxTypes[valuesOrIndex!][key as keyof (typeof boxTypes)[0]]
    );
  });
  setValue(
    "piece_contents",
    !typeValue
      ? valuesOrIndex.piece_contents
      : boxTypes[valuesOrIndex!].piece_contents
  );
  setValue(
    "package_type",
    !typeValue
      ? valuesOrIndex.package_type
      : boxTypes[valuesOrIndex!].package_type
  );

  setValue("weight", !typeValue ? valuesOrIndex.weight : 0);
  setValue("value", !typeValue ? valuesOrIndex.value : 0);
  setValue("piece_ID", lengthItemsInformation);

  setIsDisabled(
    typeValue && boxTypes[valuesOrIndex!].height === "0" ? false : true
  );
};
