import React from "react";
import { StyledContainer, StyledTab } from "./styles";

export const TabMenuBar = ({
  tabsList,
  setKey,
  componentRender,
  defaultActiveKey,
  activeKey,
}) => {
  return (
    <StyledContainer
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      onSelect={(key) => setKey(key)}
    >
      {tabsList.map((tab, index) => (
        <StyledTab eventKey={tab} title={tab} key={index}>
          {componentRender(tab)}
        </StyledTab>
      ))}
    </StyledContainer>
  );
};
