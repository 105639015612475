export const EDIT_PATH_NATIONAL = (orderId: string) => [
  {
    title: "Mis Ordenes",
    path: "/shipments/realizados",
  },
  {
    title: "Mis Ordenes Pendientes",
    path: "/shipments/realizados",
  },
  {
    title: `Order ${orderId}`,
    path: `/shipping/national/${orderId}`,
  },
];

export const EDIT_PATH_INTERNATIONAL = (orderId: string) => [
  {
    title: "Mis Ordenes",
    path: "/shipments/realizados",
  },
  {
    title: "Mis Ordenes Pendientes",
    path: "/shipments/realizados",
  },
  {
    title: `Order ${orderId}`,
    path: `/shipping/international/${orderId}`,
  },
];
