import React, { useState, useEffect } from "react";
import { TableList } from "../../molecules/table-list";
import { ItemProvider } from "../../molecules/item-provider";
import { StyledContainer } from "./styles";
import { dashboardCurrentList } from "../../../constant/data";
import { useSelector/* , useDispatch */ } from "react-redux";
// import { changeIsActiveService } from "../../../store/redux/actions/auth";
import { filterServices } from "../../../helpers";

export const SettingProviders = () => {
  const { carriersDisabled } = useSelector((state) => state.auth);
  const [item, setItems] = useState(dashboardCurrentList);

  // const dispatch = useDispatch();

  const filterActive = (i) => i.isEnabled;
  const filterNational = (i) => i.isNational;
  const filterInternational = (i) => !i.isNational;

  const changeStateServices = (item, checked) => {
    setItems((prev) => ({
      data: {
        ...prev.data,
        services: [
          ...prev.data.services.map((service) =>
            service.idDoc === item.idDoc
              ? { ...service, isActive: checked }
              : service
          ),
        ],
      },
    }));
  };

  const changeState = (item, checked) => {
    if (checked === false && !carriersDisabled.includes(item.idDoc)) {
      // dispatch(changeIsActiveService("add", item.idDoc));
    } else if (checked === true && carriersDisabled.includes(item.idDoc)) {
      // dispatch(changeIsActiveService("delete", item.idDoc));
    }
    changeStateServices(item, checked);
  };

  useEffect(() => {
    const getServices = async () => {
      const newServices = await filterServices(
        item.data.services,
        carriersDisabled
      );
      setItems((prev) => ({
        ...prev,
        data: { ...prev.data, services: newServices },
      }));
    };
    getServices();
    // eslint-disable-next-line
  }, [carriersDisabled]);

  return (
    <StyledContainer>
      <h3>Mis Proveedores</h3>
      <hr />
      <p>Internacional</p>
      <TableList
        list={item.data.services
          .filter(filterInternational)
          .filter(filterActive)}
        headers={["", "", "Activar"]}
        itemComponent={(props) => (
          <ItemProvider
            key={props.idDoc}
            perks={item.data.perks}
            item={Boolean(props) && props}
            fnChangeState={(item, checked) => {
              changeState(item, checked);
            }}
          />
        )}
      />
      <div className="containerListNational">
        <p>Nacional</p>
        <TableList
          list={item.data.services.filter(filterNational).filter(filterActive)}
          headers={["", "", "Activar"]}
          itemComponent={(props) => (
            <ItemProvider
              key={props.idDoc}
              perks={item.data.perks}
              item={Boolean(props) && props}
              fnChangeState={(item, checked) => {
                changeState(item, checked);
              }}
            />
          )}
        />
      </div>
    </StyledContainer>
  );
};
