import React, { useState } from "react";
import { Modal, Card, Grid, Divider, Typography, Button, Container } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { currentFormat } from "utils/convert";
import { MdWarningAmber } from "react-icons/md";
import { useHistory } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

export interface BalanceValidationData  {
  actualBalance?: string;
  finalBalance?: string;
  amountToDeb?: string;
  haveCapacity?: boolean;
}

export interface BalanceValidationProps  {
  balanceData?: BalanceValidationData;
  open: boolean;
  isLoadingPayment: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}
    
export const PaymentAds = ({ balanceData, open, handleClose, handleConfirm, isLoadingPayment }: BalanceValidationProps) => {
  const history = useHistory();

  const addBAlance = () => {
    history.push({ pathname: "/user-wallet", state: { value: balanceData?.amountToDeb? balanceData?.amountToDeb: '' } });
  }

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
 <Container maxWidth="xs" sx={{ justifyContent: 'center', height: '100vh' }}>
        <Card sx={{ 
          transform: 'translate(-50%, -50%)',
          top: '50%',
          bottom: '50%',
          left: 'calc(50%)',
          maxWidth: '500px',
          mat: 'auto',
          mab: 'auto'
         }}>
          <Grid container>
            <Grid xs={12} item padding={2} sx={{ background: '#FAFAFA' }}>
              <Typography variant="h4">
                Confirmar pago {!balanceData?.haveCapacity && <MdWarningAmber color="red" />}
              </Typography>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid xs={12} item>
              <Grid container padding={2}>
                <Grid xs={6} item>
                  <Typography variant="h4">
                    Saldo inicial
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  {balanceData?.haveCapacity &&
                    <Typography variant="body1" textAlign={'right'}>
                      {balanceData?.actualBalance && currentFormat(parseFloat(balanceData?.actualBalance))}
                    </Typography>
                  }
                  {!balanceData?.haveCapacity &&
                    <Typography variant="body1" textAlign={'right'}>
                      <Typography variant="body1" color={'red'}>
                        {balanceData?.actualBalance && currentFormat(parseFloat(balanceData?.actualBalance))}
                      </Typography>
                      <Typography variant="body1" color={'red'}>
                        ¡Saldo insuficiente!
                      </Typography>
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid container padding={2}>
                <Grid xs={6} item>
                  <Typography variant="h4">
                    Costo de envío
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant="body1" textAlign={'right'}>
                    - {balanceData?.amountToDeb && currentFormat(parseFloat(balanceData?.amountToDeb))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid xs={12} item>
              {balanceData?.haveCapacity &&
                <Grid container padding={2}>
                  <Grid xs={6} item>
                    <Typography variant="h4">
                      Balance final
                    </Typography>
                  </Grid>
                  <Grid xs={6} item>
                    <Typography variant="body1" textAlign={'right'}>
                      {balanceData?.finalBalance && currentFormat(parseFloat(balanceData?.finalBalance))}
                    </Typography>
                  </Grid>
                </Grid>
              }
              {!balanceData?.haveCapacity &&
                <Grid container padding={2}>
                  <Grid xs={12} item textAlign={'center'}>
                    <Typography variant="body1">
                      Cantidad requerida
                    </Typography>
                  </Grid>
                  <Grid xs={12} item textAlign={'center'}>
                    <b>
                      <Typography variant="h4">
                      {balanceData?.finalBalance && currentFormat(parseFloat(balanceData?.finalBalance)).replace('-', '')}
                      </Typography>
                    </b>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid xs={12} item paddingBottom={4}>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid xs={12} item>
                  <Grid container justifyContent={'center'} textAlign={'center'}>
                  {!balanceData?.haveCapacity && 
                    <Grid xs={7} item>
                      <Button variant="outlined" onClick={addBAlance}>
                        <AddIcon /> ABONAR SALDO
                      </Button>
                    </Grid>
                  }
                  {balanceData?.haveCapacity && 
                  <Grid xs={7} item>
                    <LoadingButton loading={isLoadingPayment} sx={{ background: "#0EBF71", ":hover": { background: "#086B3F" } }} variant="contained" onClick={handleConfirm}>PAGAR</LoadingButton>
                  </Grid>
                  }
                  </Grid>
                  <Grid container my={2} justifyContent={'center'} textAlign={'center'}>
                    <Grid xs={7} item>
                      <Button onClick={handleClose} sx={{ color: '#000' }} variant="text">Cancelar</Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Grid xs={12} item>
                      <Typography color={'gray'}>
                        !No te preocupes, tu pedido ha sido guardado¡
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Modal>
  );
}
