import React from "react";
import { Image, Col } from "react-bootstrap";
import { BasicCard } from '../../atoms/basic-card';

import {
  CardBodyStyled,
  ContainerDecriptions,
  ListMethods,
  StyledCardCustom,
} from "./styles";

export const MethodsPayments = ({
  methods,
  selectedMethod,
  setSelectedMethod,
}) => {
  return (
    <BasicCard height='auto' width='auto'>
        <ListMethods noGutters={false} lg={2} xl={2} sm={1} xs={1}>
          {methods.map((method) => (
            <Col lg="auto" key={method.code}>
              <StyledCardCustom height='auto' width='auto'
                key={method.code}
                selected={
                  JSON.stringify(method) === JSON.stringify(selectedMethod)
                }
                onClick={() => setSelectedMethod(method)}
              >
                <CardBodyStyled>
                  <Image src={method.src} fluid />
                  <p>{method.title}</p>
                </CardBodyStyled>
              </StyledCardCustom>
            </Col>
          ))}
        </ListMethods>
        {methods?.length > 0 && "pse" in methods && (<ContainerDecriptions>
          <p>Pagos Nacionales solo con PSE Y Transferencias</p>
        </ContainerDecriptions>)}
    </BasicCard>
  );
};
