export const styles = {
  iconAndLabel: { display: "flex", flexDirection: "row", gap: "0.5rem" },
  modal: {
    title: {
      fontSize: "0.9rem!important",
      color: "grey",
      fontweight: "500",
      my: 2,
      mx:1
    },
    warning: {
      fontSize: "0.8rem!important",
      color: "red",
      fontweight: "500",
      my: 2,
      ml: 2,
      mr: 2,
    },
    fieldsRow: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      marginBottom: 2,
    },
    smallTexfields: { width: "20%" },
  },
  notebook: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  saveForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
};
