import { useMutation, useQuery } from "@tanstack/react-query";
import { Dashboard } from "components/templates/page-private-dashboard";
import { SHIPPING_TYPE } from "enums/shipping";
import React, { createElement, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getUser } from "services/serviceV2";
import { createOrderDraw, getConfig } from "services/serviceV2/orders";
import { HorizontalBar } from "components/molecules/order-components/horizontalBar";
import { defaultValue } from "components/molecules/order-forms/defaultValue";
import { DraftFormInternational } from "components/molecules/order-forms/international";
import { DraftFormNational } from "components/molecules/order-forms/national";
import {
  ConsigneeProps,
  Due_diligenceProps,
  Export_declarationProps,
  Package_informationProps,
  ShipalProps,
  ShipperProps,
} from "components/molecules/order-forms/types";
import { toast } from "react-toastify";
import { CREATE_PATH_INTERNATIONAL, CREATE_PATH_NATIONAL } from "components/molecules/order-forms/breadcrumbs/create";
import { getDefaultAddress } from "services/serviceV2/addressBook";

export const DrawCreatePage = () => {
  const { typeShipping } = useParams<any>();
  const history = useHistory();
  const [dataDraft, setdataDraft] = useState(defaultValue(typeShipping));
  const { data: defautAddress } = useQuery({
    queryKey: ["defautAddress"],
    queryFn: getDefaultAddress,
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ["draw"],
    mutationFn: createOrderDraw,
    onSuccess: (resolve: any) => {
      history.push(`/shipping/${typeShipping}/${resolve._id}`);
    },
    onError: (error: any) => {
      toast("Error Servicio, Contactar a Soporte", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: 'error'
      });
    },
  });
  const handleDrawSave = async (
    data: any,
    attribute: string,
    values:
      | Partial<Due_diligenceProps>
      | Partial<ShipperProps>
      | Partial<ConsigneeProps>
      | Partial<ShipalProps>
      | Partial<Export_declarationProps>
      | Partial<Package_informationProps[]>
  ) => {
    try {
      const config = await getConfig();
      if(!config.data && typeShipping === SHIPPING_TYPE.INTERNATIONAL) {
        alert("Tenemos problemas al procesar el TRM de hoy");
      }
      return await mutateAsync({
        ...data,
        trm_used: config.data.currencyUSD,
        [attribute]: values,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const {
    data: authUser,
    isLoading: isLoadingAuth,
  }: { data: any; isLoading: boolean } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });

  useEffect(() => {

    let resetValues: any = {
      ...dataDraft,
      shipper: {
        address_line: "",
        city: "",
        company_name: "",
        email: "",
        person_name: "",
        phone_number: "",
        country_code: "",
        country_name: "Colombia",
        postal_code: "",
        document_number: "",
        state: "",
        dane_code: "",
        is_default_address: false
      }
    };

    if(defautAddress && defautAddress.data) {
      resetValues = {
        ...resetValues,
        shipper: defautAddress.data
      };
    }

    if(typeShipping === SHIPPING_TYPE.NATIONAL) {
      resetValues = {
        ...resetValues,
        consignee: {
          city: "",
          company_name: "",
          email: "",
          person_name: "",
          phone_number: "",
          postal_code: "",
          country_code: "",
          country_name: "Colombia",
          document_number: "",
          state: "",
          address_line: "",
          dane_code: "",
        }
      };
    }
    setdataDraft(() => (
      {...resetValues}
    ));
    console.log(dataDraft);
  }, [defautAddress]);

  useEffect(() => {
    if (!isLoadingAuth) {
      console.log(authUser);
      setdataDraft((prev) => ({ ...prev, shipper_account: authUser?._id }));
    }
  }, [isLoadingAuth, authUser]);

  const formTypeSelect = useMemo(() => {
    if (typeShipping === SHIPPING_TYPE.NATIONAL) {
      return createElement(DraftFormNational, {
        currentData: dataDraft,
        isLoading,
        onSave: handleDrawSave,
        type: typeShipping,
      });
    }

    return createElement(DraftFormInternational, {
      currentData: dataDraft,
      isLoading,
      onSave: handleDrawSave,
      type: typeShipping,
    });
  }, [dataDraft, isLoading, typeShipping]);

  return (
    <Dashboard>
      <HorizontalBar currentData={dataDraft} currentPaths={typeShipping  === SHIPPING_TYPE.NATIONAL ? CREATE_PATH_NATIONAL: CREATE_PATH_INTERNATIONAL} saving={!isLoading} />
      {formTypeSelect}
    </Dashboard>
  );
};
