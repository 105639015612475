import { Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Dashboard } from "components/templates/page-private-dashboard";
import React from "react";
import { useParams } from "react-router-dom";
import { getOrderById } from "services/serviceV2/orders";
import { Insurance } from "components/molecules/order-components/addSegure";
import { CheapestOptions } from "components/molecules/order-components/cheapestOptions";
import { ContactCard } from "components/molecules/order-components/contactCard";
import { HorizontalBar } from "components/molecules/order-components/horizontalBar";
import { PackageDetail } from "components/molecules/order-components/packageDetailCard";
import { CardOrderStatus } from "components/molecules/order-components/orderStatus";
import { Total } from "components/molecules/order-components/total";
import {
  ConsigneeProps,
  ShipperProps
} from "components/molecules/order-forms/types";
import { recipent, sender } from "./mook";
import { SHIPPING_TYPE } from "enums/shipping";

export default function DrawViewPage() {
  const { orderId } = useParams<any>();
  const { data: dataOrders, isLoading } = useQuery({
    queryKey: ["draft", orderId ?? ""],
    queryFn: getOrderById(orderId ?? ""),
  });
  const { typeShipping } = useParams<any>();
  const handleNull = async (_data: any) => {};

  return (
    <Dashboard>
      <HorizontalBar currentData={dataOrders} saving={!isLoading} currentPaths={[]} />
      {!!dataOrders && (
        <Grid container spacing={3} marginTop={1}>
          <Grid item xs={12} md={8}>
            <Typography variant="h3">Configura tu orden</Typography>
            <Stack spacing={2}>
              <CardOrderStatus
                current_data={dataOrders}
                
              />
              <ContactCard
                body={dataOrders?.shipper! as ShipperProps}
                title={sender.title}
              />
              <ContactCard
                body={dataOrders?.consignee! as ConsigneeProps}
                title={recipent.title}
              />
              <PackageDetail body={dataOrders?.items_information!} title="Items" typeShipping={SHIPPING_TYPE.NATIONAL} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h3">Escoge transportadora</Typography>
            <Stack spacing={2}>
              <CheapestOptions typeShipping={typeShipping} fnSave={handleNull} data={dataOrders} />
              <Insurance data={dataOrders} fnSave={handleNull} />
              <Total
                order={dataOrders}
                isView
                insure={dataOrders.shipal?.insurance ? dataOrders.shipal?.insurance_value : 0}
                shipping={dataOrders.shipment_value}
                total={dataOrders.total_payment} loading={false}              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Dashboard>
  );
}
