import styled from "styled-components";
import { colors } from '../../../constant/branding';

export const StyledContainer = styled.div`
  p {
    margin: 0px;
    padding: 0px;
    color: ${colors.purpule};
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }
  hr {
    padding-bottom: 1rem;
    margin: 0px;
  }

  .containerListNational {
    margin-top: 3rem;
  }
`;
