export const Event = {
  Screen: {
    id: "screen_enter",
    param: "Screen",
  },
  Button: {
    id: "Touched",
    param: "Button",
  },
  Event: {
    id: "screen_event",
    param: "Action",
  },
  Login: {
    id: "screen_login",
    param: "Login",
  },
  RecoverPassword: {
    id: "screen_recoverpassword",
    param: "RecoverPassword",
  },
  Home: {
    id: "screen_home",
    param: "Home",
  },
  Menu: {
    id: "menu_event",
    param: "Item",
  },

  Register: {
    id: "screen_register",
    id2: "first_register_screen",
    id3: "my_first_screen",
  },
  Account:{
    id: "screen_account",
    param: "Account",
  },
  Analytical:{
    id: "screen_analytical",
    param: "Analytical",
  },
  HomeShipping:{
    id: "screen_homeShipping",
    param: "HomeShipping",
  },
  Orders:{
    id: "screen_orders",
    param: "Orders",
  },
  Quotation:{
    id: "screen_quotation",
    param: "Quotation",
  },
  Setting:{
    id: "screen_setting",
    param: "Setting",
  },
  Shipments:{
    id: "screen_shipments",
    param: "Shipments",
  },
  UserWallet:{
    id: "screen_userWallet",
    param: "UserWallet",
  }
};
