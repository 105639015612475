import { types } from "../../types";

const initialState = {
  formNatInt: null,
  packageInfo: {
    returnGuide: false,
    proformaInvoice: false,
  },
  carrierSelect: null,
  infoDeclarationAdunasIternational: null,
  formNatIntQuote : null
};

export const registerShippingReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case types.getInfoNatInt:
      return {
        ...state,
        formNatInt: { ...payload },
      };
    case types.getInfoPackageInfo:
      return {
        ...state,
        packageInfo: { ...payload },
      };
    case types.getInfoCarrierSelect:
      return {
        ...state,
        carrierSelect: { ...payload },
      };

    case types.getInfoDeclarationAduanas:
      return {
        ...state,
        infoDeclarationAdunasIternational: { ...payload },
      };
    case types.returnGuide:
      return {
        ...state,
        packageInfo: {
          ...state.packageInfo,
          returnGuide: payload,
        },
      };

    case types.changeProformaInvoice:
      return {
        ...state,
        packageInfo: {
          ...state.packageInfo,
          proformaInvoice: payload,
        },
      };

    case types.homeRegisterShipping:
      localStorage.removeItem("idOrden");
      return {
        ...state,
        formNatInt: null,
        packageInfo: {
          ...state.packageInfo,
          returnGuide: false,
        },
        carrierSelect: null,
        infoDeclarationAdunasIternational: null,
      };

    case types.addQuotation:
      console.log("DATA AQUI REDUCER QOUTE", payload);
      return {
        ...state,
        formNatIntQuote : {...payload}
      }
    default:
      return state;
  }
};
