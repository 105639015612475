import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap'

export const Loading = ({ title, content }) => {
    return (
        <div
            style={{ height: '100vh' }}
            className="d-flex justify-content-center align-items-center align-content-center">
            <div>
                <h3>{title}</h3>
                <div className="d-flex justify-content-center">
                    <Spinner animation="grow" role="status" variant="primary" />
                </div>
                {content && (
                    <p className="text-justify text-muted pt-3">{content}</p>
                )}
            </div>
        </div>
    )
}

Loading.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
}