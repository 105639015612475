import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TabMenuBar } from "../../molecules/tab-menu-bar";
import { FormProfile } from "../../organisms/form-profile";
import { BillingInformation } from "../../organisms/account-billing-information";
import { Event } from "../../../utils/events";
import { Dashboard } from '../../templates/page-private-dashboard';

export const Account = () => {

  useEffect(() => {
    console.log("Account.useEffect");
  }, []);

  const { tab } = useParams();
  const [key, setKey] = useState(tab);

  const renderPages = (key) => {
    switch (key) {
      case "Perfil":
        return <FormProfile />;
      case "Facturación":
        return <BillingInformation />;
      default:
        return <FormProfile />;
    }
  };
  
  return (
    <Dashboard PageName="Cuenta" className="px-5 pt-3">
      <TabMenuBar
        activeKey={key}
        defaultActiveKey="Perfil"
        setKey={setKey}
        tabsList={["Perfil", "Facturación"]}
        componentRender={renderPages}
      />
    </Dashboard>
  );
};
