import React, { useEffect } from "react";
import { Field, Formik, ErrorMessage } from "formik";
import { Col, Form, Row, FormCheck } from "react-bootstrap";

import { Button } from "../../components/atoms/button";
import { Logo } from "../../components/atoms/logo";
import FormikInput from "../../components/molecules/formik-input";

import { registerSchema } from "./validations";
import { firebase } from "../../firebase";
import { Event } from "../../utils/events";
import { createEncryptedKey } from "../../utils/encryption";

import logo from "../../assets/imgs/shipal-logo4.png";
import { Title, StyledLogin, StyledTextRegister } from "./styles";

const RegisterForm = ({ onSubmit }) => {
  const prueba = async () => {
    await createEncryptedKey();
  };
  prueba();

  useEffect(() => {
    console.log("Register.useEffect");
    const analytics = firebase.analytics();
    analytics.setCurrentScreen("Register");
    analytics.logEvent(Event.Register.id, {
      [Event.Event.param]: "In Register",
    });
  }, []);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    policies: false,
    phone: "",
    enterpriseName: "",
    documentType: "",
    document: "",
    industryTarget: "",
    industryTarget2: "",
    employeesNumber: "",
    internationalShipmentsEstimated: "",
    nationalShipmentsEstimated: "",
    confirmPassword: "",
  };

  return (
    <div className="px-4 py-4">
      <div>
        <div style={{ margin: "-11px -33px 11px" }}>
          <Logo srcUrl={logo} alt="logo-shipal" heigth="120" />
        </div>
        <Title>¡Completa el formulario!</Title>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={registerSchema}
      >
        {({ handleSubmit, values, setValues, errors, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Row>
              <FormikInput label="Nombre" name="firstName" />
              <FormikInput label="Apellido" name="lastName" />
              <FormikInput
                type="email"
                autoComplete="off"
                InputFullWidth
                label="Correo"
                name="email"
              />
              <FormikInput
                type="phone"
                autoComplete="off"
                InputFullWidth
                label="Celular"
                name="phone"
              />
              <FormikInput
                label="Nombre de Empresa"
                InputFullWidth
                name="enterpriseName"
              />
              <FormikInput
                as="select"
                InputFullWidth
                label="Tipo de documento"
                name="documentType"
                onChange={(e) => {
                  const id = e.target.value;
                  setValues({ ...values, documentType: id });
                }}
              >
                <option value="documentType">Escoge uno...</option>
                <option value="Nit">Nit</option>
                <option value="CC">CC</option>
              </FormikInput>
              <FormikInput
                InputFullWidth
                label="Numero de documento"
                name="document"
              />
              <FormikInput
                as="select"
                InputFullWidth
                label="¿En qué industria esta tu empresa?"
                name="industryTarget"
                onChange={(e) => {
                  const id = e.target.value;
                  setValues({ ...values, industryTarget: id });
                }}
              >
                <option value="industryTarget">Escoge uno...</option>
                <option value="Moda">Moda</option>
                <option value="Salud y Belleza">Salud y Belleza</option>
                <option value="Decoración">Decoración</option>
                <option value="Arteasnías">Artesanías</option>
                <option value="Alimentos">Alimentos</option>
                <option value="Otro">Otro</option>
              </FormikInput>
              {values.industryTarget === "Otro" && (
                <FormikInput
                  InputFullWidth
                  label="Especifique que otro mercado."
                  name="industryTarget2"
                />
              )}
              <FormikInput
                as="select"
                InputFullWidth
                label="¿Cúantos empleados hay en tu empresa?"
                name="employeesNumber"
                onChange={(e) => {
                  const id = e.target.value;
                  setValues({ ...values, employeesNumber: id });
                }}
              >
                <option value="employeesNumber">Escoge uno...</option>
                <option value="0-10 Empleados">0-10 Empleados</option>
                <option value="11-50 Empleados">11-50 Empleados</option>
                <option value="51-99 Empleados">51-99 Empleados</option>
                <option value="Más de 100 empleados">
                  Más de 100 empleados
                </option>
              </FormikInput>
              <FormikInput
                InputFullWidth
                label="En promedio, cuántos envíos internacionales haces mensualmente?"
                name="internationalShipmentsEstimated"
              />
              <FormikInput
                InputFullWidth
                label="En promedio, cuántos envíos nacionales haces mensualmente?"
                name="nationalShipmentsEstimated"
              />
              <FormikInput
                type="password"
                autoComplete="off"
                InputFullWidth
                label="Establece tu contraseña"
                text="De 8 o màs caracteres"
                name="password"
              />
              <FormikInput
                type="password"
                autoComplete="off"
                InputFullWidth
                label="Confirma tu contraseña"
                text="De 8 o màs caracteres"
                name="confirmPassword"
              />
            </Row>
            <br />
            <Field name="policies">
              {({ field }) => {
                return (
                  <div>
                    <Form.Group
                      controlId={field.name}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "0px",
                      }}
                    >
                      <FormCheck
                        label={
                          <p>
                            Al crear su cuenta, acepta nuestros
                            <a
                              href="https://www.shipal.co/terminos-y-condiciones/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#7E00FB",
                                textDecoration: "underline",
                              }}
                            >
                              términos y condiciones
                            </a>
                            &nbsp;y&nbsp;
                            <a
                              href="https://www.shipal.co/terminos-y-condiciones/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#7E00FB",
                                textDecoration: "underline",
                              }}
                            >
                              políticas de privacidad.
                            </a>
                          </p>
                        }
                        type="checkBox"
                        {...field}
                      />
                      <ErrorMessage name={field.name}>
                        {(msj) => (
                          <Form.Text className="text-danger">{msj}</Form.Text>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </div>
                );
              }}
            </Field>
            <br />
            <Button
              type="submit"
              purple
              bold
              className="py-2 px-3"
              disabled={isSubmitting}
            >
              Crear una cuenta
            </Button>
            <Col className="py-3 d-flex justify-content-around flex-column align-items-left">
              <div className="text-center my-4">
                <StyledTextRegister>¿Ya tienes una cuenta?</StyledTextRegister>
                <StyledLogin to="/login">Iniciar Sesión</StyledLogin>
              </div>
            </Col>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;
