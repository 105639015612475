export const convertCamelCase = (item: Record<string, any>) => {
    const newMap: Record<string, any> = {};
    Object.keys(item).forEach((key: string) => {
      const keySplit =  key.split('');
      keySplit[0] = keySplit[0].toLowerCase();
      const newKey: string = keySplit.join('');
      newMap[newKey] = item[key];
    })
    return newMap;
}

export const currentFormat = (value: number) => {
	return new Intl.NumberFormat('es-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 2,
    minimumFractionDigits: 0
	}).format(value);
};
