import React from "react";
import { SettingProviders } from "../../../organisms/setting-providers";
import { Dashboard } from '../../../templates/page-private-dashboard';

// const fieldUser = {
//   balanceAvailable: 0, // number,
//   carriersDisabled: [],
//   emial: "",
//   emailCompany: "",
//   fullName: "",
//   infoSocialMedia: {}, // object contiene los siguientes campos: facebook, instagram, twitter
//   lastName: "",
//   name: "",
//   nameCompany: "",
//   notification: Boolean,
//   plan: {}, //default null, pero es un object, contiene la informacion del plan selecionado.
//   profilePicture: {}, // object, contiene: name, path, url
//   uid: "",
//   websiteCompany: "",
// };
export const Providers = () => {
  return (
    <Dashboard >
      <SettingProviders />
    </Dashboard>
  );
};
