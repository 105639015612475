import { Grid, Stack, Typography } from "@mui/material";
import { SHIPPING_TYPE } from "enums/shipping";
import { Insurance } from "components/molecules/order-components/addSegure";
import { CheapestOptions } from "components/molecules/order-components/cheapestOptions";
import { CardOrderStatus } from "components/molecules/order-components/orderStatus";
import { Total } from "components/molecules/order-components/total";
import React from "react";

import { AddressInfoCard } from "components/molecules/order-components/addressInfoCard";
import {
  fullFormProp,
  fnCreateDrawProps
} from "./types";
import { DeclareExportNational } from "components/molecules/order-components/declareExport/national";
import { OrderType } from "services/serviceV2/orders/types";
import { PackageDetails } from "components/molecules/order-components/packageDetailsCard";

type DraftFormProps = {
  type: SHIPPING_TYPE;
  onSave: fnCreateDrawProps;
  currentData: Partial<fullFormProp>;
  isLoading: boolean;
};

export const DraftFormNational = ({
  type,
  currentData,
  onSave,
  isLoading,
}: DraftFormProps) => {
  return (
    <>
      {currentData ? (
        <Grid container spacing={4} paddingX={0} paddingRight={3} marginTop={0}>
          <Grid item xs={12} md={7} paddingTop={0}>
            <Typography
              sx={{
                px: "24px",
                fontSize: "1rem!important",
                fontWeight: "bold",
              }}
            >
              Configura tu orden
            </Typography>
            <Stack spacing={1}>
              <CardOrderStatus current_data={currentData} fnSave={onSave} />

              <AddressInfoCard
                data={currentData!}
                fnSave={onSave}
                title="Origen"
                isShipper={true} 
              />

              <AddressInfoCard
                data={currentData!}
                fnSave={onSave}
                title="Destino"
                isShipper={false} 
              />

                <PackageDetails
                  fnSave={onSave}
                  shippingType={type}
                  data={currentData}
                />
              <DeclareExportNational data={currentData} fnSave={onSave} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography  sx={{
                px: "24px",
                fontSize: "1rem!important",
                fontWeight: "bold",
              }}>
              Escoge transportadora
            </Typography>
            <Stack spacing={2}>
              <CheapestOptions typeShipping={type} fnSave={onSave} data={currentData} />
              <Insurance data={currentData} fnSave={onSave} />
              <Total
                order={currentData}
                loading={isLoading}
                insure={
                  currentData.shipal?.insurance
                    ? currentData.shipal?.insurance_value
                    : 0
                }
                shipping={currentData.shipment_value}
                total={currentData.total_payment}
                dueDiligence={currentData.due_diligence}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
