import React from "react";
import { useMemo } from "react";
import { TableHeader } from "components/organisms/Table/Table.models";
import { OrderInfoTableCell } from "../orderInfoTableCell";
import { ConsigneeTableCell } from "../consigneeTableCell";
import { CarrierInfoTableCell } from "../carrierInfoTableCell";
import { PickupButton } from "../pickupButton";
import { Tracking } from "../tracking";
import { OrderTag } from "../orderTag";
import { ContactInfo } from "../contactInfo";

export const useHeaders = (
  handlePickup: any,
  pickupButtonStyles: any,
  generateLabelLink: any,
  handleTrackingUrl: any
) => {
  return useMemo(
    (): TableHeader[] => [
      {
        id: "invoice_number",
        renderComponent: (props) => <OrderInfoTableCell order={props} />,
        label: "Orden",
        width: "10%",
      },
      {
        id: "client_name",
        renderComponent: (props) => <ConsigneeTableCell order={props} />,
        label: "Destino",
        width: "25%",
      },
      {
        id: "contact_info",
        renderComponent: (props) => <ContactInfo order={props} />,
        label: "Contact Info",
      },
      {
        id: "shipments",
        renderComponent: (props) => <CarrierInfoTableCell order={props} />,
        label: "Servicio",
        width: "15%",
      },
      {
        id: "pickup",
        renderComponent: (props) => (
          <PickupButton
            order={props}
            handlePickup={handlePickup}
            pickupButtonStyles={pickupButtonStyles}
          />
        ),
        label: "Recolección",
      },
      {
        id: "tracking",
        renderComponent: (props) => (
          <Tracking order={props} handleTrackingUrl={handleTrackingUrl} />
        ),
        label: "Guia",
      },
      {
        id: "tag",
        renderComponent: (props) => (
          <OrderTag order={props} generateLabelLink={generateLabelLink} />
        ),
        label: "Etiqueta",
        align: "center",
      },
    ],
    [handlePickup, pickupButtonStyles, generateLabelLink, handleTrackingUrl]
  );
};
