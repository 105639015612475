export const typesShipment = {
  SET_SHIPPER_INFO: "SET_SHIPPER_INFO",
  SET_CONSIGNEE_INFO: "SET_CONSIGNEE_INFO",
  SET_TYPE_SHIPPING: "SET_TYPE_SHIPPING",
  loadingShipment: "[Shipment] loading",
  updateShipment: "[Shipment] update",
  updateShipmentStep1: "[Shipment] updateStep1",
  updateShipmentStep2: "[Shipment] updateStep2",
  updateShipmentStep3: "[Shipment] updateStep3",
  updateShipmentStep5: "[Shipment] updateStep5",
  resetShipment: "[Shipment] reset",
};
