import React from "react";
import { Col } from "react-bootstrap";
import { Form, Formik } from "formik";

import { Button } from "../../components/atoms/button";
import FormikInput from "../../components/molecules/formik-input";

import { recoverSchema } from "./validations";

export const SendRecoverEmail = ({ onSubmit }) => {
  const initialValues = {
    email: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={recoverSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikInput
            InputFullWidth
            type="email"
            label="Correo electrónico"
            name="email"
          />

          <Col xs="auto" className="pt-3 d-flex justify-content-center">
            <Button type="submit" purple disabled={isSubmitting} Regular>
              Restablecer
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
