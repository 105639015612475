import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { OrderStatus } from "services/serviceV2/orders/types";

type Props = {
  _id: string;
  consignee: {
    country_code: string;
    city: string;
    state: string;
    postal_code: string;
    email: string;
    phone_number: string;
  };
  client_name: string;
  due_diligence: {
    carrier_selected: boolean;
  };
  total_payment: number;
  shipal: {
    selected_carrier: string;
  };
  is_international_shippment: boolean;
  order_status: string;
  pick_up_info: {
    type: string;
    date: any;
  };
};

export const PickupButton = ({
  order,
  pickupButtonStyles,
  handlePickup,
}: {
  order: Props;
  pickupButtonStyles: Record<string, any>;
  handlePickup: (o: any) => {};
}) => {
  return (
    <>
      {order.order_status === OrderStatus.ACEPTED && (
        <Button
          variant="contained"
          sx={pickupButtonStyles}
          onClick={() => handlePickup(order._id)}
        >
          Programar
        </Button>
      )}

      {order.order_status === OrderStatus.SCHEDULED && (
        <Stack
          direction="column"
          alignItems="start"
          width={"100%"}
          height={"100%"}
        >
          {order.pick_up_info.type === "SELF_PICKUP" ? (
            <Typography>Entregar el paquete en una oficina de la transportadora.</Typography>
          ) : (
            <>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ minHeight: "70%" }}
              >
                <Typography variant="caption" fontWeight="bold">
                  Fecha estimada:
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ minHeight: "30%" }}
              >
                <Typography variant="caption">
                  {order.pick_up_info.date}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
};
