import React, { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { Props } from "../contactInfo/index";
import { currentFormat } from "utils/convert";
import LinkAction from "components/molecules/Link-action";
import { useHistory } from "react-router-dom";

export const ItemResume = ({ order }: Props) => {
  const history = useHistory();
  const handleSelectedOrder = useCallback(
    (isNational: boolean, id: string) =>
      history.push(
        `/shipping/${isNational ? "international" : "national"}/${id}`
      ),
    [history]
  );
  return (
    <>
      {order.contents && order.declared_value ? (
        <Stack direction="column" width={"100%"} height={"100%"}>
          <Stack direction="row" alignItems="center" sx={{ minHeight: "70%" }}>
            <Typography variant="caption" fontWeight="bold">
              {order.contents && order.contents}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ minHeight: "30%" }}>
            <Typography variant="caption">
              {order.declared_value &&
                `${currentFormat(order.declared_value)} Total`}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <LinkAction
          text="AGREGAR ARTICULOS"
          action={() =>
            handleSelectedOrder(order.is_international_shippment, order._id)
          }
        />
      )}
    </>
  );
};
