import React, { useEffect } from "react";
//REACT-BOOSTRAP
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StyledButton } from "./styles";
import { Dashboard } from "../../templates/page-private-dashboard";
import { getUserData } from "../../../store/auth/auth.selectors";
import { colors } from "../../../constant/branding";

export const PageConfirm = () => {
  const history = useHistory();
  const user = useSelector(getUserData);
  // const [brounce, setBrounce] = useState('')

  let broun =
    localStorage.getItem("ticX_To") !== null
      ? JSON.parse(localStorage.getItem("ticX_To"))
      : "";

  useEffect(() => {
    if (broun === null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      broun = JSON.parse(localStorage.getItem("ticX_To"));
      console.log(broun);
    }
  }, [broun]);

  // history.push({ pathname: "/user-wallet");
  return (
    <Dashboard PageName="Mi Billetera" className="px-5 pt-3">
      <div className="ml-1 mt-0">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: broun.estado === "Pendiente" ? "10%" : "-15%",
          }}
        >
          <div
            style={{
              margin: "4%",
              borderBottom: `thick solid ${colors.orange}`,
              width: "100%",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <h3>Información</h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "Column",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <br />
          <>
            <strong>Información:</strong> Gracias por confiar en SHIPAL, estamos
            verificando su pago. Si la operación fue exitosa el saldo puede
            tardar hasta 10 min en verse reflejado. Si tienes alguna pregunta
            por favor comunícate con soporte.
          </>
          <br />
          <StyledButton
            regular
            purple
            type="button"
            onClick={() =>
              window.open(
                ` https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}&text=Hola!%20%0A%F0%9F%A4%91%20%20Tengo%20dudas%20sobre%20mi%20transacci%C3%B3n%20%F0%9F%93%A6%0A%F0%9F%9A%9A%20%20att%3A%20${user.firstName}%0AId%20de%20usuario%3A%${user._id}`,
                "_blank"
              )
            }
          >
            Hablar con soporte por whatsapp
          </StyledButton>
          <StyledButton
            regular
            orange
            type="button"
            onClick={
              () =>
                history.push({
                  pathname: "/setting/mypayments",
                }) /* window.location.href = paymentResponse?.urlbanco */
            }
          >
            Mi billetera
          </StyledButton>
        </div>
      </div>
    </Dashboard>
  );
};

/* 
{broun.estado === 'Pendiente' && false &&
              (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-15%' }}>
                  <div style={{ alignItems: 'center', alignContent: 'center' }}>
                    <p>Actualmente tiene una transacion pendiente </p>
                  </div>
                </div>
              )
            }



            {false && <div style={{ display: 'flex', justifyContent: 'center', padding: '3%', marginLeft: '23%', marginTop: broun.estado === 'Pendiente' ? '10%' : '0' }}>
              <Row>
                <Col xs={6} >
                  <p># orden </p>
                </Col>

                <Col xs={6}>
                  <p>{broun.factura}</p>
                </Col>

                <Col xs={6}>
                  <p>Fecha de pago</p>
                </Col>

                <Col xs={6}>
                  <p>{broun.fecha}</p>
                </Col>

                <Col xs={6}>
                  <p>Correo </p>
                </Col>

                <Col xs={6}>
                  <p>{broun.email}</p>
                </Col>

                <Col xs={6}>
                  <p>Estado </p>
                </Col>

                <Col xs={6}>
                  <p>{broun.estado}</p>
                </Col>

                <Col xs={6}>
                  <p>Valor </p>
                </Col>

                <Col xs={6}>
                  <p>{broun.valor}</p>
                </Col>
              </Row>
            </div>}

            {broun.estado === 'Aceptada' && false &&
              (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <BtnConfirm
                    medium
                    purple
                    onClick={() => {
                      localStorage.removeItem('ticX_To')
                      return history.push("/")
                    }}
                  >
                    Continuar
                  </BtnConfirm>
                </div>
              )
            }
*/
