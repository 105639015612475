import iconFedex from "../../assets/imgs/logoFedex.png";
import iconDHL from "../../assets/imgs/iconDHL.png";
import iconUPS from "../../assets/imgs/iconUPS.png";
import imgPse from "../../assets/imgs/logoPse.png";
import imgPaypal from "../../assets/imgs/logoPaypal.png";

export const AduanasDocs = [
  {
    id: 1,
    description: "Paquete",
    quantity: 2,
    totalValue: 80.0,
    countryOrigin: "co",
  },
  {
    id: 2,
    description: "Paquete",
    quantity: 2,
    totalValue: 80.0,
    countryOrigin: "co",
  },
  {
    id: 3,
    description: "Paquete",
    quantity: 2,
    totalValue: 80.0,
    countryOrigin: "co",
  },
  {
    id: 4,
    description: "Paquete",
    quantity: 2,
    totalValue: 80.0,
    countryOrigin: "co",
  },
  {
    id: 5,
    description: "Paquete",
    quantity: 2,
    totalValue: 80.0,
    countryOrigin: "co",
  },
];

export const aduanasFields = [
  { label: "Descripción", id: "description" },
  { label: "Cantidad", id: "quantity" },
  { label: "Total de valor", id: "totalValue" },
  { label: "Original", id: "countryOrigin" },
  { label: "Opciones", id: "option" },
];

export const optionsTypesDocumento = [
  { text: "CC", value: "CC" },
  { text: "Tarjata de identidad", value: "identidad" },
];
export const options = [
  {
    value: "default",
    text: "Mi propio embalaje",
    dimensions: {
      Dlength: 0,
      Dwidth: 0,
      Dheigth: 0,
    },
  },
  {
    value: "Caja Fedex",
    text: "Caja Fedex",
    dimensions: {
      Dlength: 48.1,
      Dwidth: 40.4,
      Dheigth: 38.9,
    },
  },
  {
    value: "Caja DHL",
    text: "Caja DHL",
    dimensions: {
      Dlength: 2.4,
      Dwidth: 6.2,
      Dheigth: 8.8,
    },
  },
];

export const optionsDefaultPacking = [
  {
    value: "default",
    text: "Mi propio embalaje",
    dimensions: {
      Dlength: 0,
      Dwidth: 0,
      Dheigth: 0,
    },
  },
];

export const optionDefafaultSelect = [
  {
    value: "",
    text: "",
    data: {
      Name: "",
      Company: "",
      Email: "",
      Phone: "",
      Address: "",
      DetailAddress: "",
      City: "",
      State: "",
      PostalCode: "",
      Country: "",
    },
  },
];

export const transactionList = [
  {
    userID: "sadfasdfasf",
    billDate: Date.now(),
    billNumber: 1214651,
    paidDate: null,
    price: 1234,
  },
  {
    userID: "sadfasdfasf",
    billDate: Date.now(),
    billNumber: 1214651,
    paidDate: Date.now(),
    price: 1234,
  },
  {
    userID: "sadfasdfasf",
    billDate: Date.now(),
    billNumber: 1214651,
    paidDate: null,
    price: 1234,
  },
  {
    userID: "sadfasdfasf",
    billDate: Date.now(),
    billNumber: 1214651,
    paidDate: null,
    price: 1234,
  },
];

export const dataInfoTable = [
  {
    idDoc: 32,
    imgSrc: iconFedex,
    service: "International Prio...",
    rate: "$80.00",
    to: "Jackie Risher, Empresa xxxx Ordem #56879",
    created: "08/12/2021",
    statusMonitoring: "Local FedEx 8904586004032",
  },
  {
    idDoc: 72,
    imgSrc: iconFedex,
    service: "International Prio...",
    rate: "$80.00",
    to: "Jackie Risher, Empresa xxxx Ordem #56879",
    created: "08/12/2020",
    statusMonitoring: "Local FedEx 8904586004032",
  },
  {
    idDoc: 62,
    imgSrc: iconFedex,
    service: "International Prio...",
    rate: "$80.00",
    to: "Jackie Risher, Empresa xxxx Ordem #56879",
    created: "08/12/2022",
    statusMonitoring: "Local FedEx 8904586004032",
  },
  {
    idDoc: 42,
    imgSrc: iconFedex,
    service: "International Prio...",
    rate: "$80.00",
    to: "Jackie Risher, Empresa xxxx Ordem #56879",
    created: "08/12/2023",
    statusMonitoring: "Local FedEx 8904586004032",
  },
  {
    idDoc: 14,
    imgSrc: iconFedex,
    service: "International Prio...",
    rate: "$80.00",
    to: "Jackie Risher, Empresa xxxx Ordem #56879",
    created: "08/12/2020",
    statusMonitoring: "Local FedEx 8904586004032",
  },
];

export const dataInfoPlan = [
  {
    id: 1,
    title: "Mensualidad",
    description:
      "Escalables, optimizados para el alto rendimiento y muy faciles de administrar gracias a un amigable panel de control.",
    titleIdeal: "Ideal para:",
    descriptionIdeal:
      "Desarrolladores de Software, Administradores de Servidores, Apps Developers, Gammers.",
    titlePlan: "Planes desde:",
    priceMonth: "$17.800 COP/Mes",
    priceYear: "$195.500 COP/Año",
    payment: "*cobro recurrente mensual",
  },
  {
    id: 2,
    title: "Anual",
    description:
      "Escalables, optimizados para el alto rendimiento y muy faciles de administrar gracias a un amigable panel de control.",
    titleIdeal: "Ideal para:",
    descriptionIdeal:
      "Desarrolladores de Software, Administradores de Servidores, Apps Developers, Gammers.",
    titlePlan: "Planes desde:",
    priceMonth: "$17.800 COP/Mes",
    priceYear: "$195.500 COP/Año",
    payment: "*pago unico",
  },
];

export const dashboardCurrentList = {
  data: {
    idDoc: "967",
    perks: [
      "Accede a descuentos de hasta el 90%",
      "Programar la recogida de paquetes",
      "Crea manifiestos",
    ],
    services: [
      {
        name: "DHL",
        isActive: true,
        isEnabled: true,
        isNational: false,
        urlImg: iconDHL,
        service: "Envío",
        durationsDays: "15 días",
        precio: 0,
        idDoc: "246",
      },
      /* {
        name: "FEDEX",
        isActive: true,
        isEnabled: true,
        isNational: false,
        urlImg: iconFedex,
        service: "DHL Express documento lorem ipsum",
        durationsDays: "3 días",
        precio: 20000,
        idDoc: "747",
      },
      {
        name: "UPS",
        isActive: true,
        isEnabled: true,
        isNational: false,
        urlImg: iconUPS,
        service: "UPS Express documento lorem ipsum",
        durationsDays: "3 días",
        precio: 20000,
        idDoc: "438",
      },
      {
        name: "DHL",
        isActive: true,
        isEnabled: true,
        isNational: true,
        urlImg: iconDHL,
        service: "DHL Express documento lorem ipsum",
        durationsDays: "3 días",
        precio: 20000,
        idDoc: "235",
      }, */
    ],
  },
};

export const dataQuote = [
  {
    id: 1,
    imgSrc: iconDHL,
    price: "$ 60.000",
    before: "$80.000",
    discount: "20% DTC",
    days: "De dos a tres dias",
  },

  {
    id: 2,
    imgSrc: iconFedex,
    price: "$ 80.000",
    before: "$120.000",
    discount: "20% DTC",
    days: "De uno a cinco dias",
  },

  {
    id: 3,
    imgSrc: iconUPS,
    price: "$ 30.000",
    before: "$60.000",
    discount: "20% DTC",
    days: "De tres a cuatro dias",
  },
];

export const methods = [
  { src: imgPse, code: "pse", title: "Pagos PSE", name: "PSE" },
  { src: imgPaypal, code: "paypal", title: "Paypal", name: "Paypal" },
];

export const typePerson = [
  { value: "", label: "Seleccione..." },
  { value: "CC", label: "Persona Natural" },
  { value: "NIT", label: "Persona Juridica" },
];

export const banks = [
  {
    bankCode: "0",
    bankName: "A continuación seleccione su banco",
  },
  {
    bankCode: "1059",
    bankName: "BANCAMIA S.A.",
  },
  {
    bankCode: "1040",
    bankName: "BANCO AGRARIO",
  },
  {
    bankCode: "1052",
    bankName: "BANCO AV VILLAS",
  },
  {
    bankCode: "1013",
    bankName: "BANCO BBVA COLOMBIA S.A.",
  },
  {
    bankCode: "1032",
    bankName: "BANCO CAJA SOCIAL",
  },
  {
    bankCode: "1066",
    bankName: "BANCO COOPERATIVO COOPCENTRAL",
  },
  {
    bankCode: "1051",
    bankName: "BANCO DAVIVIENDA",
  },
  {
    bankCode: "1001",
    bankName: "BANCO DE BOGOTA",
  },
  {
    bankCode: "1023",
    bankName: "BANCO DE OCCIDENTE",
  },
  {
    bankCode: "1062",
    bankName: "BANCO FALABELLA ",
  },
  {
    bankCode: "1012",
    bankName: "BANCO GNB SUDAMERIS",
  },
  {
    bankCode: "1006",
    bankName: "BANCO ITAU",
  },
  {
    bankCode: "1060",
    bankName: "BANCO PICHINCHA S.A.",
  },
  {
    bankCode: "1002",
    bankName: "BANCO POPULAR",
  },
  {
    bankCode: "1058",
    bankName: "BANCO PROCREDIT",
  },
  {
    bankCode: "1065",
    bankName: "BANCO SANTANDER COLOMBIA",
  },
  {
    bankCode: "1069",
    bankName: "BANCO SERFINANZA",
  },
  {
    bankCode: "1007",
    bankName: "BANCOLOMBIA",
  },
  {
    bankCode: "1061",
    bankName: "BANCOOMEVA S.A.",
  },
  {
    bankCode: "1283",
    bankName: "CFA COOPERATIVA FINANCIERA",
  },
  {
    bankCode: "1009",
    bankName: "CITIBANK ",
  },
  {
    bankCode: "1292",
    bankName: "CONFIAR COOPERATIVA FINANCIERA",
  },
  {
    bankCode: "1551",
    bankName: "DAVIPLATA",
  },
  {
    bankCode: "1507",
    bankName: "NEQUI",
  },
  {
    bankCode: "1151",
    bankName: "RAPPIPAY",
  },
  {
    bankCode: "1019",
    bankName: "SCOTIABANK COLPATRIA",
  },
];

const statesUs = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

const statesCa = [
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Alberta",
    abbreviation: "AB",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "New Brunswick",
    abbreviation: "NB",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Yukon",
    abbreviation: "YT",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
];

export const countriesWithStates = [
  {
    countryCode: "US",
    states: statesUs,
  },
  {
    countryCode: "CA",
    states: statesCa,
  },
];
