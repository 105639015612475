import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
// import * as yup from "yup";
import { useSelector } from 'react-redux'
import { Col, ListGroup } from "react-bootstrap";
import { StyledButton } from './styles';
// Components
import FormikInput from "../../molecules/formik-input";
import { BasicCard } from '../../atoms/basic-card';
import { BasicCardListItem } from '../../atoms/basic-card-list-item'; 
// Redux API & ... 
import { getUserData } from '../../../store/auth/auth.selectors'
import { ApiService } from "../../../services";
import { getUserInfo } from '../../../store/auth/auth.actions';
import { Loading } from "../../atoms/loading";

export const FormProfile = () => {
  const user = useSelector(getUserData)
  const dispatch = useDispatch();
  const ValidSchema = Yup.object().shape({
    industryTarget2: Yup.string().when('industryTarget', {
      is: industryTarget => industryTarget === 'Otro',
      then: Yup.string().required("Campo obligatorio")
    })
  });
  if(!user) return <Loading />;
  return (
    <>
      <Formik 
          initialValues={{...user}}
          onSubmit={async (v) => {
            // console.log(v)
              try {
                const res = await ApiService.AuthService.updateMe({...v, industryTarget: v.industryTarget === 'Otro' ? v.industryTarget2 : v.industryTarget});
                if (res.status === 200){
                  dispatch(getUserInfo());
                }
             } catch ( err) {
                console.log(err);
             }
          }}
          validationSchema={ValidSchema}
        >
          {({ handleSubmit, values, setValues }) => (
            <form noValidate onSubmit={handleSubmit}>
              <br />
              {console.log(values)}
              <Col xs="12" md="12">
                <BasicCard title="Mi Perfil" purple height='auto' width='auto'>
                  <ListGroup className="list-group-flush">
                    <BasicCardListItem>
                        <FormikInput label="Nombre" name='firstName' />
                        <FormikInput label="Apellido" name='lastName' />
                        <FormikInput label="Correo Electronico" name="email"/> 
                        <FormikInput label="Telefono" name="phone"/> 
                        <FormikInput label="Ciudad" name="city"/> 
                        <FormikInput label="País" name="country"/> 
                        <FormikInput label="Nombre Empresa" name="enterpriseName"/> 
                        <FormikInput as="select" InputFullWidth label="Tipo de documento" name='documentType'
                              onChange ={ e => {
                                const id = e.target.value
                                setValues({...values, documentType: id})
                              }}
                              >
                                <option value="documentType">Escoge uno...</option>
                                <option value="Nit">Nit</option>
                                <option value="CC">CC</option>
                        </FormikInput>
                        <FormikInput label="Documento" name="document"/>    
                        <FormikInput as="select" InputFullWidth label="¿En qué industria esta tu empresa?" name='industryTarget'
                              onChange ={ e => {
                                const id = e.target.value
                                setValues({...values, industryTarget: id})
                              }}
                              >
                                <option value="industryTarget">Escoge uno...</option>
                                <option value="Moda">Moda</option>
                                <option value="Salud y Belleza">Salud y Belleza</option>
                                <option value="Decoración">Decoración</option>
                                <option value="Arteasnías">Arteasnías</option>
                                <option value="Alimentos">Alimentos</option>
                                <option value="Otro">Otro</option>
                        </FormikInput> 
                        {
                        values.industryTarget === 'Otro' && (
                            <FormikInput
                              InputFullWidth
                              label="Especifique que otro mercado."
                              name="industryTarget2" 
                            />
                          )
                        } 
                        <FormikInput as="select" InputFullWidth label="Número de empleados" name='employeesNumber'
                              onChange ={ e => {
                                const id = e.target.value
                                setValues({...values, employeesNumber: id})
                              }}
                              >
                                <option value="employeesNumber">Escoge uno...</option>
                                <option value="0-10 Empleados">0-10 Empleados</option>
                                <option value="11-50 Empleados">11-50 Empleados</option>
                                <option value="51-99 Empleados">51-99 Empleados</option>
                                <option value="Más de 100 empleados">Más de 100 empleados</option>
                        </FormikInput>
                      </BasicCardListItem>
                  </ListGroup>
                  <StyledButton regular purple type='submit'>Actualizar información</StyledButton>
                </BasicCard>
              </Col>
            </form>
            )}
        </Formik>
    </>
  );
};
