import styled from "styled-components";

export const StyledTitle2 = styled.p`
  font-family: Roboto-Bold;
`;

export const StyledSubtitle = styled.p`
  font-family: Roboto-Regular;
  color: #474747;
`;

export const StyledDivider = styled.hr`
  border: 1px solid #eaedef;
`;

export const STyledBorder = styled.div`
  border: 1px solid #e2e6e9;
  border-radius: 5px;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const StyledTableWrapper = styled.div`
padding: 5px;
overflow: scroll;
width: 100%;
.table {
  overflow: scroll;
}
.table-responsive{
  overflow-x: auto;
  width: 100%;
}
.table th {
  border: none;
}
.table tr {
  height: 80px;
}
.table th {
  /* border-top: 1px solid #dee2e6; */
  vertical-align: middle;
}
.table td {
  /* border-top: 1px solid #dee2e6; */
  vertical-align: middle;
}
`;