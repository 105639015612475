import { useEffect } from 'react';
import { Provider } from "react-redux";
import styled from "styled-components";
import { colors } from "./constant/branding";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from "./routes";
import { AppStore, history, persistor } from "./store";
import { applyInterceptors } from "./utils/interceptors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ThemeProvider from "./theme";
const { REACT_APP_AWS_BRANCH } =  process.env;

applyInterceptors(AppStore, REACT_APP_AWS_BRANCH);
const queryClient = new QueryClient();

export const App = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "es",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    console.log("IS TRANSLATING!");
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ThemeProvider>
      <Provider store={AppStore}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <AppRoutes />
            <StyledContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </QueryClientProvider>
)};

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast--success {
    background-color: ${colors.purpule};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
