import styled from 'styled-components';
import { Col, Row } from "react-bootstrap";

export const MainRow = styled(Row)`
max-width: 100vw;
overflow: hidden;
flex-wrap: no-wrap;
height: 100vh;
.pointer{
    cursor: pointer;
}
.gap-3{
    gap: 0.5rem;
}
`
export const DisabledDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: inherit;
width: inherit;
`;

export const MainContent = styled.div`
display: flex;
width:  100%;
`


export const MainPhoneHeader = styled.div`
display: flex;
justify-content: flex-end;
    ${props => props.side && `
    padding: 4px;
    background-color: rgb(248, 248, 248);
    `}
    @media (min-width: 600px) {
        /* styles for browsers larger than 960px; */
        display: none;
    }
`;

export const MainPhoneSider = styled.div`
    visibility: 0;
    z-index: 1;
    
    @media (max-width: 600px) {
        ${props => !props.open && `
            display: none;
        `}
        position: absolute;
    }
`;

export const MainPhoneButton = styled.button`
    border: 0px;
    background: transparent;
`
export const ColSidebar = styled(Col)`
    flex-basis: 0;
    flex-grow: 0.2;
    max-width: 100%;
    height: 100vh;
    padding-left: 0px;
    ${props => !props.open && `
    flex-grow: 0;
    `}
    ${props => props.side && `
        flex-grow: 0;
        @media (max-width: 600px) {
            position: absolute;
            max-height: 100vh;
            overflow: scroll;
        }
    `}
`;

export const ColContent = styled(Col)`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin: inherit;
    overflow: scroll;
    max-width: 100%;
    margin: inherit;
    height: 100vh;
    overflow: scroll;
    padding: 26px;
}
`;