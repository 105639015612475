import { InputText } from "commons/input-text";
import type { TextFieldProps } from "@mui/material";
import React from "react";

type InputSmallProps = TextFieldProps & {
  name: string;
  label: string;
  width?: string;
  disabled?: boolean;
  type?: string;
  value?: any;
  error?: boolean;
  helperText?: string;
};

export const InputSmallForm = ({
  name,
  label,
  width = "50%",
  disabled = false,
  type,
  value,
  error,
  helperText
}: InputSmallProps) => {
  return (
    <InputText
      name={name}
      size="small"
      label={label}
      required
      value={value}
      variant="outlined"
      sx={{ width: { width } }}
      disabled={disabled}
      error={error}
      helperText={helperText}
      type={type}
    />
  );
};
