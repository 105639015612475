import React from "react";
import { BasicCard } from '../../atoms/basic-card';
import { useSelector } from 'react-redux'
import { getUserData } from '../../../store/auth/auth.selectors';
import { currentFormat } from "utils/convert";

import {
  CardBodyStyled,
  CardStyled,
} from "./styles";

export const ResumeAbono = ({ balanceAbonar, balanceAbonarPaypal, paypalFee, selectedMethod }) => {
  const user = useSelector(getUserData);
  const balanceAvailable = (user?.balanceAvailable || 0).toString();
  const newBalance = parseInt(balanceAvailable.replace(".", "")) + parseInt(balanceAbonar.replace(".", ""));
  
  return (
    <BasicCard title="Resumen de tu abono" height='auto' width='auto'>
      <CardStyled>
        <CardBodyStyled>
          <div className="containerText">
            <p>Saldo actual</p>
            <p>{`${currentFormat(balanceAvailable)}`}</p>
          </div>
          <div className="containerText">
            <p>Saldo de abono</p>
            <p>{`${currentFormat(balanceAbonar)}`}</p>
          </div>
          <hr />
          <div className="containerText">
            <p>Saldo Total:</p>
            <p>{`${currentFormat(newBalance)}`}</p>
          </div>
          {selectedMethod && selectedMethod?.code === 'paypal'  && (
            <BasicCard title="Resumen de tu transacci&oacute;n en PayPal" purple={true} cardformat={false} height='auto' width='auto'>
              <CardStyled>
                <CardBodyStyled>
                  <div className="containerText">
                    <p>Saldo de abono en dolares</p>
                    <p>{`${currentFormat(balanceAbonarPaypal.balanaceUsd)} USD`}</p>
                  </div>
                  <div className="containerText">
                    <p>Recargo paypal ({`${currentFormat(balanceAbonarPaypal.fee)}%`})</p>
                    <p>{`${currentFormat(balanceAbonarPaypal.balanaceFee)} USD`}</p>
                  </div>
                  <div className="containerText">
                    <p><b>Total de tu transacci&oacute;n en paypal</b></p>
                    <p><b>{`${currentFormat(balanceAbonarPaypal.total)} USD`}</b></p>
                  </div>
                </CardBodyStyled>
              </CardStyled>
            </BasicCard>
          )}
        </CardBodyStyled>
      </CardStyled>
    </BasicCard>
  );
};
