import React from "react";
import { StyledButton } from "./styles";

export const Button = ({ children, ...props }) => {
  return (
    <StyledButton data-testid="atomic-button-test" className="btn" {...props}>
      {children}
    </StyledButton>
  );
};
