import React from "react";
import { Modal } from "react-bootstrap";

export const LoginMessage = ({ message, handleMessage }) => {
  return (
    <Modal show={message} onHide={handleMessage}>
      <Modal.Header closeButton>Mensaje</Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
    </Modal>
  );
};
