export const createEncryptedKey = async () => {
  const { subtle } = window.crypto;
  const base64Key = btoa(process.env.REACT_APP_SECRET_KEY);
  const encodedKey = new TextEncoder().encode(base64Key);
  const buffer = await subtle.digest("SHA-1", encodedKey);
  console.log(bufferToString(buffer))
};

const bufferToString = (buffer) => {
  let string = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.length; i++) {
    let byte = bytes[i].toString(16);
    byte = byte.length < 2 ? "0" + byte : byte;
    string += byte;
  }

  return string;
};


