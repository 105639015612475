import React, { useState, useMemo, useCallback, MouseEvent } from "react";
import { 
  TableContainer,
  ButtonGroup,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Pagination,
  Button,
  Menu,
  MenuItem,
  Fade,
  Chip,
  Typography
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PeerPage } from "commons/peer-page";
import { usePagination } from "hooks/v2/useGenerycalPagination";
import { TabSourceProps, DataMapping, AddressDTO } from '../../types';

  export const TabPanel = (props: TabSourceProps) => {
    const [ _, actionPagination ] = usePagination();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const { data, index, value, tabSource: { dataMapping }, pagination: { limit, page, totalPages }, actions } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ currentId, setCurrentId ] = useState("");
    const open = Boolean(anchorEl);
    const handleClickActions  = useCallback(
      (id: string, event: MouseEvent<HTMLButtonElement>) => {
        console.log(id)
        setCurrentId(() => id);
        setAnchorEl(event.currentTarget);
      },
      [currentId]
    );

    const handleCloseActions = () => {
      setAnchorEl(() => null);
    };
  
    const handleAction = useCallback(
       async (orId: string, actionHandle: any) => {
        await actionHandle(orId);
        await handleCloseActions();
    },
    []
  );

    const imgStyles: React.CSSProperties = useMemo(
      () => ({
        width: "32px",
        objectFit: "contain",
      }),
      []
    );
    console.log("dataMapping----");
    console.log(dataMapping)

    const handlePagination = (type: string) => (event: any, value: any) => actionPagination(type, type === "page" ? value.toString() : event.target.value);

    return (
      <>
        <Stack
        alignItems="center"
        gap={2}
        sx={{
          mt: 3,
          border: "1px solid #F0F2F5",
          pb: 2,
        }}
        role="tabpanel"
        hidden={value !== index}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ borderBottom: "1px solid #F0F2F5" }}>
                {
                  Object.keys(dataMapping).map(currentKey => (<TableCell 
                    sx={{
                      backgroundColor: "white",
                    }}
                     key={dataMapping[currentKey as keyof DataMapping]?.toString()}>{dataMapping[currentKey as keyof DataMapping]}</TableCell>))
                }
                <TableCell 
                  sx={{
                    backgroundColor: "white",
                  }}
                  key={'Actions'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && 
                data.map((row, key: number) => (
                <TableRow
                  key={key}
                  sx={{ borderBottom: "1px solid #F0F2F5" }}
                >
                {
                  Object.keys(dataMapping).map(currentKey =>{
                    if(currentKey === 'country_name') {
                      return <TableCell key={'country_name'}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <img
                                alt="Bandera de pais"
                                style={imgStyles}
                                src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${row.country_code}.svg`}
                              />
                              {row[currentKey as keyof AddressDTO]}

                          
                        </Stack>
                       
                        </TableCell>
                        
                    }
                    if (currentKey === 'is_default_address') { 
                      return (<TableCell key={currentKey} >
                        {(row[currentKey as keyof AddressDTO] === true) && 
                          <Chip label="Principal" color="success" variant="filled" size="small" />
                        }
                        </TableCell>)
                      }

                      if (currentKey === 'company_name') { 
                        return (<TableCell key={currentKey} >
                          <Typography variant="body2">
                           <b>
                           {`${row[currentKey as keyof AddressDTO]}  `}
                           </b>
                          </Typography>
                        </TableCell>)
                        }
                      
                    return  (currentKey !== 'is_default_address'?
                    <TableCell key={currentKey} >
                      <Typography variant="body2">
                      {`${row[currentKey as keyof AddressDTO]}  `}
                      </Typography>
                    </TableCell>: '')
                  })
                }
                 {!!actions && (
                    <TableCell key={'actions'} align="right">
                       <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
                        <Button
                          id="fade-button"
                          aria-controls={open ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          key={row._id}
                          aria-expanded={open ? "true" : undefined}
                          onClick={() => handleAction(row._id, actions[0]?.handle)}
                        >
                          Editar
                        </Button>
                        <Button
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={event => handleClickActions(row._id, event)}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      

                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseActions}
                        TransitionComponent={Fade}
                      >
                          <MenuItem
                            key={`${row._id}-${actions[1].type}`}
                            onClick={() => handleAction(currentId, actions[1]?.handle)}
                          >
                            {actions[1].title}
                          </MenuItem>
                      </Menu>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack flexDirection="row" justifyContent="space-between" width="100%" px={3}>
          <PeerPage
            size={limit.toString()}
            onChange={handlePagination("limit")}
          />
          <Pagination
            count={totalPages ?? 1}
            page={page}
            onChange={handlePagination("page")}
            variant="outlined"
            sx={{
              '& .Mui-selected': {
                color: "#000 !important",
                fontWeight: "bold !important",
                borderColor: "#fff !important",
                backgroundColor: "rgba(145, 158, 171, 0.16) !important",
              }
            }}
          />
        </Stack>
      </Stack>
      </>
    );
};
