import React, { useEffect, useState, useMemo } from "react";
import { Card, Typography, MenuItem } from "@mui/material";
import { CountriesStatesSelector } from "components/molecules/countries-states-selector";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { getCountries, getCities } from "services/serviceV2/orders";
import { ADDRESS_TYPE } from 'enums/address';
import type { ConsigneeProps, ShipperProps, fullFormProp } from "components/molecules/order-forms/types";
import { InputSelect } from "commons/input-select";
import { SHIPPING_TYPE } from "enums/shipping";

type Props = {
  handleChange: (
    data: any,
    attribute: string,
    values: ShipperProps | ConsigneeProps
  ) => Promise<any>;
  title: string;
  warningInput: string;
  currentFormData: Partial<fullFormProp>;
  icon: string;
  typeShipping: SHIPPING_TYPE;
  isShipper: boolean;
  initialValues: Partial<ShipperProps | ConsigneeProps>;
};

export const EmptyQuoteAddressConfigCard = ({
  title,
  warningInput,
  icon,
  typeShipping,
  isShipper,
  handleChange,
  initialValues,
  currentFormData
}: Props) => {
const [ typeShippingState, setTypeShippingState ] = useState<SHIPPING_TYPE>(typeShipping);
const { data: cities } = useQuery({
  queryKey: ["cities"],
  queryFn: getCities,
});

const { data: countries } = useQuery({
  queryKey: ["countries"],
  queryFn: getCountries,
});

const onCityChangeSelect = async(_target: any, index: any) => {
  if (cities) {
    await handleChange(
      currentFormData,
      isShipper? 'shipper': 'consignee',
      {
        ...initialValues,
        dane_code: cities[index.key.slice(2)].daneCode,
        city: cities[index.key.slice(2)].cityName,
        postal_code: cities[index.key.slice(2)].postalCode,
      } as ShipperProps | ConsigneeProps
    )
  }
}

const onChangeSelectByCountry = async(_target: any, index: any) => {

  if(countries) {
    await handleChange(
      currentFormData,
      isShipper? 'shipper': 'consignee',
      {
        country_name: countries[Number(index.key.slice(2))].countryName, 
        country_code: countries[Number(index.key.slice(2))].countryCode,
        address_type: isShipper? 'SHIPPER': 'CONSIGNEE',
      } as ShipperProps | ConsigneeProps
    )
  }
};

const onChangeSelectByState = async(data: any) => {
    await handleChange(
      currentFormData,
      isShipper? 'shipper': 'consignee',
      data as ShipperProps | ConsigneeProps
    )
};


useEffect(() => {
  setTypeShippingState(() => typeShipping);
  }, [typeShippingState, typeShipping, setTypeShippingState, initialValues]
);

const textFiledStyle = useMemo(
  () => ({
    fontSize: "12px!important",
    color: "#677787",
  }),
  []
);

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
         <Box
          sx={{
            width: "5%",
            mr: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
            <img src={icon} alt="icono de campos pendientes por llenar" />
        </Box>
        <Box
          sx={{
            width: "32%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "left"
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "left" }}> {title}</Typography>

            <Typography
              sx={{
                ...textFiledStyle,
                textAlign: "left"
              }}
            >
              {warningInput}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "65%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          
        <Box
          sx={{
            width: `${initialValues.country_name === 'Colombia'? '60%': '80%'}`,
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
        {initialValues.country_code &&
                <CountriesStatesSelector
                id={'test'}
                isForQuote={true}
                valuesforQuote={initialValues}
                isInternationalShippment={currentFormData?.is_international_shippment}
                addressType={
                  isShipper || typeShippingState === SHIPPING_TYPE.NATIONAL
                  ? ADDRESS_TYPE.SHIPPER: ADDRESS_TYPE.CONSIGNEE}
                onChangeSelectByCountry={onChangeSelectByCountry}
                onChangeSelectByState={onChangeSelectByState}
              />
        }
        </Box>
        { typeShippingState  === SHIPPING_TYPE.NATIONAL && initialValues.country_name === 'Colombia' &&
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "end",
            alignItems: "center",
          }}
        >
          
          <InputSelect
            name={`${isShipper? 'shipper': 'consignee'}.city`}
            size="small"
            id={`${isShipper? 'shipper': 'consignee'}.city`}
            label="Ciudad"
            sx={{ height: "100%", ml: 4, width: '90%' }}
            InputProps={{ style: { height: "100%" } }}
            InputLabelProps={{ shrink: true }}
            value={initialValues.city}
            onChange={onCityChangeSelect as any}
          >
            {cities?.map((city, index) => (
              <MenuItem key={index} value={city.cityName}>
                {city.cityName}
              </MenuItem>
            ))}
          </InputSelect>
        </Box>
        }
        
        {((typeShippingState  === SHIPPING_TYPE.INTERNATIONAL) && (isShipper && initialValues.country_name === 'Colombia')) &&
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "end",
              alignItems: "center",
            }}
        >
          
          <InputSelect
            name={`${isShipper? 'shipper': 'consignee'}.city`}
            size="small"
            id={`${isShipper? 'shipper': 'consignee'}.city`}
            label="Ciudad"
            sx={{ height: "100%", ml: 4, width: '90%' }}
            InputProps={{ style: { height: "100%" } }}
            InputLabelProps={{ shrink: true }}
            value={initialValues.city}
            onChange={onCityChangeSelect as any}
          >
            {cities?.map((city, index) => (
              <MenuItem key={index} value={city.cityName}>
                {city.cityName}
              </MenuItem>
            ))}
          </InputSelect>
          
        </Box>
        }

        
        </Box>
        
      </Box>
      {((typeShippingState  === SHIPPING_TYPE.INTERNATIONAL) && (!isShipper && initialValues.country_name === 'Colombia')) &&
        <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}>
       
            <Typography sx={{
             
              fontSize: "0.8rem!important",
              color: "red",
              fontweight: "500",
              my: 2,
              ml: 2,
              mr: 2,
            }}>
            Los destinos internacionales deben ser diferentes a Colombia, para realizar una cotización a cualquier destino en Colombia, por favor cambie el tipo de cotización a "Nacional".
          </Typography>  
        </Box>
}
    </Card>
  );
};
