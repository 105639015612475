import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { ListGroup, Row, Col, Table } from "react-bootstrap";

import { BasicCard } from "../../atoms/basic-card";
import { BasicCardListItem } from "../../atoms/basic-card-list-item";
import FormikInput from "../../molecules/formik-input";

import { boxTypes } from "../../../constant/shippingConstants";
import { ApiService } from "../../../services";

import { StyledDiv, StyledButton } from "./styles";
import { SelectField } from "../../molecules/SelectField";
import { Button } from "../../atoms/button";
import { shippmentSchema } from "./formikValidation";

export const ShippingCreationStep1 = ({
  handleSubmit,
  isNational,
  setIsNational,
}) => {
  const [consigneeCities, setConsigneeCities] = useState([]);
  const [countriesConsignee, setCountriesConsignee] = useState([]);

  const fetch = async () => {
    try {
      const cities = await ApiService.AuthService.getCities();
      const listCountries = await ApiService.AuthService.getCountries();

      const sorted = listCountries.sort((a, b) =>
        a.countryName.localeCompare(b.countryName)
      );

      const excluded = () =>
        sorted.filter((country) => country.countryName !== "Colombia");

      setCountriesConsignee(isNational ? sorted : excluded());
      setConsigneeCities(cities);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const pesoVol = (Weight, Width, Height, Depth) => {
    const pesoVol = (Width * Height * Depth) / 5000;
    const pesoVolCeil = Math.ceil(pesoVol * 2) / 2;
    const weisghtCeil = Math.ceil(Weight * 2) / 2;
    const heigestWeight = Math.max(weisghtCeil, pesoVolCeil).toFixed(1);
    return `${heigestWeight} Kg`;
  };

  const pesoVolOnly = (Width, Height, Depth) => {
    const pesoVol = (Width * Height * Depth) / 5000;
    const pesoVolCeil = Math.ceil(pesoVol * 2) / 2;
    return `${pesoVolCeil} Kg`;
  };

  const parsedBoxes = () =>
    boxTypes.map((box, index) => ({
      label: box.PieceContents,
      value: index,
    }));

  return (
    <StyledDiv>
      <h2>{`Regalanos esta información básica para tu cotización de envío ${
        isNational ? "Nacional." : "Internacional."
      }`}</h2>
      <p>
        Pueden haber variaciones en precio por el dolar, y la veracidad de la
        información que se incluye, también puede generar demoras en los envíos.
      </p>
      <Row>
        <Col>
          <Button
            purple
            outline={!isNational}
            type="button"
            className="m-2 p-2"
            onClick={() => setIsNational(true)}
          >
            Nacional
          </Button>
          <Button
            purple
            outline={isNational}
            type="button"
            className="m-2 p-2"
            onClick={() => setIsNational(false)}
          >
            Internacional
          </Button>
        </Col>
      </Row>

      <hr className="border border-1 opacity-70" />

      <Formik
        initialValues={{
          national: isNational,
          consignee: {
            countryCode: isNational ? "CO" : "",
            postalCode: "",
          },
          shipper: {
            countryCode: "CO",
            postalCode: "",
          },
          declaredValue: 0,
          pieces: [],
        }}
        onSubmit={handleSubmit}
        validationSchema={shippmentSchema(isNational)}
        enableReinitialize
      >
        {({
          values,
          handleSubmit,
          setValues,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form>
            <Row>
              <Col xs="12" md="6">
                <BasicCard
                  cardformat={false}
                  title="Remitente"
                  purple
                  height="auto"
                  width="auto"
                >
                  <ListGroup className="list-group-flush">
                    <FormikInput
                      className="innerFormikInputDiv"
                      InputFullWidth
                      as="select"
                      label="Ciudad de Remitente"
                      name="shipper.city"
                      onChange={(e) => {
                        const id = e.target.value;
                        const value = consigneeCities[id];
                        setFieldValue("shipper.postalCode", value.postalCode);
                      }}
                    >
                      <option value="">
                        {values?.shipper?.ColombianZone || "Escoge una ciudad"}
                      </option>
                      {consigneeCities.map((directory, index) => (
                        <option
                          className="dropdownOption"
                          key={index}
                          value={index}
                        >
                          {directory.cityName}
                        </option>
                      ))}
                    </FormikInput>
                    <ErrorMessage name="shipper.postalCode">
                      {(message) => (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {message}
                        </p>
                      )}
                    </ErrorMessage>
                  </ListGroup>
                </BasicCard>
              </Col>

              <Col xs="12" md="6">
                <BasicCard
                  cardformat={false}
                  title="Receptor"
                  height="auto"
                  width="auto"
                >
                  <ListGroup className="list-group-flush">
                    {isNational && (
                      <>
                        <FormikInput
                          className="innerFormikInputDiv"
                          InputFullWidth
                          as="select"
                          label="Ciudad de destinatario"
                          name="consignee.city"
                          onChange={(e) => {
                            const id = e.target.value;
                            const value = consigneeCities[id];
                            setFieldValue(
                              "consignee.postalCode",
                              value.postalCode
                            );
                          }}
                        >
                          <option value="">Escoge una ciudad</option>

                          {consigneeCities.map((directory, index) => (
                            <option
                              className="dropdownOption"
                              key={index}
                              value={index}
                            >
                              {directory.cityName}
                            </option>
                          ))}
                        </FormikInput>
                        <ErrorMessage name="consignee.postalCode">
                          {(message) => (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {message}
                            </p>
                          )}
                        </ErrorMessage>
                      </>
                    )}

                    {!isNational && (
                      <>
                        <FormikInput
                          as="select"
                          InputFullWidth
                          label="País de Destino"
                          name="consignee.countryName"
                          onChange={(e) => {
                            const id = e.target.value;
                            const value = countriesConsignee[id];

                            setFieldValue(
                              "consignee.countryCode",
                              value.countryCode
                            );
                          }}
                        >
                          <option value="default">Escoge un país</option>

                          {countriesConsignee.map((directory, index) => (
                            <option key={index} value={index}>
                              {directory.countryName}
                            </option>
                          ))}
                        </FormikInput>
                        <ErrorMessage name="consignee.countryCode">
                          {(message) => (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {message}
                            </p>
                          )}
                        </ErrorMessage>
                      </>
                    )}
                  </ListGroup>
                </BasicCard>
              </Col>
            </Row>
            <ListGroup className="list-group-flush">
              <BasicCardListItem>
                <FieldArray name="pieces">
                  {({ remove, push }) => (
                    <div style={{ width: "100%" }}>
                      <SelectField
                        label="Tipo de embalaje:"
                        name="packageList"
                        onChange={(e) => {
                          const id = e.target.value;
                          const boxValues = boxTypes[id];
                          setValues({
                            ...values,
                            height: parseFloat(boxValues.Height),
                            depth: parseFloat(boxValues.Depth),
                            width: parseFloat(boxValues.Width),
                            PackageType: boxValues.PackageType,
                            PackageIndex: id,
                          });
                        }}
                        options={parsedBoxes()}
                        defaultText="Seleccione una opcion"
                      />

                      <Row
                        style={{
                          paddingRight: "15px",
                          paddingLeft: "15px",
                        }}
                      >
                        <FormikInput
                          disabled={
                            !values.pieces.PackageType ||
                            values.pieces.PackageType < 1
                              ? false
                              : true
                          }
                          InputThreeWidth
                          label="Alto (cm)"
                          placeholder="Alto cm"
                          type="number"
                          name="height"
                        />
                        <p style={{ margin: "8% auto 0" }}>x</p>
                        <FormikInput
                          disabled={
                            !values.pieces.PackageType ||
                            values.pieces.PackageType < 1
                              ? false
                              : true
                          }
                          InputThreeWidth
                          label="Largo (cm)"
                          placeholder="Largo cm"
                          type="number"
                          name="depth"
                        />
                        <p style={{ margin: "8% auto 0" }}>x</p>
                        <FormikInput
                          disabled={
                            !values.pieces.PackageType ||
                            values.pieces.PackageType < 1
                              ? false
                              : true
                          }
                          InputThreeWidth
                          label="Acho (cm)"
                          placeholder="Acho cm"
                          type="number"
                          name="width"
                        />
                        <FormikInput
                          label="Peso kg"
                          placeholder="Peso Kg"
                          name="weight"
                          type="number"
                        />
                      </Row>
                      <StyledButton
                        style={{
                          marginLeft: "15px",
                        }}
                        type="button"
                        className="secondary"
                        onClick={() => {
                          if (
                            !errors?.weight &&
                            !errors?.width &&
                            !errors?.height &&
                            !errors?.depth
                          )
                            push({
                              pieceID: values.pieces.length + 1,
                              PackageType:
                                boxTypes[values.PackageIndex || 0]
                                  .PackageType || "OD",
                              weight: values.weight || 0,
                              width: values.width || 0,
                              height: values.height || 0,
                              depth: values.depth || 0,
                            });
                        }}
                      >
                        Agregar paquete
                      </StyledButton>
                      {touched.pieces && errors.pieces && (
                        <p
                          style={{
                            color: "rgb(220, 53, 69)",
                            fontSize: "10px",
                          }}
                        >
                          {errors.pieces}
                        </p>
                      )}
                      <br />
                      <br />
                      <h5
                        style={{
                          marginLeft: "15px",
                        }}
                      >
                        {values.pieces.length < 1
                          ? "Resumen de embalajes: No tienes embalajes."
                          : "Resumen de embalajes:"}
                      </h5>
                      <div>
                        {values.pieces.length > 0 && (
                          <Table responsive="sm">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Peso Real</th>
                                <th>Peso Volumétrico</th>
                                <th>Peso a Cobrar</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.pieces
                                .sort((a, b) => {
                                  return b.PieceID - a.PieceID;
                                })
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {item?.PieceContents || "Propio Embalaje"}
                                    </td>
                                    <td>{item.weight} Kg</td>
                                    <td>
                                      {pesoVolOnly(
                                        item.width,
                                        item.height,
                                        item.depth
                                      )}
                                    </td>
                                    <td>
                                      {pesoVol(
                                        item.weight,
                                        item.width,
                                        item.height,
                                        item.depth
                                      )}
                                    </td>
                                    <td>
                                      <StyledButton
                                        marginTop
                                        variant="primary"
                                        onClick={() => remove(index)}
                                        type="button"
                                      >
                                        Eliminar embalaje
                                      </StyledButton>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>
              </BasicCardListItem>
            </ListGroup>
            <FormikInput
              InputFullWidth
              type="number"
              label={`Valor declarado en (${isNational ? "COP" : "USD"})`}
              id="declaredValue"
              name="declaredValue"
              placeholder="..."
            />
            <StyledButton onClick={handleSubmit}>Cotizar</StyledButton>
          </Form>
        )}
      </Formik>
    </StyledDiv>
  );
};
