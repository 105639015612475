import { types } from "../../types";
const initialState = {
  stores: [],
  loading: false,
};

export const storeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.storesConnect:
      const f = state.stores.every((elm) => elm.id === payload.id);
      if (f) {
        return {
          ...state,
          stores: [...state.stores, { ...payload }],
        };
      }
      break;
    case types.disConnectWoocommerce:
      return {
        ...state,
        stores: state.stores.filter((elm) => elm.id !== payload),
      };

    case "storeSetIsLoading":
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
