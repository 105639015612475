import React from 'react';
import { Card } from "react-bootstrap";
import { StyledCard } from './styles';
import { colors } from '../../../constant/branding';

export const BasicCard = ({ title, children, purple = false, height = '187px', width = '350px', cardformat = true }) => {
    return (
        <StyledCard cardformat={cardformat} className="mb-3 mx-auto" style={{ height, width }}>
            <Card.Body>
              <Card.Title style={{ color: purple ? colors.purpule : colors.orange }}>
                <strong>{title}</strong>
              </Card.Title>
              {children }
            </Card.Body>
        </StyledCard>
    )
};

// border: 1px solid rgba(0, 0, 0, 0.125);// 

