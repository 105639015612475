import React from "react";
import { Stack, Typography } from "@mui/material";

export type Props = {
  order: {
    _id: string;
    consignee: {
      country_code: string;
      city: string;
      state: string;
      postal_code: string;
      company_name: string;
      person_name: string;
      email: string;
      phone_number: string;
    };
    client_name: string;
    due_diligence: {
      carrier_selected: boolean;
      consignee_completed: boolean;
    };
    total_payment: number;
    shipal: {
      selected_carrier: string;
    };
    is_international_shippment: boolean;
    declared_value: number;
    contents: string;
  };
  can_modify?: boolean;
};

export const ContactInfo = ({ order }: Props) => {
  return (
    <Stack direction="column" width={"100%"} height={"100%"}>
      <Stack direction="row" alignItems="center" sx={{ minHeight: "70%" }}>
        <Typography variant="caption">
          {order.consignee?.email?.toUpperCase() ?? ""}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ minHeight: "30%" }}>
        <Typography variant="caption">
          {order.consignee?.phone_number?.toUpperCase() ?? ""}
        </Typography>
      </Stack>
    </Stack>
  );
};
