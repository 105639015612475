import { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Dashboard } from "components/templates/page-private-dashboard";
import {
  Button,
  Grid,
  Modal,
  Typography,
  Alert,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { PickupForm } from "modules/orders/forms/pickup";
import { OrderSummary } from '../components/OrderSummary';
import { getOrderById } from "services/serviceV2/orders";
import OrderActionsButton from "components/atoms/order-actions-button";
import { OrderActionsAlertDto } from 'components/atoms/order-actions-button/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const OrderResult = () => {
  const { orderId } = useParams<any>();
  const { data: order, isLoading } = useQuery({
    queryKey: ["result", orderId ?? ""],
    queryFn: getOrderById(orderId ?? ""),
  });
  const [pickupData, setPickupData] = useState<{
    pickupId: string | null;
    isFormOpen: boolean;
  }>({
    pickupId: null,
    isFormOpen: false,
  });
  const [actionsState, setActionsState] = useState<OrderActionsAlertDto>({
    showAlert: false,
    alertType: "warning"
  });

  const handleSetActionsState = useCallback((data: OrderActionsAlertDto) => {
    setActionsState({ showAlert: data.showAlert, alertMessage: data.alertMessage, alertType: data.alertType });
  }, [actionsState, setActionsState]);

  const handlePickup = useCallback((pickupId: string) => {
    setPickupData({
      pickupId,
      isFormOpen: true,
    });
  }, []);

  const handleClose = useCallback(
    () => window.location.reload(),
    []
  );

  return (
    <>
      <Dashboard>
        <Grid container sx={{
          alignItems: 'center',
          padding: 2
        }} spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h3" fontSize="1rem">
              ¡Todo listo! 🎉
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction={'row'} alignItems={'center'} justifyContent={'flex-end'} flexWrap={"nowrap"} gap={1}>
              <Grid item textAlign={'right'}>
                <Button sx={{ ":hover": { color: '#7E00FB' } }} type="button" href="/shipments/realizados?tab=completed" variant="outlined">Cerrar</Button>
              </Grid>
              <Grid item >
                <OrderActionsButton orderId={order?._id} handleChangeAlert={handleSetActionsState} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={7} marginBottom={2} marginTop={2} sx={{ paddingLeft: 0 }}>
              {actionsState.showAlert &&
                <Alert
                  onClick={() => {
                    setActionsState(() => ({
                      showAlert: false
                    }));
                  }}
                  sx={{ marginLeft: 0 }} severity={actionsState.alertType}>{actionsState.alertMessage}</Alert>
              }
            </Grid>
          </Grid>

          <OrderSummary handlePickup={() =>
            handlePickup(order?._id)
          } orderData={order} />
        </Grid>
      </Dashboard >

      <Modal open={pickupData.isFormOpen} onClose={handleClose}>
        <div>
          <PickupForm id={pickupData.pickupId!} closeModal={handleClose} />
        </div>
      </Modal>
    </>

  );
};
