import styled from 'styled-components';
import { Button } from '../../atoms/button';

export const SectionMain = styled.section`
    text-align: center;
    padding: 20px 88px;
    min-height: ${props => props.height || '50vh'};
    background: ${props => props.background};
`;

export const StyledBtn = styled(Button)`
  padding: 0.8em 1em;
  background-color: #7E00FB;
  color: white;
  border: 1px solid #7E00FB;
  &:hover {
    background-color: #6500c9;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
`;