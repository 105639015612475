import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  MenuItem,
  Typography,
  Divider
} from "@mui/material";

import { DropDownButton } from "commons/dropdown-button";
import { KeyboardArrowDown } from "@mui/icons-material";
import { getUser } from "services/serviceV2/user";

interface Props {
  showSubtitle?: boolean,
}

export const SubHeader = ({ showSubtitle }: Props) => {
  const history = useHistory();
  const { data: authData } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });

  return (
    <Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems={'center'}>
    <Box flexDirection="column" sx={{ display: "flex", p: 1 }}>
      <Typography variant="h3" sx={{ mb: 0 }} fontSize="1rem !important">
        {`Bienvenido a Shipal, ${authData?.firstName ?? ""} ${
          authData?.lastName ?? ""
        }`}
      </Typography>

      {
      // showSubtitle && 
      <Typography sx={{ fontSize: "1rem!important", color: "grey" }}>
        ¿Que clase de envio quieres hacer hoy?
      </Typography>}
    </Box>
      <DropDownButton
        label="Crear Envio"
        variant="outlined"
        sx={{ minHeight: '40px' }}
        endIcon={<KeyboardArrowDown />}
        disableElevation
      >
        <MenuItem onClick={() => history.push("/shipping/national")}>
          Nacional
        </MenuItem>

        <MenuItem onClick={() => history.push("/shipping/international")}>
          Internacional
        </MenuItem>
        <MenuItem onClick={() => history.push("/quoter")}>
          Cotizar envio
        </MenuItem>
      </DropDownButton>
    </Box>
    <Divider sx={{ width: '100%', mt: showSubtitle ? 1 : 1 }} />
  </Box>
  )
};
