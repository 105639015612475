import styled from 'styled-components';
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

export const StyledFormControled = styled(Form.Control)`
 & > #formikCheckbox {
     color: #000,
     background: #fff
 }
`;

export const StyledDatePicker = styled(DatePicker)`
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
`;