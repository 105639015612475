import React from "react";
import { SettingNotifications } from "../../../organisms/setting-notifications";
import { Dashboard } from '../../../templates/page-private-dashboard';

export const Notifications = () => {
  return (
    <Dashboard PageName="Notificaciones">
      <SettingNotifications />
    </Dashboard>
  );
};
