import React from "react";
import { StyledListGroup } from "../../atoms/list-group";
import { ListGroupItem } from "../../atoms/list-group-item";
import { Col, Row } from "react-bootstrap";

export const TableList = ({ list, itemComponent: Component, headers }) => (
  <StyledListGroup variant="flush">
    {headers && (
      <ListGroupItem>
        <Row>
          {headers.map((title, index) => (
            <Col md={index === 1 ? 4 : null} className="text-center" key={index}>
              {title}
            </Col>
          ))}
        </Row>
      </ListGroupItem>
    )}
    {Array.isArray(list) &&
      list.map((elm, index) => (
        <ListGroupItem key={elm.idDoc}>
          <Component {...elm} index={index} key={elm.idDoc} />
        </ListGroupItem>
      ))}
  </StyledListGroup>
);
