import axios from "axios";

import { PaymentService } from "./payment/payment.service";
import { AuthService } from "./auth/auth.service";
import { NotebookService } from "./notebook/notebook.service";
import { ShipmentService } from "./shipment/shipment.service";
import { StoreService } from "./store/store.service";
import { PriceZoneService } from "./price-zone/pricezone.service";
import { WalletService } from "./wallet/wallet.service";
import { client } from "../utils/interceptors";
import { DrawService } from "./serviceV2/draw";
class ImplementService {
  AuthService = new AuthService(client);
  NotebookService = new NotebookService(client);
  WalletService = new WalletService(client);
  constructor() {
    this.PaymentService = new PaymentService(axios);
    this.ShipmentService = new ShipmentService(axios);
    this.StoreService = new StoreService(axios);
    this.PriceZoneService = new PriceZoneService(axios);
    this.DrawService = new DrawService(axios);
  }
}

export const ApiService = new ImplementService();
