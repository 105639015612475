import { types } from "../types";

const initialState = {
  notebooks: [],
  isLoading: false,
};

export const notebookReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "notebookSetIsLoading":
      return {
        ...state,
        isLoading: payload,
      };
    case types.getAllNotebooks:
      return {
        ...state,
        notebooks: payload,
      };
    default:
      return state;
  }
};
