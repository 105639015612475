import React from "react";
import PropTypes from "prop-types";
import { Col, Image, Form } from "react-bootstrap";
import { StyledColSwitch, StyledRow, StyledUl } from "./styles";

export const ItemProvider = ({ item, fnChangeState, perks }) => {
  const { idDoc, urlImg, isActive } = item;
  return (
    <StyledRow key={idDoc}>
      <Col>
        <Image src={urlImg} rounded />
      </Col>
      <Col>
        <StyledUl>
          {perks.map((item) => (
            <li>{item}</li>
          ))}
        </StyledUl>
      </Col>
      <StyledColSwitch>
        <Form.Switch
          onChange={(e) => {
            const value = e.target.checked;
            fnChangeState(item, value);
          }}
          id={idDoc}
          type="switch"
          checked={isActive}
        />
      </StyledColSwitch>
    </StyledRow>
  );
};

ItemProvider.propTypes = {
  item: PropTypes.object.isRequired,
  perks: PropTypes.array,
  fnChangeState: PropTypes.func.isRequired,
};
