import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { FormLabel } from "../../atoms/form-label";
import { FormControl } from "../../atoms/form-control";
import { StyledContainer } from "./styles";
// min max = props
export const FormInput = ({
  label,
  placeholder,
  type,
  textHelp,
  field,
  sublabel: SubLabel,
  textRightInput: TextRightInput,
  ...props
}) => {
  return (
    <Form.Group controlId={!!field && field?.name}>
      {label && (
        <div
          className={`${SubLabel && "d-flex flex-colum justify-content-between"
            }`}
        >
          <FormLabel className="font-weight-bold">{label}</FormLabel>
          {SubLabel && <SubLabel />}
        </div>
      )}
      <div className={`${TextRightInput && "d-flex flex-colum "}`}>
        <StyledContainer>
          <FormControl
            type={type}
            placeholder={placeholder}
            onWheel={(e) => e.currentTarget.blur()}
            {...field}
            {...props}
          />
        </StyledContainer>
        {TextRightInput && <TextRightInput />}
      </div>
      {textHelp && <Form.Text className="text-muted">{textHelp}</Form.Text>}
      {!!field && (
        <ErrorMessage name={field?.name}>
          {(msj) => <Form.Text className="text-danger">{msj}</Form.Text>}
        </ErrorMessage>
      )
      }
    </Form.Group>
  );
};
