import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import { Button } from "../../atoms/button";
import { ChangePassword } from "../../../forms/ChangePassword";

import { ApiService } from "../../../services";

import Verified from "../../../assets/icons/CheckMark.svg";
import Expired from "../../../assets/icons/LinkExpired.svg";
import { Container } from "./styles";
import { useEffect } from "react";

export const RestorePassword = () => {
  const [status, setStatus] = useState("");
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    params.get("token") ?? setStatus("error");
  }, []);

  const handleSubmit = async ({ password }) => {
    try {
      const { status } = await ApiService.AuthService.resetPassword(
        password,
        params.get("token")
      );

      if (status === 200) {
        setStatus("success");
      }
    } catch (error) {
      if (error.response.status === 404)
        console.log(error.response.data.message);

      setStatus("error");
    }
  };

  if (status === "success")
    return (
      <Container>
        <Image src={Verified} width="80px" />
        <p className="title text-center">¡Exito!</p>
        <p className="description text-center">
          <strong>¡Contraseña actualizada!</strong>
        </p>
        <Button
          type="button"
          purple
          onClick={() => history.push("/login")}
          Regular
        >
          ¡Ya puedes logearte!
        </Button>
      </Container>
    );

  if (status === "error")
    return (
      <Container>
        <Image src={Expired} width="70px" />
        <p className="title text-center">¡El token no es valido o caduco!</p>
        <p className="description  text-center">
          <strong>Te sugerimos enviar un nuevo correo de recuperacion</strong>
        </p>
        <Button
          type="button"
          purple
          onClick={() => history.push("/recovery-password")}
          Regular
        >
          Ir a recuperacion de contraseña
        </Button>
      </Container>
    );

  return (
    <Container>
      <p className="title text-center">Cambio de contraseña</p>

      <ChangePassword onSubmit={handleSubmit} />
    </Container>
  );
};
