import React from "react";
import { ListGroup } from "react-bootstrap";
import PropTypes from "prop-types";

export const StyledListGroup = ({ children, ...props }) => (
  <ListGroup {...props}>{children}</ListGroup>
);

StyledListGroup.propTypes = {
  as: PropTypes.string,
  variant: PropTypes.string,
  horizontal: PropTypes.oneOf([true | "sm" | "md" | "lg" | "xl"]),
};
