import styled from "styled-components"
import { Image } from "react-bootstrap"
import { colors } from "../../../constant/branding"

export const ContainerImg = styled(Image)`
  background-size: cover;
  background-repeat: no-repeat;
`

export const ContainerSectionImg = styled.div`
  background-image: url(${({ imgUrl }) => imgUrl});
  background-size: cover;
  background-color: ${({ bg }) => bg};
  min-height: 100vh;
  color: ${({ bg }) => bg === "#ECEBFC" && colors.purpule};
  background-repeat: no-repeat;
  width: ${(props) => (props.fullWidth ? "100%" : "65%")};
  position: relative;
  @media (max-width: 768px) {
    ${(props) => props.hiddeMobile && "display: none;"};
  }

  h3 {
    color: ${colors.purpule};
    font-family: Roboto-Bold;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 39px;
  }

  .description {
    color: #585e59;
    font-family: Roboto-Regular;
    letter-spacing: 0;
    line-height: 21px;
  }
`

export const CardTop = styled.div`
  padding-top: 60px;
  margin: auto;
  width: 70%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > p {
    font-size: 1.5em !important;
  }
`
export const CardContainerBottom = styled.div`
  margin-left: 3em;
  padding: 1em;
  width: 45%;
  position: absolute;
  bottom: 0px;

  .ceo {
    color: #000000;
    font-family: Roboto-Italic;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 1rem;

    p,
    h6 {
      margin: 0px;
    }
  }
`

export const CardBottom = styled.div`
  position: relative;
  font-family: Roboto-Italic;
  font-style: italic;
  line-height: 21px;
  border-radius: 5px;
  letter-spacing: 0;
  background-color: #ffffff;
  color: #000000;
  padding: 10px;

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 24px solid #fff;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
    border-bottom: 20px solid transparent;
    left: 32px;
    bottom: -24px;
  }
`
