import React, { useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import { Field, Formik } from "formik";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQueryString } from "hooks/v2/useQueryString";
import { Typography, Box, Alert } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { startLoginEmailPassword } from "../../../store/auth/auth.actions";
import { loginSchema } from "./validations";

// import { Button } from "../../atoms/button";
import { Logo } from "../../atoms/logo";

import logo from "../../../assets/imgs/shipal-logo6.svg";
import { Content, StyledLogin, Styledloginfield, StyledTextRegister } from "./styles";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [search] = useQueryString();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, SetError ] = useState({});
  const initialValues = {
    email: "",
    password: "",
  };

  const submitForm = async(data) => {
    setIsLoading(() => true);
    let shopifyData = null;
    if((sessionStorage.getItem('access_token') && sessionStorage.getItem('access_token') !== null) && (sessionStorage.getItem('shop') && sessionStorage.getItem('shop') !== null)) {
      shopifyData = {
        shop: sessionStorage.getItem('shop'),
        access_token: sessionStorage.getItem('access_token')
      }
    }
    const dataForDispatch = await dispatch(startLoginEmailPassword(data.email, data.password, shopifyData));
    console.log("dataForDispatch");
    console.log(dataForDispatch);
    if(dataForDispatch.error) {
      SetError({
        hasError: true,
        message: dataForDispatch.error
      })
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if((search.access_token && search.access_token !== null) && (search.shop && search.shop !== null)) {
      sessionStorage.setItem('access_token', search.access_token);
      sessionStorage.setItem('shop', search.shop);
      queryParams.delete('access_token');
      queryParams.delete('shop');
      history.replace({
        search: queryParams.toString(),
      })
    }
    }, [search, history, location]
  );

  const boxStyles = useMemo(
    () => ({
      display: "flex",
      py: 3,
      justifyContent: "center",
    }),
    []
  );

  return (
    <div
      className="px-0 py-0 d-flex flex-column justify-content-center"
    >
      <Content >
        <div style={{ width: 150 }}>
          <Logo srcUrl={logo} alt="logo-shipal" heigth="120" />
        </div>
        <Typography variant="subtitle1" fontSize={"1.5rem"}>Iniciar sesión en Shipal</Typography>
        <p className="text-muted " sx={{ 'marging-button': '1rem!important' }}>
          ¿A donde enviamos hoy? ¡Feliz envío!
        </p>
        {error && error.hasError &&
        <Alert sx={{ mb: 2 }} severity="error" onClose={() => SetError(() => {})}>
          {error.message}
        </Alert>
        }
      </Content>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={loginSchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              type="email"
              autoComplete="off"
              label="Correo electrónico"
              name="email"
              component={Styledloginfield}
              data-cy="email"
              heigth={"60px"}
            />

            <Field
              type="password"
              label="Contraseña"
              name="password"
              text="De 6 o màs caracteres"
              component={Styledloginfield}
              data-cy="password"
              heigth={"60px"}
            />
            <Typography
            textAlign={'right'}>
              <Link
                  to="/recovery-password"
                  style={{ color: "#6666", textDecoration: "none" }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
            </Typography>
            <Box display="flex" sx={boxStyles}>
              <LoadingButton
                type="submit"
                variant="contained"
                width={'100%'}
                loading={isLoading}
                sx={{ flex: 'auto', height: '60px' }}
              >
                Enviar
              </LoadingButton>
            </Box>
            <Col className="py-3 d-flex justify-content-around flex-column align-items-left">
              <div className="text-center my-4">
                <StyledTextRegister>¿No tienes una cuenta?</StyledTextRegister>
                <StyledLogin to={`/register${(sessionStorage.getItem('access_token') && sessionStorage.getItem('access_token') !== null) && (sessionStorage.getItem('shop') && sessionStorage.getItem('shop') !== null)? `?access_token=${sessionStorage.getItem('access_token')}&shop=${sessionStorage.getItem('shop')}`: ''}`}>Registrate</StyledLogin>
              </div>
            </Col>
          </form>
        )}
      </Formik>
    </div>
  );
};
