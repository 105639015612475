import React from "react";
import PropTypes from 'prop-types';       
import { Route, Redirect } from "react-router-dom";
import { useQueryString } from "hooks/v2/useQueryString";

export const PublicRoute = ({ isLoggedIn, component: Component, ...rest }) => {
  const [search] = useQueryString();

  return (
    <Route
      {...rest}
      component={(props) => 
        !isLoggedIn ? <Component {...props} /> : <Redirect to={`/${(search.access_token && search.access_token !== null) && (search.shop && search.shop !== null)? `?access_token=${search.access_token}&shop=${search.shop}`: ''}`} />
      }
    />
  );
};

PublicRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  component: PropTypes.func,
}


