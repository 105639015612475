import React, { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Typography,
  Skeleton,
} from "@mui/material";
import { listHeaders } from "./headers";
import { getUserOrders, bulkDelete } from "services/serviceV2";
import { getComparator, stableSort } from "utils/sort";
import { usePagination } from "hooks/v2/usePagination";
import type { ButtonProps } from "@mui/material";
import ShipalTable from "components/organisms/Table/Table";
import { useHeaders } from "./hooks";

export const DraftShipments = () => {
  const [pagination, actionPagination] = usePagination();
  const [order, setOrder] = React.useState<any>("desc");
  const [orderBy, setOrderBy] = React.useState<any>(listHeaders[0].id);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const headersV2 = useHeaders()

  const listOrdersQuery = useQuery({
    queryKey: ["orders", "draft", pagination.page, pagination.peerPage],
    queryFn: () =>
      getUserOrders({
        page: parseInt(pagination.page),
        size: parseInt(pagination.peerPage),
        draftOrders: true,
      }),
    select: (values) => {
      const { data, ...rest } = values;
      const newData = data?.map((item: any) => ({
        ...item,
        company_name: item?.shipal?.company_name ?? "Sin Store",
        client_name: item?.consignee?.company_name || "Sin Cliente",
      }));
      return {
        docs: newData,
        ...rest,
      };
    },
  });

  const rows = listOrdersQuery?.data?.docs || [];

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );

  // const toggleModal = useCallback(() => {
  //   setOpenDeleteModal((prev) => !prev);
  // }, []);

  const handleBulkDelete = async (selectedIds: string[]) => {
    await setIsLoading(true);
    await bulkDelete(selectedIds);
    await setIsLoading(false);
    await queryClient.invalidateQueries({ queryKey: ["orders", "draft", pagination.page, pagination.peerPage] });
    // await toggleModal();
  }
  const handleChange = (type: string) => (event: any, value: any) => actionPagination(type, type === "page" ? value.toString() : event.target.value);

  if (listOrdersQuery.error)
    return <Typography>No se pudo obtener la lista de envíos</Typography>;

  if (listOrdersQuery.isLoading)
    return (
      <Skeleton
        sx={{
          height: "5rem",
        }}
      />
    );

  const actionButtons = {
    handleDuplicate: () => { },
    handleDelete: (selectedIds: string[]) => handleBulkDelete(selectedIds)
  }

  return (
    <ShipalTable
          tableName="envios realizados"
          rows={visibleRows}
          headers={headersV2}
          isLoading={false}
          listOrdersQuery={listOrdersQuery}
          pagination={pagination}
          handleChange={handleChange}
          isCheckable={true}
          actionButtons={actionButtons}
      />
  );
};
