import React, { useMemo } from 'react'
import { TitleCard } from '../atoms/titleCard'
import { Box, Card, CardContent, Typography } from '@mui/material';
import { MdEmail, MdPhone } from 'react-icons/md'
import { styles } from "../styles";
import { ConsigneeProps, ShipperProps } from 'components/molecules/order-forms/types';
type Props = {
    handleModal?: () => void;
    body:  ShipperProps | ConsigneeProps;
    title: string;
  };
export const ContactCard = ({title, handleModal, body}:Props) => {
  const textFiledStyle = useMemo(
    () => ({
      fontSize: "12px!important",
      color: "#677787",
    }),
    []
  );
  return (
    <Card>
    <TitleCard title={title} handleOpenModal={handleModal} validations={body.location_validation} />
    <CardContent sx={{pt: "16px"}}>
      <Typography variant="h2" sx={{ fontSize: "1rem!important" }}>
        {body?.person_name}
      </Typography>
      <Typography sx={textFiledStyle}>
        {body?.address_line.trim()} - {body?.city}
      </Typography>
      <Box sx={styles.iconAndLabel}>
        {/* <Typography>{body?.country.icon}</Typography> */}
        <Typography sx={textFiledStyle}>
        {body?.country_name}
        </Typography>
      </Box>
      <Box sx={styles.iconAndLabel}>
        <MdPhone />
        <Typography sx={textFiledStyle}>
          {body?.phone_number}
        </Typography>
      </Box>
      <Box sx={styles.iconAndLabel}>
        <MdEmail />
        <Typography sx={textFiledStyle}>
          {body?.email}
        </Typography>
      </Box>
    </CardContent>
  </Card>
  )
}
