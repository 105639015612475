import styled from "styled-components";
import ListGroupItem from "react-bootstrap/ListGroupItem";

export const StyledListGroupItem = styled( ListGroupItem)`
  .form-control {
  }
  .list-group-item {
    border-top: none !important;
    border-right: none!important;
    border-left: none!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
`;
