import axios from "axios";

export class StoreService {
  constructor(client) {
    this.client = client;
  }
  connectWoocommerce = async (values, type) => {
    try {
      const res = await axios.get(`https://api.ipify.org?format=json`);

      const { consumerKey = "", consumerSecret = "", url = "", uid } = values;
      const newValues = {
        connect: {
          url,
          apikey: consumerKey,
          apiSecret: consumerSecret,
          ip: res?.data.ip,
          platform: "wocomerce",
          name: "wocomerce",
          uid,
        },
        remove: {
          uid,
        },
      };
      const { data } = await this.client.post(
        `/ecommerces/woocomerce/createConnect/${type}`,
        newValues[type]
      );

      return data;
    } catch (error) {
      alert("error");
      throw error;
    }
  };

  getStoreConnectWoocommerce = async (uid) => {
    try {
      const  data  = await this.client.get(
        `/ecommerces/woocomerce/getConnects/${uid}`
      );
      console.log("DATA-----", data)
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getOrdersWoocommercer = async (uid) => {
    try {
      const { data } = await this.getStoreConnectWoocommerce(uid);
      let response = null;

      if (Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-use-before-define
        const { apiSecret, apikey, url } = data;
        const {
          data: { data },
        } = await this.client.get(
          `/ecommerces/woocomerce/getAllOrder/${apikey}/${apiSecret}/${uid}?url=${url}`
        );
        response = data;
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
