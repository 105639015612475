import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { 
  Typography,
  Box,
  Link,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Grid,
  CardActionArea,
  CardActions,
  Button,
  Skeleton
 } from "@mui/material";
 import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { InfoOutlined } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';

import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "commons/form-provider";

import type { BoxProps, ButtonProps, CardProps } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bookPickup } from "services/serviceV2/orders";
import { getPickupDays } from "services/serviceV2/orders";
import { PickupData, PickupError, PickupDataDays } from "services/serviceV2/orders/types";
import { requestPickupSchema } from "./validation";

type Props = {
  id: string;
  closeModal: () => void;
};

enum EPickupType {
  SELF_PICKUP = 'SELF_PICKUP',
  AUTOMATIC_PICKUP = 'AUTOMATIC_PICKUP',
}

export const PickupForm = ({ id, closeModal }: Props) => {
  const queryClient = useQueryClient();
  const [ error, setError ] = useState<PickupError>();
  const [ checkedCustom, setCheckedCustom ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const { data: pickupDayData, isLoading: loadingPickupData }: UseQueryResult<PickupDataDays> = useQuery(
    ["pickup-days"],
    () => getPickupDays(id)
   );
  const [ scheduleCardTypeSelected, setscheduleCardTypeSelected ] = useState("today");
  const currentDateTime = new Date();

  const closeTodayTime = new Date(new Date().setHours(12, 0, 0));
  const canScheduleToday = currentDateTime < closeTodayTime;

  const nextDay = new Date(new Date().setHours(12, 0, 0));
  let day = nextDay.getDay(), add = 1;
  if (day === 6)           add = 2; else 
  if (day === 5)           add = 3;
  nextDay.setDate(nextDay.getDate() + add); 
  
  if (nextDay < currentDateTime) {
    nextDay.setDate(nextDay.getDate() + 7);
  }

  const boxStyles = useMemo<BoxProps["sx"]>(
    () => ({
      position: "absolute" as "absolute",
      top: "20%",
      left: "30%",
      minWidth: 450,
      bgcolor: "white",
      p: 4,
      borderRadius: "1rem",
    }),
    []
  );

  const selectedStyles = useMemo<CardProps["sx"]>(
    () => ({
      border: 2,
      borderColor: "#7E00FB",
    }),
    []
  );

  const buttonStyles = useMemo<ButtonProps["sx"]>(
    () => ({ width: "50%", marginLeft: "auto", marginRight: "auto" }),
    []
  );

  const { mutate, isError } = useMutation(
    async (data: PickupData) => {
      setIsLoading(true);
      return bookPickup(data)
    },
    {
      onSuccess: () =>
        {
          setIsLoading(false);
          closeModal();
          return queryClient.invalidateQueries({ queryKey: ["orders", "completed"] });
        },
      onError: (errordata: PickupError) => {
        setIsLoading(false);
        setError(errordata);
      }
    }
  );

  const changePickuptype = useCallback(() => setCheckedCustom(state => !state), [setCheckedCustom]);

  const submitFn = useCallback(
    () =>
    { 
      let date = scheduleCardTypeSelected === "today"? pickupDayData?.today.date: pickupDayData?.next_day.date;
  
      return mutate({ date: date? date: "", type: checkedCustom? EPickupType.SELF_PICKUP: EPickupType.AUTOMATIC_PICKUP, orderId: id })
    },
    [id, mutate, checkedCustom, scheduleCardTypeSelected, currentDateTime, nextDay]
  );

  useEffect(() => {
    if(!canScheduleToday) {
      setscheduleCardTypeSelected("nextDay");
    }
  }, [checkedCustom, scheduleCardTypeSelected, setscheduleCardTypeSelected, canScheduleToday]);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(requestPickupSchema)
  });

  const renderChoiceCard = (cardType: string) => {

      return (<Card sx={
        (cardType === scheduleCardTypeSelected) ? 
        selectedStyles: {}}>
          {loadingPickupData? 
          <Skeleton variant="rectangular" width={"100%"} height={"7rem"} />:
          <CardActionArea 
                  onClick={() => setscheduleCardTypeSelected(cardType)}
                  sx={{ minHeight: "7rem", background: !canScheduleToday? '#F5F5F5': cardType === scheduleCardTypeSelected? '#FCFAFF' : '' , opacity: (!canScheduleToday && cardType === "today")? 0.5: 1 }} 
                  disabled={!canScheduleToday && (cardType === "today")}
                  >
                  <CardContent sx={{ padding: 1.5, py: 0 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {cardType === "today"? 'Hoy': pickupDayData?.next_day.day}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {cardType === "today"? `${pickupDayData?.today.monthAbbreviated} ${pickupDayData?.today.date.slice(-2)}`: `${pickupDayData?.next_day.monthAbbreviated} ${pickupDayData?.next_day.date.slice(-2)}`}
                    </Typography>
                    <CardActions sx={{ p:0 }}>
                  {!canScheduleToday && (cardType === "today")  ?
                    <Typography sx={{ textAlign: 'left', p:0 }} width={'100%'} variant="body2" color="text.secondary">
                      <Grid container spacing={0}>
                        <Grid item xs={2}>
                          <InfoOutlined sx={{ mt: 1 }} />
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container>
                            <Grid item xs={12}>
                              No disponible después
                            </Grid>
                            <Grid item xs={12}>
                              de las 11:59 AM
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Typography>:
                    <Typography sx={{ textAlign: 'right', p:0 }} width={'100%'} variant="body2" color="text.secondary">
                      Aprox 2pm - 5pm 
                    </Typography>
                  }
                </CardActions>
                  </CardContent>
                </CardActionArea>
            }
              </Card>);
  }

  if (isError) {
    return (
      <Box sx={boxStyles}>
            <Typography>Hubo un error al intentar agendar la recolección</Typography>

            {error?.listErrors && (
              <ul>
                {error.listErrors.map((error, index) => (
                  <li key={index}>
                    <Typography>{error}</Typography>
                  </li>
                ))}
              </ul>
            )}

            <Link href={`https://wa.me/${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}?text=Hola!,%20tengo%20este%20problema%20al%20programar%20recoleccion,%20${error?.listErrors.map((currentError) => (`${currentError}%20`))}`} target="_blank">
              contactar con soporte
            </Link>
      </Box>
    );
  }

  return (
    <Box sx={boxStyles}>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(submitFn)} >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          rowGap="2em"
        >
          <Typography variant="h3" >Fecha de recolección</Typography>

          <FormControlLabel 
            control={<Checkbox checked={checkedCustom} onChange={changePickuptype}
            />} 
            label="Entregar en oficina"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderChoiceCard("today")}
            </Grid>
            <Grid item xs={6}>
              {renderChoiceCard("nextDay")}
            </Grid>
          </Grid>
           <Grid container spacing={2} justifyContent={'end'}>
            <Grid item xs={3} sx={{ paddingLeft: '0px!important' }} textAlign={'center'}>
              <Button variant="text" onClick={() => closeModal()} type="button"  sx={buttonStyles}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ paddingLeft: '0px!important' }} textAlign={'center'}>
              <LoadingButton loading={isLoading} variant="contained" type="submit"  sx={buttonStyles}>
                Enviar
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};
