import React, { useState } from "react";
import { Image } from "react-bootstrap";

import { Currency } from "../../atoms/Currency";
import { ShippingCreationStep1 } from "../../organisms/quote-creation-step1";
import { SectionOrganism } from "../../organisms/section-organism";
import { Table } from "../../organisms/table-organism";
import { Dashboard } from "../../templates/page-private-dashboard";

import { ApiService } from "../../../services";

import iconCOO from "../../../assets/imgs/iconCoordinadora.png";
import iconDHL from "../../../assets/imgs/iconDHL.png";
import iconFEDEX from "../../../assets/imgs/logoFedex.png";
import { MainHomeDiv, StyledBtn } from "./style";

export const QuotePage = () => {
  const [isNational, setIsNational] = useState(true);
  const [quote, setQuote] = useState(null);

  const getQuotes = async ({
    declaredValue,
    national,
    consignee,
    shipper,
    pieces,
  }) => {
    try {
      const quotes = await ApiService.WalletService.quote({
        declaredValue,
        national,
        consignee,
        shipper,
        pieces,
      });

      setQuote(quotes);
    } catch (error) {
      setQuote([]);
    }
  };

  const carrierImages = {
    DHL: iconDHL,
    FEDEX: iconFEDEX,
    COORDINADORA: iconCOO,
  };

  if (quote) {
    return (
      <Dashboard>
        <MainHomeDiv>
          <SectionOrganism>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap-reverse",
                minHeight: "inherit",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {quote.length === 0 ? (
                  <h2>
                    No encontramos carriers disponibles para la ciudad remitente
                    o destino
                  </h2>
                ) : (
                  <>
                    <h2>{`Aquí puedes encontrar tu cotización de envío ${
                      isNational ? "Nacional." : "Internacional."
                    }`}</h2>
                    <p>
                      Pueden haber variaciones en precio por el dolar, y la
                      veracidad de la información que se incluye, también puede
                      generar demoras en los envíos.
                    </p>

                    <Table
                      pagination={false}
                      isQuote
                      quote={quote.map((quoteElm) => ({
                        delivery: (
                          <Image
                            style={{
                              width: "7rem",
                            }}
                            src={carrierImages[quoteElm.delivery]}
                          />
                        ),
                        safe: (
                          <Currency
                            ammount={quoteElm.safe}
                            sufix={quoteElm.safeCurrency}
                          />
                        ),
                        price: (
                          <Currency
                            ammount={quoteElm.totalPrice + quoteElm.surcharge}
                            sufix={quoteElm.currency}
                          />
                        ),
                      }))}
                    />
                  </>
                )}
                <StyledBtn
                  onClick={() => {
                    setIsNational(true);
                    setQuote(null);
                  }}
                >
                  Nueva Cotización
                </StyledBtn>
              </div>
            </div>
          </SectionOrganism>
        </MainHomeDiv>
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <MainHomeDiv>
        <SectionOrganism>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap-reverse",
              minHeight: "inherit",
              alignContent: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ShippingCreationStep1
                handleSubmit={getQuotes}
                isNational={isNational}
                setIsNational={(value) => setIsNational(value)}
              />
            </div>
          </div>
        </SectionOrganism>
      </MainHomeDiv>
    </Dashboard>
  );
};
