import React from "react";
import { TitleCard } from "../atoms/titleCard";
import {
  Button,
  Card,
  Fade,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Package_informationProps } from "components/molecules/order-forms/types";
import { pesoVol } from "utils/calculations";
import { SHIPPING_TYPE } from "enums/shipping";

type Props = {
  handleModal?: () => void;
  body: any;
  title: string;
  actions?: any[];
  typeShipping: SHIPPING_TYPE;
  isShopifyShipment?: boolean
};

export const PackageDetail = ({
  handleModal,
  title,
  body,
  actions,
  typeShipping,
  isShopifyShipment
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ currentIndex, setCurrentIndex ] = React.useState<number>();
  const open = Boolean(anchorEl);
  const handleClick = React.useCallback(
    async(event: React.MouseEvent<HTMLElement>, index: number) =>
      {
        await setAnchorEl(event.currentTarget);
        await setCurrentIndex(index);

      },
    [currentIndex, anchorEl]
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <TitleCard handleOpenModal={handleModal} title={title} buttonName="Agregar otro paquete" />

      <TableContainer
        component={Paper}
        sx={{
          pl: "24px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "1px solid #F0F2F5",
              }}
            >
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "white",
                  fontWeight: "500",
                }}
              >
                {isShopifyShipment? "Cantidad": "Paquete"}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "white",
                  fontWeight: "500",
                }}
              >
                Descripción
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: "white",
                  fontWeight: "500",
                }}
              >
                Peso Físico
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: "white",
                  fontWeight: "500",
                }}
              >
                Peso Volumétrico
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: "white",
                  fontWeight: "500",
                }}
              >
                Peso de envío
              </TableCell>
              {!!actions && (
                <TableCell
                  align="right"
                  sx={{
                    backgroundColor: "white",
                    fontWeight: "500",
                  }}
                >
                  Acciones
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {body.map(
              (item: Partial<Package_informationProps>, index: number) => (
                <TableRow
                  key={item?.piece_ID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row" align="left">
                    Paquete #{(item?.piece_ID || 0) + 1 ?? index}
                  </TableCell>
                  <TableCell align="left">{item?.piece_contents}</TableCell>
                  <TableCell align="right">{item?.weight} kg</TableCell>
                  <TableCell align="right">
                    {pesoVol(
                      item?.width!,
                      item?.height!,
                      item?.depth!,
                      typeShipping
                    )} kg
                  </TableCell>
                  <TableCell align="right">{item?.payable_weight} kg</TableCell>

                  {!!actions && (
                    <TableCell align="right">
                      <Button
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(event) => handleClick(event, index)}
                      >
                        Acciones
                      </Button>

                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        {actions?.map((action: any, i: number) => (
                          <MenuItem
                            key={`${action?.name}`}
                            onClick={() => {
                              setAnchorEl(null);
                              action?.handle(currentIndex)
                            }}
                          >
                            {action?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
