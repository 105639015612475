import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { ApiService } from "../../../services";

import { Image } from "react-bootstrap";
import { Button } from "../../atoms/button";

import CheckEmail from "../../../assets/imgs/CheckEmail.png";
import { Container } from "./styles";

export const VerifyAccount = ({ setMessage }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const resendActivationEmail = async () => {
    try {
      setLoading(true);
      setDisabledButton(true);

      await ApiService.AuthService.sendActivationEmail(params.get("email"));

      setDisabledButton(false);
    } catch (error) {
      if (error.response.status !== 409 && error.response.status !== 404) {
        setDisabledButton(false);
      }

      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage(
          "Fallo al enviar correo de activacion, reintenta nuevamente"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Image src={CheckEmail} width="200px" />
        <p className="title">
          {loading
            ? "Enviando..."
            : `¡Hemos mandado un correo a ${params.get(
                "email"
              )} revisa si te llego!`}
        </p>
        <Button
          purple
          outline
          Regular
          className="send_again"
          disabled={!params.get("email") || disabledButton}
          onClick={resendActivationEmail}
        >
          Reenviar
        </Button>
        <p className="description">
          Por favor, revisa tu bandeja de entrada y sigue las instrucciones del
          correo de verificación. Después podrás empezar a enviar a todo el
          mundo con Shipal!
        </p>
      </Container>
    </>
  );
};
