import React from 'react';
import { SectionMain } from './style';

export const SectionOrganism = ({ background, height, children }) => {
    return (
        <SectionMain background={background} height={height}>
            {children} 
        </SectionMain>
    )
};
