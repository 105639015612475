import * as CryptoJS from "crypto-js";

import { makeId } from "./caractergeneration"
import { CONFIG } from "./config"


export const encryptDataV2 = (data, type, mode = 0) => {
  let iv = CryptoJS.enc.Base64.parse(CONFIG.CryptoJS.iv);
  let key = null
  let encryptedString = null

  let typeArr = ["MD5", "SHA-256"];
  let arrFunc = [CryptoJS.MD5, CryptoJS.SHA256]
  let index = typeArr.indexOf(type);

  let charac = makeId(3);

  if (mode !== 0 && mode === 1) {
    data += charac
  }

  if (index > -1) {
    key = arrFunc[index](CONFIG.CryptoJS.key)
    if (typeof data === "string") {
      encryptedString = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    } else {
      encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
  }

  return encryptedString.toString();
}

export const decryptDataV2 = (data, type) => {
  let iv = CryptoJS.enc.Base64.parse(CONFIG.CryptoJS.iv);
  let key = null

  let typeArr = ["MD5", "SHA-256"];
  let arrFunc = [CryptoJS.MD5, CryptoJS.SHA256]
  let index = typeArr.indexOf(type);

  if (index > -1) {
    key = arrFunc[index](CONFIG.CryptoJS.key)
    if (typeof data === "string") {
      let decrypted = CryptoJS.AES.decrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  }
}



