import * as Yup from "yup";

const specialCharsRegex = /^[A-Za-z0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
const message = 'Evita el uso de los caracteres "&", "Ñ" y las tildes.';

const especialCharsWitnÑ = /^[A-Za-zÑñ0-9\s!"#$%()*+,-./:;<=>?@[\\\]^_{|}~]*$/;
const messageWithÑ = 'Evita el uso de los caracteres "&", y las tildes.';

export const shipperAddressSchema = Yup.object({
  address_line: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});

export const consigneeAddressSchema = Yup.object({
  address_line: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  city: Yup.string()
    .trim()
    .required()
    .matches(especialCharsWitnÑ, messageWithÑ),
  company_name: Yup.string()
    .trim()
    .optional()
    .matches(specialCharsRegex, message),
  country_code: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  postal_code: Yup.string()
    .trim()
    .required("El código postal es requerido")
    .min(4, "Mínimo 4 caracteres")
    .matches(/^[a-zA-Z0-9-]+$/, {
      message: 'No se permiten espacios ni caracteres especiales, excepto "-"',
      excludeEmptyString: true,
    }),
  email: Yup.string()
    .trim()
    .email()
    .required()
    .matches(specialCharsRegex, message),
  person_name: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
  phone_number: Yup.string()
    .trim()
    .required()
    .matches(specialCharsRegex, message),
});
