import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

export const ListGroupItem = ({ children, ...props }) => (
  <ListGroup.Item {...props}>{children}</ListGroup.Item>
);

ListGroupItem.propTypes = {
  action: PropTypes.bool,
  href: PropTypes.string,
  active: PropTypes.bool,
  disable: PropTypes.bool,
  as: PropTypes.oneOf(["div" | "a" | "button"]),
  variant: PropTypes.oneOf([
    "primary" |
      "secondary" |
      "success" |
      "danger" |
      "warning" |
      "info" |
      "dark" |
      "light",
  ]),
};
