import { types } from "../../types";

const INITIAL_STATE = {
  payment: "",
};

export const paymentPSEReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.PAYMENTPSE:
      return {
        ...state,
        payment: payload,
      };
    default:
      return state;
  }
};
