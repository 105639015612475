import { UseFormSetValue } from "react-hook-form";

const manualValidation = { shouldDirty: true, shouldValidate: true };

export type LabelsSelectValueProp = {
  labelBySelected: string;
  labelByBodySave: string;
};


export const saveSetValue = (
  setValue: UseFormSetValue<any>,
  label: string,
  data: any
) => {
  setValue(label, data, manualValidation);
};

export const saveSetValueBySelectMultiValue = (
  setValue: UseFormSetValue<any>,
  index: string,
  dictionary : any,
  arrayItems: any[]
) => {
  console.log("=====================");
  console.log(dictionary)
  const onSavekeys = Object.keys(dictionary);
  console.log(arrayItems);
  console.log(index);
  console.log(arrayItems[Number(index)]);
  onSavekeys.forEach((key) => {
    saveSetValue(
      setValue,
      key,
      arrayItems[Number(index)][dictionary[key]]
    );
  });
};

