import React, { useCallback, useMemo, useState } from "react";
import {
  ButtonProps,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useQuery } from "@tanstack/react-query";
import { getUserOrders } from "services/serviceV2";
import { colors } from "constant/branding";
import { handleTrackingUrl } from "utils/tracking";
import { getComparator, stableSort } from "utils/sort";
import { PickupForm } from "modules/orders/forms/pickup";
import { usePagination } from "hooks/v2/usePagination";
import { OrderStatus } from "services/serviceV2/orders/types";
import { styled } from '@mui/material/styles';
import ShipalTable from "components/organisms/Table/Table";
import { useHeaders } from "./hooks";
import { listHeaders } from "./headers";


const { REACT_APP_AWS_BRANCH } = process.env;

export const CompletedShipments = () => {
  const [pagination, actionPagination] = usePagination();
  const [order, setOrder] = React.useState<any>("desc");
  //FIXME:
  const [orderBy, setOrderBy] = React.useState<any>(listHeaders[0].id);
  const [pickupData, setPickupData] = useState<{
    pickupId: string | null;
    isFormOpen: boolean;
  }>({
    pickupId: null,
    isFormOpen: false,
  });


  const listOrdersQuery = useQuery({
    queryKey: ["orders", "completed", pagination.page, pagination.peerPage],
    queryFn: () =>
      getUserOrders({
        page: parseInt(pagination.page),
        size: parseInt(pagination.peerPage),
      }),
    select: (values) => {
      const { data, ...rest } = values;
      const newData = data?.map((item: any) => ({
        ...item,
        company_name: item?.shipal?.company_name ?? "Sin Store",
        client_name: item?.consignee?.company_name || "Sin Cliente",
      }));
      return {
        docs: newData,
        ...rest,
      };
    },
  });

  const rows = listOrdersQuery?.data?.docs || [];

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );

  const pickupButtonStyles: ButtonProps["sx"] = useMemo(
    () => ({
      background: colors.purpule,
    }),
    []
  );

  const handleChange = (type: string) => (event: any, value: any) =>
    actionPagination(
      type,
      type === "page" ? value.toString() : event.target.value
    );

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler =
    (property?: string) => (event: React.MouseEvent<unknown>) =>
      property && handleRequestSort(event, property);

  const handlePickup = useCallback((pickupId: string) => {
    setPickupData({
      pickupId,
      isFormOpen: true,
    });
  }, []);

  const handleClose = useCallback(
    () =>
      setPickupData({
        pickupId: null,
        isFormOpen: false,
      }),
    []
  );

  const generateLabelLink = useCallback(
    (labelId: string) =>
      `${REACT_APP_AWS_BRANCH === 'prod' ? 'https://shipment-labels-production.s3.amazonaws.com/' : 'https://shipment-labels-dev.s3.amazonaws.com/'}${labelId}`,
    []
  );



  const headersV2 = useHeaders(handlePickup, pickupButtonStyles, generateLabelLink, handleTrackingUrl)
  const actionButtons = {
    handleDuplicate: () => { }
  }

  if (listOrdersQuery.error)
    return <Typography>No se pudo obtener la lista de envios</Typography>;

  if (listOrdersQuery.isLoading)
    return (
      <Skeleton
        sx={{
          height: "5rem",
        }}
      />
    );

  return (
    <>
      <Stack
        alignItems="center"
        gap={2}
      >
        <ShipalTable
          tableName="envios realizados"
          rows={visibleRows}
          headers={headersV2}
          isLoading={false}
          listOrdersQuery={listOrdersQuery}
          pagination={pagination}
          handleChange={handleChange}
          isCheckable={true}
          actionButtons={actionButtons}
        ></ShipalTable>
      </Stack>

      <Modal open={pickupData.isFormOpen} onClose={handleClose}>
        <PickupForm id={pickupData.pickupId!} closeModal={handleClose} />
      </Modal>
    </>
  );
};
