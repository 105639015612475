export class PaymentService {
  constructor(client) {
    this.client = client;
  }

  generateToken = async (values) => {
    const data = await this.client.post(`/payment/token/create`, values);
    console.log("data retornada del back", data);
  };

  createUserAndSubscribe = async (values) => {
    try {
      const { data } = await this.client.post(
        "/payment/createAndSubscribe",
        values
      );
      return data;
    } catch (error) {
      throw error;
    }
  };

  getListBank = async () => {
    try {
      const { data } = await this.client.get("/payment/getlistbank");
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getStatusTransf = async (id) => {
    try {
      const { data } = await this.client.get(`/payment/info/statusTranf/${id}`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getStatusTransfM = async (id) => {
    try {
      const { data } = await this.client.get(`/payment/info/statusTranM/${id}`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  createTranfeBank = async (body) => {
    try {
      const data = await this.client.post("/payment/createBankTranf", body);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
