import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardContent, CardActions, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";

import { LoginMessage } from "../../molecules/login-message";
import { LoginForm } from "../../organisms/login-form";
import { SectionImg } from "../../organisms/section-img";
import RegisterForm from "../../../forms/Signup";

import { ApiService } from "../../../services";

export const Auth = () => {
  const [modalMessage, setMessage] = useState(null);
  const { pathname } = useLocation();
  const history = useHistory();


  useEffect(() => {
    console.log("pathname");
    console.log(pathname)
    },[]
  );

  const handleRegister = async (values) => {
    try {
      const { status } = await ApiService.AuthService.register(values);

      if (status === 201) {
        history.push(`/verify-account?email=${values.email}`);
      }
    } catch (error) {
      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage("No pudimos registrar tu cuenta, intentalo de nuevo");
      }
    }
  };

  const handleStyles = { overflow: "scroll", height: "100vh" };

  return (
    <Stack spacing={2} 
      direction="row" 
      justifyContent="center"
      alignItems="center"
      height={'100vh'}
      style={{ background: "#F7F7FF" }}>
        <Card sx={{ minWidth: 500, maxWidth: 500 }}>
          <CardContent>
            <LoginForm />
          </CardContent>
        </Card>
    </Stack>
  );
};





{/* <Row className="p-0 m-0">
<SectionImg hiddeMobile imgUrl={""} screen="login" />
<Col>
  <LoginForm />
</Col>

</Row>
<LoginMessage
message={modalMessage}
handleMessage={() => setMessage(null)}
/> */}