import { useCallback, useState } from "react";
import { useQueryString } from "./useQueryString";
import { useHistory } from "react-router-dom";

export const usePagination = (): [
  Record<string, string>,
  Function
] => {
  const history = useHistory();
  const [search, queryString] = useQueryString();

  const currentSearch = {
    tab: (search?.tab ?? "pending") as string,
    page: (search?.page ?? "1") as string,
    peerPage: (search?.peerPage ?? "5") as string,
  };
  const [pagination, setPagination] =
    useState<Record<string, string>>(currentSearch);
  const callback = useCallback(
    (type: string, value: string) => {
      const newDataPaginate = {
        ...pagination,
        [type]: value,
      };
      const newPath = queryString.stringify({
        tab: pagination.tab,
        ...newDataPaginate,
      });
      setPagination(newDataPaginate);
      history.push({
        pathname: history.location.pathname,
        search: newPath,
      });
    },
    [pagination]
  );
  return [currentSearch, callback];
};
