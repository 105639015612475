import styled from "styled-components";
import { Card } from "react-bootstrap";
import { colors } from '../../../constant/branding';

export const AlertBar = styled.div`
  color: white;
  text-align: center;
  background-color: #ff3366;
`;

export const StyledQuoteButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 33px;
  .btn {
    width: 127px;
    height: 39px;
    outline: none;
    color: #fff;
    margin-bottom: 0.5rem;
    background-color: ${colors.purpule};
    border-color: ${colors.purpule};
    box-shadow: 0px 8px 20px 0px #dee2e6;
    border: none;
  } 
`;

export const StyledDots = styled.div`
  background-image: radial-gradient(#000 20%,transparent 20%);
  background-position: 0 0;
  background-size: 15px 14px;
  height: 100px;
  width: 77px;
  position: absolute;
  right: 0;
`;

export const StyledCard = styled(Card)`
  ${props => props.cardformat ?  `
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px #dee2e6;
  ` :
  `border: 1px solid rgba(0, 0, 0, 0.125);
  `}
  background-color: #ffffff;
  list-style: none;
  border: none;
`;

export const StyledDescription = styled.p`
font-family: Roboto-Regular;
padding: 1rem 0px;
margin: 0px;
`;
