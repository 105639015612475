import React from "react";
import { useMemo } from "react";
import { TableHeader } from "components/organisms/Table/Table.models";
import { OrderInfoTableCell } from "../orderInfoTableCell";
import { ConsigneeTableCell } from "../consigneeTableCell";
import { ItemResume } from "../itemResume";
import { PaymentButton } from "components/molecules/payment-button";
import { CarrierInfoTableCell } from "../carrierInfoTableCell";

export const useHeaders = () => {
  return useMemo(
    (): TableHeader[] => [
      {
        id: "invoice_number",
        renderComponent: (props) => <OrderInfoTableCell order={props} />,
        label: "Orden",
        width: "10%",
      },
      {
        id: "client_name",
        renderComponent: (props) => <ConsigneeTableCell order={props} />,
        label: "Destino",
        width: "25%",
      },
      {
        id: "item",
        renderComponent: (props) => <ItemResume order={props} />,
        label: "Articulos",
      },
      {
        id: "shipments",
        renderComponent: (props) => (
          <CarrierInfoTableCell order={props} can_modify />
        ),
        label: "Servicio",
      },
      {
        id: "track_guide",
        renderComponent: (props) => <PaymentButton order={props} />,
        label: "Crear guía",
      },
    ],
    []
  );
};
