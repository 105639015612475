import React, { useMemo } from 'react'
// import MuiTablePagination, {
//     TablePaginationProps as Props,
// } from '@mui/material/TablePagination'
import { Stack } from '@mui/material'
import MuiTablePagination, { PaginationProps } from '@mui/material/Pagination'

import { PeerPage } from 'commons/peer-page'

const TablePagination = ({
    pagination,
    handleChange,
    listOrdersQuery,
}: any) => {
    const paginationStyles = useMemo<PaginationProps["sx"]>(
        () => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            "& .Mui-selected": {
                color: "#000 !important",
                fontWeight: "bold !important",
                borderColor: "#fff !important",
                backgroundColor: "rgba(145, 158, 171, 0.16) !important",
            },
        }),
        []
    );
    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            px={1}
            padding={'10px'}
        >
            <PeerPage
                size={pagination.peerPage}
                onChange={handleChange("peerPage")}
            />
            <MuiTablePagination
                count={listOrdersQuery.data?.totalPages ?? 1}
                page={parseInt(pagination.page)}
                onChange={handleChange("page")}
                variant="outlined"
                dir='ltr'
                sx={paginationStyles}
            />
        </Stack>
    )
}

export default TablePagination

