import React, { useMemo } from "react";
import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";

type Props = {
  handleModal: () => void;
  titleButton: string;
  title: string;
  warningInput: string;
  icon: string;
  isforQuote?: boolean;
};

export const EmptyButtonSenders = ({
  handleModal,
  titleButton,
  title,
  warningInput,
  icon,
  isforQuote
}: Props) => {
  const textFiledStyle = useMemo(
    () => ({
      fontSize: "12px!important",
      color: "#677787",
    }),
    []
  );
  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
         <Box
          sx={{
            width: isforQuote? '5%': 32,
            mr: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
            <img src={icon} alt="icono de campos pendientes por llenar" />
        </Box>
        <Box
          sx={{
            width: isforQuote? "70%": "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "left"
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "left" }}> {title}</Typography>

            <Typography
              sx={{
                ...textFiledStyle,
                textAlign: "left"
              }}
            >
              {warningInput}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "10rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              width: "100%"
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={handleModal}
            >
              {titleButton}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
