import * as Yup from "yup";

const nationalConsigneeSchema = Yup.object({
  postalCode: Yup.string().required("este campo es requerido"),
  countryCode: Yup.string().required("Campo obligatorio: Código País"),
});

const internationalConsigneeSchema = Yup.object({
  countryCode: Yup.string().required("Campo obligatorio: Código País"),
});

const baseSchema = {
  shipper: Yup.object({
    postalCode: Yup.string().required("Este campo es requerido"),
    countryCode: Yup.string().required("Campo obligatorio: Código País"),
  }),
  declaredValue: Yup.number().min(1, "El valor declarado debe ser mayor a 0"),
  pieces: Yup.array().min(1, "Debes agregar minumo un paquete a enviar"),
  weight: Yup.number().min(0.1, "Debe ser valores desde 0.1"),
  width: Yup.number().min(0.1, "Debe ser valores desde 0.1"),
  height: Yup.number().min(0.1, "Debe ser valores desde 0.1"),
  depth: Yup.number().min(0.1, "Debe ser valores desde 0.1"),
};

export const shippmentSchema = (isNational) => {
  if (isNational) {
    return Yup.object({ ...baseSchema, consignee: nationalConsigneeSchema });
  }

  return Yup.object({ ...baseSchema, consignee: internationalConsigneeSchema });
};
