import React, { useState, useCallback, useEffect } from "react";
import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { Dashboard } from "components/templates/page-private-dashboard";
import { getUserAddressBookList, getAddressById, deleteAddress } from "services/serviceV2/addressBook";
import { useLocation, useHistory } from "react-router-dom";
import { SubHeader } from "components/molecules/sub-header";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Stack,
  Grid
} from "@mui/material";
import type { ShipperProps } from "components/molecules/order-forms/types";
import { Add } from "@mui/icons-material";
import { TabPanel } from '../components/TabPanel';
import { TabSource, DataDocsDto, AddressDTO } from '../types';
import { ADDRESS_TYPE } from 'enums/address';
import { usePagination } from "hooks/v2/useGenerycalPagination";
import { AddressBookForm } from 'components/organisms/address-book-form';

const tabSources: TabSource[] = [
  {
    addressType: ADDRESS_TYPE.SHIPPER,
    tableName: 'Origen',
    dataMapping: {
      person_name: "Nombre de contacto",
      company_name: "Companía",
      is_default_address: false,
      address_line: "Dirección",
      city: "Ciudad",
      // state: "Estado / Provincia",
      postal_code: "Código postal",
      country_name: "País",
    }
  },
  {
    addressType: ADDRESS_TYPE.CONSIGNEE,
    tableName: 'Destino',
    dataMapping: {
      person_name: "Nombre de contacto",
      company_name: "Companía",
      address_line: "Dirección",
      city: "Ciudad",
      state: "Estado / Provincia",
      postal_code: "Código postal",
      country_name: "País",
    }
  }
]


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const initialValues: ShipperProps = {
  address_line: "",
  document_number: "",
  address_type: ADDRESS_TYPE.SHIPPER,
  city: "",
  country_name: "",
  country_code: "",
  company_name: "",
  postal_code: "",
  state: "",
  email: "",
  person_name: "",
  phone_number: "",
  dane_code: "",
  is_default_address: false,
  _id: undefined
};

export const AddressBookPage = () => {
  const queryClient = useQueryClient();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [ showForm, setToggleShowForm ] = useState(false);
  const [ currentAddressForEdit, setCurrentAddressForEdit ] = useState<ShipperProps>(initialValues);
  const history = useHistory();
  const [pagination] = usePagination();
  const currentType = searchParams.get('tab') || ADDRESS_TYPE.SHIPPER;
  const currentTabIndex = tabSources.findIndex((currentTab) => currentTab.addressType === currentType);
  const { data }: UseQueryResult<DataDocsDto> = useQuery(
     ["address-book", currentType, pagination.page, pagination.limit],
     () => getUserAddressBookList(currentType, parseInt(pagination.page), parseInt(pagination.limit))
    );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
      history.push(history.location.pathname + "?tab=" + tabSources[newValue].addressType);
    };

  const setToggleForm = useCallback(
    () => {
      setToggleShowForm(prev => !prev)
    },
    [showForm]
  );

  const openAddAddressModal = useCallback(
    () => {
      setCurrentAddressForEdit(() => initialValues);
      setToggleForm()
    },
    [currentAddressForEdit]
  );
  
  const handleSetEdit = useCallback(
    async( addressId: string ) => {
      const data = await getAddressById(addressId);
      await setCurrentAddressForEdit(data);
      setToggleForm();
    },
    [currentAddressForEdit]
  );

  const handleAskDelete = ( addressId: string ) => {
    deleteAddress(addressId)
    .then(() => {
      queryClient.invalidateQueries({ queryKey: ["address-book"] });
    })
    .catch((error) => {
      console.error(error);
    })
  }

  useEffect(() => {
}, [currentAddressForEdit]);

  return (
    <>
      <Dashboard>
       <Grid container flexDirection="column" pr="15px !important">
        <SubHeader />
        <Box
          sx={{
            marginTop: "1rem",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography variant="h3" sx={{ fontSize: '1.75rem!important' }}>
              Mis Direcciones
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTabIndex} onChange={handleChangeTab} sx={{ color: "#7e00fb" }} aria-label="basic tabs example">
          {tabSources.map((currentTab: TabSource, index) =>  <Tab key={index} sx={{
                  fontSize: "14px!important",
                }} label={currentTab.tableName} {...a11yProps(index)} /> )}
          <Stack sx={{ 
            width: '100%',
            alignItems: 'end',
            justifyContent: 'center'
           }}>
            <Button onClick={openAddAddressModal} size={"medium"} variant="contained" startIcon={<Add />}>
              AGREGAR
            </Button>
          </Stack>
        </Tabs>
      </Box>
        {data?.docs && tabSources.map((currentTab: TabSource, index) => 
          <TabPanel 
            data={data.docs} 
            pagination={{ 
              limit: data.limit,
              page: data.page,
              status: data.status,
              total: data.total,
              totalPages: data.totalPages 
            }} 
            key={index} 
            value={currentTabIndex} 
            actions={[
              {
                handle: handleSetEdit,
                type: 'edit',
                title: 'Editar',
              },
              {
                handle: handleAskDelete,
                type: 'delete',
                title: 'Borrar',
              }
            ]}
            tabSource={currentTab}  
            index={index} />
            )}
        </Grid>
      </Dashboard>

      {currentAddressForEdit &&
        <AddressBookForm showForm={showForm} currentAddress={currentAddressForEdit!} setToggleForm={setToggleForm} />
      }
    </>
    
  );
};
