import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Stack,
  Typography,
} from "@mui/material";
import { SubHeader } from "components/molecules/sub-header";
import { ApiService } from "../../../services";

import { Dashboard } from "components/templates/page-private-dashboard";
import { DraftShipments } from "../components/draftShipmentsTable";
import { TabPanel } from "../components/tabPanel";

import { CompletedShipments } from "../components/completedShipmentsTable";
import { useQueryString } from "hooks/v2/useQueryString";
import { useHistory, useLocation } from "react-router-dom";
import { ShopifyBanner } from "components/organisms/shopify-banner";

enum TabsEnum {
  PENDING = 0,
  COMPLETED = 1,
}

enum TabsMap {
  PENDING = "pending",
  COMPLETED = "completed",
}

export const OrdersPage = () => {
  const [search] = useQueryString();
  const history = useHistory();
  const location = useLocation();
  const mapTab: Record<string, number> = {
    [TabsMap.PENDING]: TabsEnum.PENDING,
    [TabsMap.COMPLETED]: TabsEnum.COMPLETED,
  };
  const currentSearchTab: string = (search?.tab || TabsMap.PENDING) as string;
  const currentTab: number = mapTab[currentSearchTab] ?? TabsEnum.PENDING;
  const [, setValue] = useState(currentTab);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    let shopData = null;
    if((search.access_token && search.access_token !== null) && (search.shop && search.shop !== null)) {
      sessionStorage.setItem('access_token', search.access_token as string);
      sessionStorage.setItem('shop', search.shop as string);
      queryParams.delete('access_token');
      queryParams.delete('shop');
      history.replace({
        search: queryParams.toString(),
      })
      shopData = {
        shop: search.shop,
        access_token: search.access_token
      }
    }
    const fetchMatchData = async (shopifyData: any) => {
      if(shopifyData && shopifyData !== null) {
        await ApiService.AuthService.shopifyMatchAuthenticated({ shop: sessionStorage.getItem('shop'), accessToken: sessionStorage.getItem('access_token')});
      }
    }
    fetchMatchData(shopData).catch(console.error);

    }, [search, history, location]
  );


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabsMap: Record<number, string> = {
      [TabsEnum.PENDING]: TabsMap.PENDING,
      [TabsEnum.COMPLETED]: TabsMap.COMPLETED,
    };
    history.push(history.location.pathname + "?tab=" + tabsMap[newValue]);
    setValue(newValue);
  };

  return (
    <Dashboard>
      <Grid container flexDirection="column" pr="15px !important">
        <SubHeader showSubtitle={true} />
        <Divider />
        <Box
          sx={{
            marginTop: "1rem",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <ShopifyBanner />
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography variant="h3" sx={{ fontSize: "1.75rem!important" }}>
              Mis Ordenes
            </Typography>
          </Stack>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ color: "#7e00fb", mx: 0 }}
            >
              <Tab
                label="Mis ordenes Pendientes"
                sx={{
                  fontSize: "14px!important",
                  marginRight: "1rem !important",
                }}
              />

              <Tab
                label="Envíos Realizados"
                sx={{
                  fontSize: "14px!important",
                }}
              />
            </Tabs>
          </Box>

          <TabPanel key={0} value={currentTab} index={0}>
            <DraftShipments />
          </TabPanel>

          <TabPanel key={1} value={currentTab} index={1}>
            <CompletedShipments />
          </TabPanel>
        </Box>
      </Grid>
    </Dashboard>
  );
};
