import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { 
  ListItem,
  List,
  Button,
  Chip
 } from "@mui/material";

export const SideLink = ({ links, onboardingItems, handleOpen }) => {
  const location = useLocation();
  useEffect(() => {
  }, [location]);
  return (
    <List key={'SideLinkList'}>
      {links.map((link, index) => (
        <ListItem key={index}>
          <Button 
            variant="text"
            fullWidth 
            type="button" 
            sx={
              link.path.split('/')[0] !== 'https:' && location.pathname.split('/')[1] === link.path.split('/')[1]?
              {
                color: '#fff',
                background: '#7E00FB',
                transition: '0.4s',
                ':hover':{
                  background: '#03030314',
                  color: '#7E00FB'
                },
                justifyContent: 'start!important'
              }: {
              color: '#637381',
              transition: '0.4s',
              ':hover':{
                background: '#03030314',
                color: '#7E00FB'
              },
              justifyContent: 'start!important'
            }}
            href={link.path}
            >
              {link.name}
            </Button>
        </ListItem>
        ))}
        {onboardingItems && onboardingItems.length &&
          <ListItem key={'onboarding'}>
          <Button 
            variant="text"
            fullWidth 
            type="button" 
            sx={{
              color: '#637381',
              transition: '0.4s',
              ':hover':{
                background: '#03030314',
                color: '#7E00FB'
              },
              justifyContent: 'start!important'
            }}
            onClick={handleOpen}
            >
              Actualizaciones 💜 
            </Button>
        </ListItem>
        }
        <ListItem key={'linktoshopify'}>
          <Button 
            variant="text"
            fullWidth 
            type="button" 
            sx={{
              color: '#637381',
              transition: '0.4s',
              ':hover':{
                background: '#03030314',
                color: '#7E00FB'
              },
              justifyContent: 'start!important'
            }}
            href={'https://shipal.co/integracion-con-shopify/'}
            target='_blank'
            >
              Shopify  <Chip label="Nuevo!" variant="outlined" color="success" size="small" sx={{ ml: 'auto' }}/>
            </Button>
        </ListItem>
    </List>
  );
};
