//imports
import React , { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import {
  BtnLogout,
  SectionWelcome,
  SideBarContainer,
} from "./styles";
import AddIcon from '@mui/icons-material/Add';
import { 
  Box,
  Divider,
  Alert,
  Typography,
  List,
  ListItem,
  Button,
  Modal,
  Card,
  Grid,
  IconButton
} from "@mui/material";
import { currentFormat } from "utils/convert";
import Carousel from 'react-material-ui-carousel';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Components
import { sideLinks } from "./side-links";
import { SideLink } from "../../atoms/side-link";
import { AppStore } from "../../../store";
import { getToken } from "store/auth/auth.selectors";
import { FormPayBalance } from "../../templates/form-pay-balance";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// API, REDUX, HELPERS & UTILS
import { logout } from "../../../store/auth/auth.actions";
import { getUserData, getAuth } from "../../../store/auth/auth.selectors";
import { useModal } from "../../../hooks/useModal";
import { Loading } from "../../atoms/loading";
import { shipalLogo6 } from "assets/imgs";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ApiService } from "../../../services";

export const SideMenuMain = () => {
  const reduxState = AppStore.getState();
  const authToken =  getToken(reduxState)
  const { REACT_APP_SUPPORT_PHONE_NUMBER } = process.env;
  const dispatch = useDispatch();
  const [show, onToggle, ContainerModal] = useModal(false);
  const user = useSelector(getUserData);
  const [onboardingItems, setOnboardingItems] = useState([]);
  const auth = useSelector(getAuth);
  const [open, setOpen] = React.useState(!user?.onboardingViewed);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const oi = async () => ApiService.AuthService.onboarding();
    oi().then(response => {
      if(response.data.data){
        setOnboardingItems(response.data.data);
      }
    }).catch(error => {
      console.error(error);
    })
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };
  if (!auth) {
    handleLogout();
  }
  if (!user) return <Loading />;

const closeAndRemove = async () => {
  const userUpdated = await ApiService.AuthService.updateMe({ onboardingViewed: true });
  console.log("userUpdated");
  console.log(userUpdated);
  setOpen((previus) => !previus);
}

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    p: 0,
  };

  return (
    <Col
      xs="12"
      md="2"
      className="p-0"
      key={'SudeMenuMainContainer'}
      style={{ height: "100%", maxWidth: "inherit", overflowY: 'auto' }}
    >
      
      <Modal
        key={'sudeMenucontainerModal'}  
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={styleModal}>
          <Carousel
          autoPlay={false}
          animation='slide'
          swipe={true}
          navButtonsAlwaysVisible={true}
          navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
              style: {
                  backgroundColor: '#7E00FB'
              }
          }} 
          navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
              style: {
                bottom: '0',
                height: '64px',
                top: 'unset',
                padding: 16
              }
          }} 
          NavButton={({onClick, className, style, next, prev}) => {
    
          return (
                <Button type="button" variant="contained"  onClick={onClick} className={className} style={style}>
                    {next && "Siguiente"}
                    {prev && "Anterior"}
                </Button>
            )
          }}
          >
          {
                onboardingItems.map( (item, i) =>
                <Box key={i}>
                  <IconButton
                    key={'iconClose'}
                      sx={{
                        color: '#000000',
                        opacity: '50%',
                        position: 'absolute',
                        right: 0
                        }} 
                      onClick={() => closeAndRemove()}
                    aria-label="fingerprint" color="secondary">
                    <CloseOutlinedIcon />
                  </IconButton>
                  <Grid key={'containerObModal'} container padding={0}>
                    <Grid key={'log'} xs={12} sx={{ minHeight: '450px' }} item>
                      <img src={item.uri_gif} width={'100%'} sx={{ minHeight: '450px' }} alt={item.uri_gif} />
                    </Grid>
                    <Grid key={'logdesc'} xs={12} padding={4} textAlign={'center'} sx={{ minHeight: '16px' }} item>
                      <Typography key={'logdesctitle'} id="modal-modal-title" variant="h4" color={'#7E00FB'} component="h2">
                        {item.title}
                      </Typography>
                      <Typography key={'logdesccontent'} id="modal-modal-title" variant="body2" component="h2">
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                )
            }
          </Carousel>
        </Card>
      </Modal>

      <SideBarContainer key={'sudeMenucontainer'} sx={{ position: 'relative' }}>
        <Box key={'firstBox'} textAlign={'center'} padding={2}>
          <Link to="/shipments/realizados" key={'logoSipal'} >
            <img src={shipalLogo6} alt="logo-shipal" heigth="120" style={{padding: "16px"}} />
          </Link>
          <p key={'available'} style={{ marginBottom: "2px" }}>
            <b>
              Saldo disponible:
            </b>
          </p>
          <p key={'availablevalue'} style={{ marginBottom: "12px" }} >
            {currentFormat(user.balanceAvailable || 0)}
          </p>
          {user.isActive ? (
            <div key={'addMoney'}>
              <Button variant="outlined" onClick={() => onToggle()}>
                <AddIcon /> Abonar saldo
              </Button>
            </div>
          ) : (
            <p key={'unactiveAccount'}>Tu usuario no esta habilitado. Comunicate con soporte.</p>
          )}
        </Box>
        <Divider key={'dividerSidelinks'}  sx={{ width: '100%' }} />
        {user.isActive && <SideLink key={'sideLinks'} user={user} links={sideLinks(authToken)} handleOpen={handleOpen} onboardingItems={onboardingItems}  />}

        
        <Box key={'secondBox'} sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <List key={'AfterlinksList'}>
          {!user.isActive &&
            <ListItem key={'unactiveAccountKey'}>
                <Alert sx={{ background: "red", color: '#fff' }} variant="solid" icon={<InfoOutlinedIcon fontSize="inherit" />} color="danger">Cuenta inactiva</Alert>
            </ListItem>
          }
            <ListItem key={'supportButton'}>
              <Button 
                startIcon={<WhatsAppIcon />}
                variant="text"
                fullWidth 
                type="button"
                sx={{
                  background: '#25D366',
                  color: '#fff',
                  ':hover':{
                    background: '#03030314',
                    color: '#7E00FB'
                  }
                }}
                href={`https://wa.me/${REACT_APP_SUPPORT_PHONE_NUMBER}`}
                target="_blank"
                >
                  <Typography variant="body2">
                    Contactar con soporte
                  </Typography>
                </Button>
            </ListItem>
            <Divider key={'aftersideitemsmenudivider'} sx={{ width: '100%' }} />
            <ListItem key={'logout'} sx={{ background:'#f8f8f8'}}>
              <BtnLogout regular purple onClick={handleLogout}>
                <FiLogOut /> Cerrar Sesión!
              </BtnLogout>
            </ListItem>
        </List>
        </Box>
      </SideBarContainer>
      {show && (
        <ContainerModal
          key={'sudeMenucontainerBlance'}
          size="sm"
          scrollable={true}
          centered={true}
          animation={true}
          typeModal="information"
        >
          <FormPayBalance onToggle={onToggle} />
        </ContainerModal>
      )}
    </Col>
  );
};
