import {Card} from 'react-bootstrap';
import styled from 'styled-components';

export const CardStyled = styled(Card)`
${(props) => props.selected && `border: 2px solid #993694;`}
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  width: 12rem;
  height: 10rem;
  text-align: center;
  cursor: pointer;
`