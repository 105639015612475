import { Cart_Item_Information } from "components/molecules/order-forms/types";
import React, { useState } from "react";
import {
  CardContent,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currentFormat } from "utils/convert";

type Props = {
  data: Cart_Item_Information[];
  orderNumber?: number;
  shopifyDeclaredValue?: number;
};

export const ItemsCard = ({ data, orderNumber, shopifyDeclaredValue = 0 }: Props) => {
  const [ expanded, setSpanded ]  = useState(true);

  if (!data.length) return null;

  return (
    <CardContent sx={{ width: '100%', p: 2 }}>
      <Accordion expanded={expanded} onChange={() => setSpanded((prevExpanded) => !prevExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography variant="h4" sx={{fontSize: "18px !important", width: '100%' }}>{`Resumen de la orden | Número de orden Shopify: ${orderNumber}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Cantidad</TableCell>
                <TableCell align="center">Descripción</TableCell>
                <TableCell align="left">Peso</TableCell>
                <TableCell align="left">Valor c/u</TableCell>
                <TableCell align="left">Valor total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  key={item?.shopify_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" width={2} scope="row">
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{item.quantity}</Typography>               
                  </TableCell>
                  <TableCell align="left" scope="row">
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{item.name}</Typography> 
                  </TableCell>
                  <TableCell align="center" width={2} scope="row">
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{item.weight}</Typography> 
                  </TableCell>
                  <TableCell align="left" scope="row">
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{currentFormat(parseFloat(item.price))} ({item.currency_code})</Typography> 
                  </TableCell>
                  <TableCell align="left" scope="row">
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{currentFormat(parseFloat(item.total_value))} ({item.currency_code})</Typography> 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
                <TableRow
                  key={'total'}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                  <TableCell align="center" scope="row">
                  </TableCell>
                  <TableCell align="left" scope="row">
                  </TableCell>
                  <TableCell align="center" scope="row">
                  </TableCell>
                  <TableCell align="center" scope="row">
                    <Typography variant="h4" sx={{ textAlign: "center" }}> Valor total:</Typography>               
                  </TableCell>
                  <TableCell align="center" scope="row">
                    <Typography variant="h4" sx={{ textAlign: "center" }}>{currentFormat(parseFloat(shopifyDeclaredValue.toString()))}</Typography>               
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" sx={{ textAlign: 'right', width: '100%' }}>Recuerda, nos basamos en la configuración de tu tienda para mostrar la moneda, <Link href="https://help.shopify.com/es/manual/payments/shopify-payments/selling-and-getting-paid-in-different-currencies#:~:text=Desde%20tu%20panel%20de%20control,Haz%20clic%20en%20Guardar.">Como cambiarlo?</Link></Typography>
        </AccordionDetails>
      </Accordion>
    </CardContent>
  );
};
