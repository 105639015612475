import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import LoadingButton from '@mui/lab/LoadingButton';
import { createGuide } from "services/serviceV2/orders";
import { validateUserBalance } from "services/serviceV2/wallet";
import { PaymentError } from "commons/paymentError";
import { checkCompletedDueDiligence } from "utils/dueDiligenceCheck";
import { CreateLabelError } from "services/serviceV2/orders/types";
import { PaymentAds, BalanceValidationData } from "components/organisms/payment-advertisements";

type MutationError = {
  status: number;
  listErrors: string[];
};

interface Props {
    order: any;
}

export const PaymentButton = ({ order }: Props) => {
  const [ error, setError ] = useState<CreateLabelError>();
  const [ IsOnError, setIsOnError ] = useState(false);
  const [ selectedOrderId, setSelectedOrderId ] = useState('');
  const [ dataBalanceValidated, setDataBalanceValidated ] = useState<BalanceValidationData>();
  const [valueToPay, setValueToPay] = useState<null | number>(null);
  const [ isLoading, setIsLoading ] = useState("");
  const [ isLoadingPayment, setIsLoadingPayment ] = useState(false);
  const [isOpenAdvPayment, setIsOpenAdvPayment] = useState(false);
  const handleOpenModal = () => setIsOpenAdvPayment(!isOpenAdvPayment)
  const history = useHistory();

  const validateCurrentBalance = useCallback(
    async (orderId: string, balance: number) => {
      setIsLoading(orderId);
      setSelectedOrderId(() => orderId);
      const currentBalanceValidation = await validateUserBalance(balance);

        setDataBalanceValidated(currentBalanceValidation?.data?.data);
        handleOpenModal();
    },
    [selectedOrderId, isLoading, isLoadingPayment]
  );
  
  const closeModal = () => {
    setIsLoading(() => "");
    handleOpenModal();
  }
  const { mutate } = useMutation<void, MutationError, string>(
    (id: string) => createGuide(id),
    {
      onSuccess: (_, id) => {
        setIsLoading("");
        setIsLoadingPayment(false);
        return history.push(`/order/result/${id}`);
      },
      onError: (errordata: CreateLabelError) => {
        setIsLoading("");
        console.log(errordata)
        setError(errordata);
        setIsLoadingPayment(false);
        setIsOnError(true);
      }
    }
  );

  const handlePay = useCallback(
    (id: string) => {
      setIsLoadingPayment(true);
      setIsLoading(id);
      mutate(id);
    },
    [mutate, isLoading, isLoadingPayment]
  );

  return (
    <>
     <LoadingButton
                        type="button"
                        variant="contained"
                        loading={isLoading === order._id}
                        onClick={() =>
                          validateCurrentBalance(order._id, order.total_payment)
                        }
                        disabled={!checkCompletedDueDiligence(order.due_diligence) ||
                          !Boolean(order.total_payment)}
                      >
                        Pagar
                      </LoadingButton>
                      
    <PaymentAds open={isOpenAdvPayment} isLoadingPayment={isLoadingPayment} handleClose={closeModal} handleConfirm={() => handlePay(selectedOrderId)} balanceData={dataBalanceValidated} />
      
    {IsOnError && <PaymentError total={valueToPay} error={error} />}
    </>
  );
};
