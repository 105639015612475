// Import
import React from "react";
import { StyledNoteBookDiv } from "./styles";
import { SubHeader } from "components/molecules/sub-header";
import { Grid, Box, Stack, Typography } from "@mui/material";
// Components
import { Dashboard } from '../../../templates/page-private-dashboard';
import { BillingInformation } from "../../../organisms/account-billing-information";

// API
 
export const MyPayments = () => (
      <Dashboard>
        <Grid container flexDirection="column" pr="15px !important">
            <SubHeader showSubtitle={true} />
            <Box
              sx={{
                marginTop: "1rem",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Typography variant="h3" fontSize="1rem">
                  Billetera
                </Typography>
              </Stack>
            </Box>
            <StyledNoteBookDiv>
            <BillingInformation />
          </StyledNoteBookDiv>
        </Grid>
    </Dashboard>
);
