import { types } from "../../types"

const initialState = {
    isLoading: false,
    orders: [],
}

export const ordersReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case 'orderSetIsLoading':
            return {
                ...state,
                isLoading: payload
            }

            case types.getAllOrders:
                return {
                    ...state,
                    orders: payload
                }

        default:
            return state
    }
}
