import { Card, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CardCustom } from '../../atoms/card';

export const ListMethods = styled(Row)`
`

export const CardBodyStyled = styled(Card.Body)`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: inherit;
p{
    margin: 0px;
    padding: 0px;
}
`

export const StyledCardCustom = styled(CardCustom)`
width: inherit;
&:hover{
    border: 1px solid purple;
}
&:focus {
    border: 1px solid purple;
}
@media (max-width: 500px){
    width: 100%;
    margin: 0.5rem auto;
}
`

export const ContainerDecriptions = styled.div`
margin-top: 1rem;
p{
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.500);
}
`

export const ContainerMethods = styled(Card)`
width: inherit;
margin-top: 1rem;
padding: 1rem;
border: 1px solid #DEE2E6;
border-radius: 10px;
background-color: #FCFCFC;
overflow: hidden;
`

export const ContainerStyled = styled.div`
max-width: 100%;
width: 80%;
@media (max-width:500px ){
    width: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
}
`