import { Due_diligenceProps } from "components/molecules/order-forms/types";

export const checkCompletedDueDiligence = (
  dueDiligence: Due_diligenceProps
) => {
  const { export_declaration_required, ...rest } = dueDiligence;

  const keys = Object.keys(rest) as Array<keyof typeof rest>;

  for (const key of keys) {
    if (
      !export_declaration_required &&
      key === "export_declaration_completed"
    ) {
      continue;
    }
    if (!dueDiligence[key]) {
      return false;
    }
  }
  return true;
};
