// Imports
import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ColSidebar,
  ColContent,
  MainPhoneHeader,
  MainPhoneButton,
  MainPhoneSider,
  MainRow,
  DisabledDiv,
} from "./styles";

import { SideMenuMain } from "../../organisms/side-menu-main";
import { PageTitle } from "../../molecules/page-title";
import { Logo } from "../../atoms/logo";
import { getUserData } from "../../../store/auth/auth.selectors";
import logo from "../../../assets/imgs/shipal-logo4.png";

export const Dashboard = ({
  children,
  PageName = null,
}) => {
  const user = useSelector(getUserData);
  const [open, setOpen] = React.useState(false);

  return (
    <Container fluid>
      <MainRow>
        <ColSidebar open={open}>
          <MainPhoneSider open={open}>
            <MainPhoneHeader>
              <MainPhoneButton onClick={() => setOpen(!open)} type="button">
                x
              </MainPhoneButton>
            </MainPhoneHeader>
            {user.isActive && (<SideMenuMain />)}
          </MainPhoneSider>
        </ColSidebar>
        <ColContent>
          <MainPhoneHeader>
            <MainPhoneButton onClick={() => setOpen(!open)} type="button">
              <Logo srcUrl={logo} alt="logo-shipal" heigth="44" />
            </MainPhoneButton>
          </MainPhoneHeader>
          {PageName && <PageTitle title={PageName} />}
          {user.isActive ? (
            children
          ) : (
            <DisabledDiv>
              <h3>Cuenta deshabilitada:</h3>
              <p>{`${user.firstName} ${user.lastName} tu usuario ha sido desactivada. Si requiere de ayuda por favor pongase en contacto con soporte.`}</p>
            </DisabledDiv>
          )}
        </ColContent>
      </MainRow>
    </Container>
  );
};
