// Imports
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CurrencyFormat from "react-currency-format";
import DynamicDataTable from "@langleyfoxall/react-dynamic-data-table";
import { StyledTitle2, StyledSubtitle, StyledTableWrapper } from "./styles";
// Components
import { Loading } from "../../atoms/loading";
import { ApiService } from "../../../services";
import { getUserData } from "../../../store/auth/auth.selectors";

export const BillingInformation = () => {
  const dispatch = useDispatch();
  const { _id, balanceAvailable } = useSelector(getUserData);

  const tableColumnNames = {
    AddressType: "Tipo",
    CompanyName: "Banco",
    AddressLine1: "Monto",
    City: "Fecha",
  };
  const loading = false;
  const reformatValueByField = (field, value, row) => {
    switch (field) {
      case "Contact":
        return value.PersonName;
      default:
        return value;
    }
  };
  const [envios, setEnvios] = React.useState();
  useEffect(() => {
    dispatch({
      type: "test",
      success: {
        id: "custom",
      },
    });
    test();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const test = async () => {
    try {
      const {
        data: { data },
      } = await ApiService.WalletService.getWallet(_id);

      const setStructure = data.motionHistory.map((trans, index) => ({
        key: index,
        balance: (
          <CurrencyFormat
            value={trans.balance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            suffix=" COP"
          />
        ),
        bank: trans.bank,
        type: trans.type === "CRED" ? "Abono" : "Debito",
        date: new Date(trans.date).toLocaleString(),
      }));
      const ordered = setStructure.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      setEnvios(ordered);
    } catch (error) {
      console.log(error);
    }
  };
  if (!envios) return <Loading />;

  return (
    <div style={{ padding: "1em" }}>
      <StyledTitle2 className="my-4">
        Todas las transacciones
        {balanceAvailable && `, tu balance actual esta en: ${balanceAvailable}`}
      </StyledTitle2>
      <StyledSubtitle>
        Busque el filtro y expórtelo en cada compra de etiqueta, recargo, cuota
        o reembolso.
      </StyledSubtitle>
      <StyledTableWrapper
        style={{
          borderColor: "#7E00FB",
          color: "#7E00FB",
          backgroundColor: "white",
          border: "0px solid",
          borderRadius: "5px",
        }}
      >
        <DynamicDataTable
          className="table table-sm"
          rows={envios}
          buttons={[]}
          allowOrderingBy={["CompanyName", "CountryName"]}
          orderByField={"CompanyName"}
          orderByDirection={"asc"}
          fieldsToExclude={[
            /_?id/,
            "key",
            "createdAt",
            "updatedAt",
            "PostalCode",
            "CountryName",
            "StreetName",
            "BuildingName",
            "StreetNumber",
            "LocationType",
            "AddressLine2",
          ]}
          fieldMap={tableColumnNames}
          dataItemManipulator={reformatValueByField}
          noDataComponent={
            <div
              style={{
                padding: "1em",
              }}
            >
              <h5>
                Aquí podrás revisar tu directorio de destinatarios y remitentes.
              </h5>
            </div>
          }
          loading={loading}
          loadingComponent={<Loading title="Cargando..." />}
        />
      </StyledTableWrapper>
    </div>
  );
};
