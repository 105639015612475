import { QuoteProps } from "services/serviceV2/types";
import { CardCarrier } from "components/molecules/order-components/cheapestOptions";
import { Due_diligenceProps, fullFormProp } from "components/molecules/order-forms/types";
type keyTypeDue_diligence = keyof Due_diligenceProps;

const VALIDATOR_VALUES_BY_COURIER: keyTypeDue_diligence[] = [
  "consignee_completed",
  "shipper_completed",
  "minimum_items_filled",
];

export const validationDueDiligenceByCouriers = (
  data: Partial<fullFormProp>
) => {
  let validationFlag = false;
  VALIDATOR_VALUES_BY_COURIER.forEach((validation) => {
    if (
      data.due_diligence &&
      data.due_diligence[validation] &&
      data.due_diligence[validation] === true
    ) {
      validationFlag = true;
    } else {
      validationFlag = false;
      return;
    }
  });

  return validationFlag;
};

export const getBodyQuote = (data: Partial<fullFormProp>): QuoteProps => {
    return {
      shipper: {
        city: data.shipper?.city!,
        countryCode: data.shipper?.country_code!,
        countryName: data.shipper?.country_name!,
        postalCode: data.shipper?.postal_code!,
      },
      consignee: {
        city: data.consignee?.city!,
        countryCode: data.consignee?.country_code!,
        countryName: data.consignee?.country_name!,
        postalCode: data.consignee?.postal_code!,
      },
      declaredValue: String(data.declared_value),
      national: !data.is_international_shippment!,
      // type: data.package_type!,
      pieces: data.package_information!.map((item_information) => {
        return {
          depth: String(item_information.depth!),
          height: String(item_information.height!),
          weight: String(item_information.weight!),
          width: String(item_information.width!),
          PackageType: item_information.package_type!,
          pieceID: item_information.piece_ID!,
          // value: String(item_information.value!),
        };
      }),
    };
  };

export const selectedEconomicCarrier = (carriers: CardCarrier[]) => {
  const selected = carriers.sort(
    (a, b) => Number(a.totalPrice) - Number(b.totalPrice)
  )[0];

  return selected;
};
