import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FieldErrors, FieldValues, useFormContext } from "react-hook-form";
import type { ShipperProps, ConsigneeProps, fullFormProp } from "components/molecules/order-forms/types";
import { labelsSelectByCountries } from "components/molecules/order-components/addressInfoCard/dictionary";
import { getCountries, getCities } from "services/serviceV2/orders";
import { labelsSelectByCities } from "components/molecules/order-components/addressInfoCard/dictionary";
import { CountriesStatesSelector } from "components/molecules/countries-states-selector";
import { saveSetValueBySelectMultiValue } from "modules/draft/utils/general";
import { styles } from "components/molecules/order-components/styles";
import { InputSelect } from "commons/input-select";
import {
  Typography,
  Box,
  Divider,
  MenuItem,
} from "@mui/material";
import { InputText } from "commons/input-text";
import { ADDRESS_TYPE } from 'enums/address';
import { SHIPPING_TYPE } from "enums/shipping";

interface Props {
  addressType: ADDRESS_TYPE;
  methods: any;
  isForQuote?: boolean;
  id?: string;
  formData?: fullFormProp;
  isforAddressBook?: boolean
}

type MyFieldErrors = FieldErrors<ShipperProps | ConsigneeProps> & {
  [key: string]: FieldValues | undefined;
};

export const AddressBookInfoForm = ({ addressType, methods, isForQuote, id = "", formData, isforAddressBook }: Props) => {
  const { setValue, getValues } = useFormContext<ShipperProps | ConsigneeProps>();
  const { formState: { errors } } = useFormContext<MyFieldErrors>();

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });
  const { data: cities } = useQuery({
    queryKey: ["cities"],
    queryFn: getCities,
  });
  const canShowPostalcode = () => {
    let can = false;
    // if(formData?.is_international_shippment){
    //   can = true;
    // } else if(!formData?.is_international_shippment){
    //   if(addressType === ADDRESS_TYPE.SHIPPER || addressType === ADDRESS_TYPE.SHIPPER_AND_CONSIGNEE){
    //     can = true;
    //   }
    //   if(isforAddressBook){
    //     can = true;
    //     if(addressType === ADDRESS_TYPE.SHIPPER){
    //       can = false;
    //     }
    //   }
    // }
    if (getValues().country_name !== "Colombia") {
      can = true;
    }
    return can;
  }
  const onCityChangeSelect = (_target: any, index: any) => {
    if (cities) {
      if (cities[index.key.slice(2)] && cities[index.key.slice(2)].cityName) {
        setValue("state", cities[index.key.slice(2)].cityName.match(/\((.*)\)/)?.pop())
      }
      setValue("dane_code", cities[index.key.slice(2)].daneCode);
      setValue("location_validation", {
        "label": "Error, datos no coinciden",
        "type": "ERROR",
        "postal_code": {
          "label": "",
          "color": "BLACK"
        },
        "city": {
          "label": "",
          "color": "BLACK"
        },
        "state": {
          "label": "",
          "color": "BLACK"
        }
      });

      saveSetValueBySelectMultiValue(
        setValue,
        index.key.slice(2),
        labelsSelectByCities,
        cities!
      );
    }
  };

  const onChangeSelectByCountry = (_target: any, index: any, isDefault: boolean) => {
    saveSetValueBySelectMultiValue(
      setValue,
      isDefault ? index.key : index.key.slice(2),
      labelsSelectByCountries,
      countries!
    );
    if (index.props.value === "Puerto Rico") {
      setValue("state", "PR");
    }
  };


  useEffect(() => {
    if (getValues().country_name && getValues().country_name.length > 0 && countries) {
      saveSetValueBySelectMultiValue(
        setValue,
        countries.findIndex((curCountry: any) => curCountry.countryName === getValues().country_name).toString(),
        labelsSelectByCountries,
        countries!
      );
    }
    if (getValues().country_name === "Puerto Rico") {
      setValue("state", "PR");
    }
    if (getValues().postal_code?.length && cities) {
      setValue("postal_code", getValues().postal_code || "");
    }
    if (getValues().dane_code?.length && cities) {
      setValue("dane_code", getValues().dane_code || "");
    }
    if (getValues().city?.length && cities) {
      if (getValues().city.match(/\((.*)\)/)?.pop()?.length) {
        setValue("state", getValues().city.match(/\((.*)\)/)?.pop())
      }
    }
  }, [getValues().country_name, cities, getValues().dane_code, getValues().postal_code]
  );

  return (
    <>
      {!isForQuote &&
        <Box sx={{ padding: 1 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>Datos de contacto</Typography>
          <Box sx={styles.modal.fieldsRow}>
            <InputText name="company_name" size="small" label="Empresa" variant="outlined" />
            <InputText name="person_name" size="small" required label="Nombre de contacto" />
          </Box>

          <Box sx={styles.modal.fieldsRow}>
            <InputText name="email" size="small" required label="Email de contacto" />
            <InputText name="phone_number" size="small" required label="Teléfono de contacto" />
          </Box>
        </Box>
      }
      <Divider sx={{ width: '100%' }} />
      <Box sx={{ padding: 1 }}>
        {!isForQuote && <Typography variant="h4" sx={{ mb: 2 }}>Detalle de dirección</Typography>}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {!isForQuote && <InputText size="small" required name="address_line" label="Dirección" />}
          <Box
            sx={{
              ...styles.modal.fieldsRow,
              gap: "5rem",
              marginBottom: 0,
            }}
          >
            {methods.getValues('country_name') === 'Colombia' ?
              <InputSelect
                name={`${id}city`}
                size="small"
                id={`${id}city`}
                label="Ciudad"
                fullWidth
                sx={{ height: "100%" }}
                InputProps={{ style: { height: "100%" } }}
                InputLabelProps={{ shrink: true }}
                onChange={onCityChangeSelect as any}
                error={getValues().location_validation?.city?.color === "RED"}
                helperText={getValues().location_validation?.city?.label || ''}
              >
                {cities?.map((city, index) => (
                  <MenuItem key={index} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
              </InputSelect> :
              <InputText
                size="small"
                id={`${id}city`}
                required
                name={`${id}city`}
                label="Ciudad"
                error={getValues().location_validation?.city?.color === "RED"}
                helperText={getValues().location_validation?.city?.label || ''} />
            }
            {canShowPostalcode() &&
              <InputText
                size="small"
                id={`${id}postal_code`}
                required
                name={`${id}postal_code`}
                disabled={methods.getValues('country_name') === 'Colombia'}
                label="Código postal"
                error={errors[`${id}postal_code`] != null}
                helperText={errors[`${id}postal_code`]?.message || ''}
              />
            }

          </Box>
          <Box
            sx={{
              ...styles.modal.fieldsRow,
              gap: "5rem",
              marginBottom: 0,
            }}
          >
            <CountriesStatesSelector
              id={id}
              isInternationalShippment={formData?.is_international_shippment}
              addressType={addressType}
              onChangeSelectByCountry={onChangeSelectByCountry}
            />
          </Box>

        </Box>

      </Box>
    </>
  );
};


