import { Card, Container, Form } from "react-bootstrap";
import styled from "styled-components";

export const ContainerHeader = styled.div`
  hr {
    margin: 0px;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
`;

export const CardStyled = styled(Card)`
  border-radius: 1.1rem;
  margin-top: 3rem;
  overflow: hidden;
  p {
    margin: 0px;
    padding: 0px;
  }
`;

export const CardHeaderStyled = styled(Card.Header)`
  background-color: #f7f7f7;
  text-align: left;
`;

export const CardBodyStyled = styled(Card.Body)`
  margin: 0;
  line-height: 1.5rem;
`;

export const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerIconEmail = styled(Container)`
  border-radius: 137px;
  box-shadow: 1px 2px #abb2b9;
  border: 1px solid #abb2b9;
  width: 3rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 0px;
`;

export const Switch = styled(Form.Switch)`
  font-weight: bold;
  color: purple;
`;

export const ContainerActive = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-weight: bold;
  color: purple;
  margin-right: 8px;
  padding: 0px;
  margin-top: 6px;
`;

export const ContainerRootStyled = styled.div``;
