import {
  ConsigneeProps,
  ShipperProps
} from "components/molecules/order-forms/types";

type onSubmitProps = {
  handleModal?: () => void;
  values: any;
  data: any;
  isShipper: boolean;
  fnSave: (
    data: any,
    attribute: string,
    values: ShipperProps | ConsigneeProps
  ) => Promise<any>;
};

export const onSubmitFormRecipmentAndSender = async ({
  data,
  fnSave,
  handleModal,
  isShipper,
  values,
}: onSubmitProps) => {
  try {
    if (handleModal) {
      handleModal();
    }

    // const dataMock = moockingDue_diligenceInData(
    //   data!,
    //   isShipper ? "shipper_completed" : "consignee_completed"
    // );

    return await fnSave(data, isShipper ? "shipper" : "consignee", values);
  } catch (error) {
    console.error(error);
  }
};

