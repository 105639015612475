import styled from "styled-components";

export const StyledNoteBookDiv = styled.div`
  padding: 5px;
  overflow: scroll;
  .table {
    margin: 1em;
    overflow: scroll;

  }
  .table-responsive{
    width: max-content;
    overflow-x: auto;
    width: auto;
  }
  .table th {
    border: none;
  }
  .table td {
    /* border-top: 1px solid #dee2e6; */
  }
`;

export const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  p {
    margin: 0px;
    padding: 0px;
    color: #686868;
  }

  img {
    width: 40%;
    height: 40%;
  }
`;
