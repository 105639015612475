import { toast, Flip } from "react-toastify";

export const showToast = (props) => {
  let autoClose = 4000;
  if (props.autoClose) {
    autoClose = props.autoClose;
  }
  const options = {
    hideProgressBar: false,
    progress: undefined,
    autoClose,
    type: props.type || "default",
    position: props.position || "bottom-right",
    toastId: props.id,
    className: props.className || undefined,
    transition: props.transition ? Flip : undefined,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
    icon: props.icon || "🚀" 
  };
  if (toast.isActive(props.id)) {
    return toast.update(props.id, { ...options, render: props.message });
  }
  return toast(props.message, options);
};
