import { Dashboard } from "components/templates/page-private-dashboard";
import { SHIPPING_TYPE } from "enums/shipping";
import { useForm } from "react-hook-form";
import FormProvider from "commons/form-provider";
import { Box } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { createOrderDraw } from "services/serviceV2/orders";
import {
  ConsigneeProps,
  Due_diligenceProps,
  Export_declarationProps,
  Package_informationProps,
  ShipalProps,
  ShipperProps,
  fullFormProp,
  Cart_Item_Information
} from "components/molecules/order-forms/types";
import {
  OrderType,
  OrderStatus,
} from "../../../services/serviceV2/orders/types";
import { SubHeader } from "components/molecules/sub-header";
import { AddressInfoCard } from "components/molecules/order-components/addressInfoCard";
import { CheapestOptions } from "components/molecules/order-components/cheapestOptions";
import { Total } from "components/molecules/order-components/total";
import { Insurance } from "components/molecules/order-components/addSegure";
import { PackageDetails } from "components/molecules/order-components/packageDetailsCard";
import { DeclareExportNational } from "components/molecules/order-components/declareExport/national";
import { DeclareExportInternational } from "components/molecules/order-components/declareExport/international";
import { ADDRESS_TYPE } from 'enums/address';
import { Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";

export const Quoter = () => {
  const [typeShipping, setTypeShipping] = useState<SHIPPING_TYPE>(SHIPPING_TYPE.NATIONAL);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [form, setForm] = useState<fullFormProp>({
    shipment_date: "",
    shipment_value: 0,
    declared_value: 0,
    total_payment: 0,
    shipper_account: "",
    is_international_shippment: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? true : false,
    contents: "",
    package_type: "",
    order_status: OrderStatus.DRAFT,
    order_type: OrderType.SHIPAL,
    invoice_number: 0,
    due_diligence: {
      consignee_completed: true,
      shipper_completed: true,
      carrier_selected: true,
      minimum_items_filled: true,
      export_declaration_required: true,
      export_declaration_completed: true,
    },
    shipper: {
      address_line: "",
      address_type: ADDRESS_TYPE.SHIPPER,
      document_number: "",
      city: "",
      country_name: "Colombia",
      country_code: "CO",
      company_name: "",
      postal_code: "0",
      state: "",
      email: "",
      person_name: "",
      phone_number: "",
      dane_code: "",
      is_default_address: false,
    },
    consignee: {
      address_line: "",
      address_type: ADDRESS_TYPE.CONSIGNEE,
      document_number: "",
      city: "",
      country_name: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? " " : "Colombia",
      country_code: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? " " : "CO",
      company_name: "",
      postal_code: "0",
      state: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? "AL" : " ",
      email: "",
      person_name: "",
      phone_number: "",
      dane_code: "",
    },
    shipal: {
      currency: "",
      insurance: false,
      company_name: "",
      need_insurance_carrier_mark: "",
      insurance_value: 0,
      selected_carrier: "",
      shipal_account: "",
      declared_currency: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? "USD" : "COP",
    },
    export_declaration: {
      export_reason: "",
      export_reason_code: "",
      inter_consignee: "N/A",
      declared_value: 0,
      invoice_date: "",
      invoice_number: 0,
      invoiceTotal_gross_weight: 0,
      invoiceTotal_net_weight: 0,
      shipment_purpose: "COMMERCIAL",
      signature_name: "N/A",
    },
    package_information: [],
    cart_items_information: []
  })
  const methods = useForm({
    mode: "onChange",
    defaultValues: form,
  });

  const handleDrawSave = async (
    data: any,
    attribute: string,
    values:
      | Partial<Due_diligenceProps>
      | Partial<ShipperProps>
      | Partial<ConsigneeProps>
      | Partial<ShipalProps>
      | Partial<Export_declarationProps>
      | Partial<Package_informationProps[]>
      | Partial<Cart_Item_Information[]>
  ) => {
    try {
      setForm({ ...data, [attribute]: values, is_international_shippment: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? true : false });
    } catch (error) {
      console.error(error);
    }
  };

  const saveAsDraft = async () => {
    setIsLoading(true);
    const draftCreated = await createOrderDraw(form);
    history.push({ pathname: `/shipping/${typeShipping}/${draftCreated._id}` });

    setIsLoading(false);
  }
  const onChangeIsinternational = useCallback((e: any, value: string) => {
    setTypeShipping(() => value as SHIPPING_TYPE);
    setForm({
      shipment_date: "",
      shipment_value: 0,
      declared_value: 0,
      total_payment: 0,
      shipper_account: "",
      is_international_shippment: typeShipping === SHIPPING_TYPE.INTERNATIONAL ? true : false,
      contents: "",
      package_type: "",
      order_status: OrderStatus.DRAFT,
      order_type: OrderType.SHIPAL,
      shopify_order_number: 0, 
      shopify_declared_value: 0,
      invoice_number: 0,
      due_diligence: {
        consignee_completed: true,
        shipper_completed: true,
        carrier_selected: true,
        minimum_items_filled: true,
        export_declaration_required: true,
        export_declaration_completed: true,
      },
      shipper: {
        address_line: "",
        address_type: ADDRESS_TYPE.SHIPPER,
        document_number: "",
        city: "",
        country_name: "Colombia",
        country_code: "CO",
        company_name: "",
        postal_code: "0",
        state: "",
        email: "",
        person_name: "",
        phone_number: "",
        dane_code: "",
        is_default_address: false,
      },
      consignee: {
        address_line: "",
        address_type: ADDRESS_TYPE.CONSIGNEE,
        document_number: "",
        city: "",
        country_name: value === SHIPPING_TYPE.INTERNATIONAL ? " " : "Colombia",
        country_code: value === SHIPPING_TYPE.INTERNATIONAL ? " " : "CO",
        company_name: "",
        postal_code: "0",
        state: "",
        email: "",
        person_name: "",
        phone_number: "",
        dane_code: "",
      },
      shipal: {
        currency: "",
        insurance: false,
        company_name: "",
        need_insurance_carrier_mark: "",
        insurance_value: 0,
        selected_carrier: "",
        shipal_account: "",
        declared_currency: value === SHIPPING_TYPE.INTERNATIONAL ? "USD" : "COP",
      },
      export_declaration: {
        export_reason: "",
        export_reason_code: "",
        inter_consignee: "N/A",
        invoice_date: "",
        invoice_number: 0,
        declared_value: 0,
        invoiceTotal_gross_weight: 0,
        invoiceTotal_net_weight: 0,
        shipment_purpose: "COMMERCIAL",
        signature_name: "N/A",
      },
      package_information: []
    });
  }, [typeShipping, setTypeShipping, setForm, form]);

  useEffect(() => {
    console.log("NEW FORM STATE");
    console.log(form);
  }, [setForm, form]
  );
  return (
    <Dashboard>
      <Grid container flexDirection="column">
        <SubHeader />
        <Grid container flexDirection={"row"}>
          <Grid item
            sx={{
              marginTop: "1rem",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "row",
              pr: "15px"
            }}
            xs={12} md={7} paddingTop={0} gap={2} spacing={2}>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Typography variant="h3" sx={{ fontSize: '1.75rem!important' }}>
                Cotizar Envío
              </Typography>
            </Stack>
            <Typography sx={{
              fontSize: "12px!important",
              color: "#677787",
              textAlign: 'center'
            }} >
              Danos esta información básica para tu cotización de envío. Puede haber variaciones en el precio por dólar, y la veracidad de la información incluida también puede causar demoras en el envío
            </Typography>
            <Stack spacing={1}>
              <FormProvider methods={methods}>
                <br />
                <Box sx={{ padding: 1 }}>
                  <Typography variant="h4">Tipo de cotización</Typography>
                  <RadioGroup
                    row
                    aria-labelledby="address_type"
                    name="is_international_shippment"
                    value={typeShipping}
                    onChange={onChangeIsinternational}
                  >
                    <FormControlLabel sx={{ mb: 0 }} key={SHIPPING_TYPE.NATIONAL} value={SHIPPING_TYPE.NATIONAL} control={<Radio />} label="Nacional" />
                    <FormControlLabel sx={{ mb: 0 }} key={SHIPPING_TYPE.INTERNATIONAL} value={SHIPPING_TYPE.INTERNATIONAL} control={<Radio />} label="Internacional" />
                  </RadioGroup>
                </Box>
                <br />
                <AddressInfoCard
                  data={form!}
                  isForQuote={true}
                  fnSave={handleDrawSave}
                  title="Origen"
                  isShipper={true}
                  typeShipping={typeShipping}
                />
                <br />
                <AddressInfoCard
                  data={form!}
                  isForQuote={true}
                  fnSave={handleDrawSave}
                  title="Destino"
                  isShipper={false}
                  typeShipping={typeShipping}
                />
                <br />
                <PackageDetails
                  fnSave={handleDrawSave}
                  shippingType={typeShipping}
                  data={form!}
                  isForQuote={true}
                />
                <br />
                {typeShipping === SHIPPING_TYPE.INTERNATIONAL ?
                  <DeclareExportInternational isForQuote={true} data={form} fnSave={handleDrawSave} /> :
                  <DeclareExportNational isForQuote={true} data={form} fnSave={handleDrawSave} />}
                <Box sx={{ mb: 2, mt: 2 }}>
                </Box>
              </FormProvider>
            </Stack>
          </Grid>
          <Grid item xs={12} paddingTop={0} md={5}>
            <Typography sx={{
              pt: 3,
              pl: 0,
              fontSize: "1rem!important",
              fontWeight: "bold",
            }}>
              Escoge transportadora
            </Typography>
            <br />
            <Stack spacing={2}>
              <CheapestOptions typeShipping={typeShipping} isForQuote={true} fnSave={handleDrawSave} data={form} />
              <Insurance data={form} fnSave={handleDrawSave} />
              <Total
                saveAsDraft={saveAsDraft}
                order={form}
                loading={isLoading}
                insure={
                  form.shipal?.insurance
                    ? form.shipal?.insurance_value
                    : 0
                }
                isForQuote={true}
                shipping={form.shipment_value}
                total={form.total_payment}
                dueDiligence={form.due_diligence}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );
};
