// import axios from "axios";
// import { encryptDataV2 } from "../../utils/token";

export class NotebookService {
  constructor(client) {
    this.client = client;
  }

  getMyNotebooks(page) {
    return this.client.get(`/address-book/my-addresses?limit=5&type=both&page=${page}`);
  }
  async getMyNotebooks2() {
    const {data} = await  this.client.get(`/address-book/my-addresses?limit=10000&type=both&page=1`);
    return data?.docs || []
  }

  login(values) {
    return this.client.post("/auth", values);
  }
  register(values) {
    return this.client.post("/auth/sing-up", values);
  }
}
