import type { ShipperProps } from "components/molecules/order-forms/types";
import { ADDRESS_TYPE } from 'enums/address';

export enum OrderStatus {
  DRAFT = "DRAFT",
  ACEPTED = "ACEPTED",
  SCHEDULED = 'SCHEDULED',
}

export enum OrderType {
  SHOPIFY = "SHOPIFY",
  SHIPAL = "SHIPAL",
}

export type ParamGetUserOrders = {
  page: number;
  size: number;
  draftOrders?: boolean;
};

export type Notebook = {
  address_line: string;
  address_type?: ADDRESS_TYPE;
  document_number?: string;
  city: string;
  country_name: string;
  country_code: string;
  company_name: string;
  postal_code: string;
  state?: string;
  email: string;
  person_name: string;
  phone_number: string;
  dane_code: string;
  is_default_address: boolean;
  _id: string;
  user: string;
  AddressType: string;
  LocationType: string;
  CompanyName: string;
  ColombianZone: string;
  ColombianZoneCode: string;
  AddressLine: string;
  PackageLocation: string;
  City: string;
  CountryCode: string;
  CountryName: string;
  PostalCode: string;
  StreetName: string;
  BuildingName: string;
  StreetNumber: string;
  Contact: {
    PersonName: string;
    PhoneNumber: string;
    Email: string;
    MobilePhoneNumber: string;
  };
  IsDefaultAddress: boolean;
};

export type MyNotebooksResponse = {
  data: Notebook[] | ShipperProps[];
  limit?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  hasMore?: boolean;
  docs?: Notebook[] | ShipperProps[];
  totalDocs?: number;
  totalPages?: number;
  page?: number;
  pagingCounter?: number;
  statusCode?: number;
  message: string;
};

export type City = {
  _id: string;
  cityName: string;
  daneCode: string;
  postalCode: string;
};

export type Order = {
  _id: string;
};

export type CitiesResponse = {
  data: City[];
  status: number;
};

export type CreateGuideResponse = {
  data: {
    errors?: string[];
    message: string;
  } | Order;
};

export type PickupError = {
  status: number;
  listErrors: string[];
};

export type CreateLabelError = {
  status?: number;
  listErrors?: any;
};

export type PickupData = {
  date: string;
  type: string;
  orderId: string;
  time?: string;
};

export type PickupDay = {
  day: string;
  date: string;
  availability: boolean;
  fullDay: string;
  monthAbbreviated: string;
  fullMonth: string;
  time: string;
  closeTime: string;
}

export type PickupDataDays = {
  next_day: PickupDay;
  today: PickupDay;
}
