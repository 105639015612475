import styled from "styled-components";
import { Container, Card } from "react-bootstrap";
import { Button } from "../../atoms/button";
import { colors } from '../../../constant/branding';

export const ContainerStyled = styled(Container)`
  max-width: 100%;
  width: 80%;
  margin: 0px;
  padding: 0px;
  padding-top: 2rem;
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
  }
`;

export const CardStyled = styled(Card)`
  border-radius: 10px;
  background-color: #fcfcfc;
  border: 1px solid #dee2e6;
  width: inherit;
  overflow: hidden;
  margin-bottom: 1rem;
`;

export const CardHeaderStyled = styled(Card.Header)`
  background-color: #f7f7f7;
  font-family: Roboto-Bold;
`;

export const CardBodyStyled = styled(Card.Body)`
  p {
    margin-bottom: 12px;
  }
  .containerText {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div:nth-child(3) {
    p {
      margin: 0px;
    }
  }
  div:nth-child(4) {
    color: purple;
    p {
      margin: 0px;
    }
  }
`;
export const ButtonStyled = styled(Button)`
  padding: 0.5rem 1.8em;
  button[disable="true"] {
  }
  ${(props) => {
    if (props.disabled) {
      return `
        background-color: red;
        `;
    }
  }}/* &:hover{
    background-color: white;
    color: ${colors.purpule};
    border: 1px solid ${colors.purpule}
} */
`;


