import React from "react"
import { Image } from "react-bootstrap"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import logo2 from "../../../assets/imgs/shipal-logo4.png"
import svg1 from "../../../assets/icons/svg1.svg"
import svg2 from "../../../assets/icons/svg2.svg"
import svg3 from "../../../assets/icons/svg3.svg"
import svg4 from "../../../assets/icons/svg4.svg"
import svg_1 from "../../../assets/icons/RSBLC.svg"
import svg_2 from "../../../assets/icons/Upper_Left_Corner.svg"
import svg_3 from "../../../assets/icons/RegsiterScreenBRC.svg"
import svg_4 from "../../../assets/icons/Upper_Right_Corner.svg"
import svg_1_1 from "../../../assets/icons/bottomleft.svg"
import svg_2_2 from "../../../assets/icons/Topleft.svg"
import svg_3_3 from "../../../assets/icons/bottomright.svg"
import svg_4_4 from "../../../assets/icons/topright.svg"
import laptop from "../../../assets/icons/HeroImage.svg"
import logo from "../../../assets/imgs/Logo.png"
import { CardTop, ContainerSectionImg } from "./styles"
import { Logo } from "../../atoms/logo"
import { Button } from "../../atoms/button"
import { useHistory } from "react-router-dom"
import { unVerify } from "../../../store/auth/auth.actions"

export const SectionImg = ({
  imgUrl,
  screen,
  children,
  showLogo,
  fullWidth = false,
  hiddeMobile = false,
  showButtons = false,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <ContainerSectionImg
      fullWidth={fullWidth}
      hiddeMobile={hiddeMobile}
      imgUrl={imgUrl}
      alt="img"
      bg={
        screen === "login" ? "#fff" : screen === "recovery" ? "#fff" : "#ECEBFC"
      }
    >
      <Image
        src={
          screen === "recovery" ? svg_4_4 : screen === "login" ? svg1 : svg_4
        }
        width={fullWidth ? "7%" : "15%"}
        style={{ position: "absolute", top: 0, right: 0 }}
      />{" "}
      {showLogo && (
        <div
          style={{
            margin: "-11px -33px 11px",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: "17%",
          }}
        >
          <Logo
            srcUrl={logo2}
            alt="logo-shipal"
            heigth="120"
            style={{ margin: " auto" }}
          />
        </div>
      )}
      {showButtons && (
        <div
          style={{
            margin: "40px 15px 11px",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            right: "17%",
          }}
        >
          <Button
            type="button"
            onClick={() => history.push("/login")}
            justText
            Regular
            style={{
              padding: "0.6rem 2.4rem 0.6rem 2.4rem",
              margin: "0px 15px 0px 0px",
            }}
          >
            Login
          </Button>
          <Button
            type="button"
            onClick={() => {
              dispatch(unVerify())
              history.push("/register")
            }}
            purple
            outline
            Regular
            style={{ padding: "0.6rem 2.4rem 0.6rem 2.4rem" }}
          >
            Register
          </Button>
        </div>
      )}
      <Image
        src={
          screen === "recovery" ? svg_1_1 : screen === "login" ? svg2 : svg_1
        }
        width={fullWidth ? "7%" : "15%"}
        style={{ position: "absolute", bottom: 0, left: 0 }}
      />
      <Image
        src={
          screen === "recovery" ? svg_2_2 : screen === "login" ? svg3 : svg_2
        }
        width={fullWidth ? "7%" : "15%"}
        style={{ position: "absolute" }}
      />
      <Image
        src={
          screen === "recovery" ? svg_3_3 : screen === "login" ? svg4 : svg_3
        }
        width={fullWidth ? "7%" : "15%"}
        style={{ position: "absolute", bottom: 0, right: 0 }}
      />
      {children ? (
        <>{children}</>
      ) : screen !== "login" ? (
        <CardTop>
          <h2
            style={{
              fontWeight: 700,
            }}
          >
            ¡Con Shipal envía tu marca al mundo!
          </h2>
          <Image src={laptop} width="60%" style={{ margin: "40px 0px" }} />
          <p
            style={{
              fontSize: "1.5em !important",
              maxWidth: "59%",
            }}
          >
            Suscribete gratis y obtén tarifas increibles con las mejores
            transportadoras del mercado.
          </p>
          <p
            style={{
              fontSize: "1.5em !important",
              fontWeight: 700,
              paddingTop: "30px",
            }}
          >
            Juntos llegamos
          </p>
        </CardTop>
      ) : (
        <CardTop>
          <Image src={logo} width="45%" style={{}} />

          <h2>¡Bienvenido de nuevo!</h2>
          <p>
            Estamos listos para procesar tus envíos a cualquier lugar del mundo
            de la manera mas fácil y divertida
          </p>
        </CardTop>
      )}
    </ContainerSectionImg>
  )
}

SectionImg.propTypes = {
  imgUrl: PropTypes.string,
}
