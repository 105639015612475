import { client } from "utils/interceptors";

const servicePath = '/address-book';

export const createAddress = async(values: any) => client.post( `${servicePath}/create`, values);

export const getUserAddressBookList = async( type: string, page: number = 1, limit: number = 10) => (await client.get(`${servicePath}/my-addresses`, { params: {
    type,
    page,
    limit
}}))?.data;

export const updateAddress = async(id: string, values: any) => client.patch( `${servicePath}/update/${id}`, values);

export const getAddressById = async( id: string) => (await client.get(`${servicePath}/get-by-id/${id}`))?.data?.data;

export const deleteAddress = async( id: string) => (await client.delete(`${servicePath}/delete/${id}`))?.data;

export const getDefaultAddress = async() => (await client.get(`${servicePath}/shipper/default-address`))?.data;