import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Link
} from "@mui/material";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { PaymentError } from "../../../../commons/paymentError";

import { createGuide } from "services/serviceV2/orders";
import { styles } from "../packageDetailsCard/styles";
import { style } from "./style";
import { Due_diligenceProps } from "components/molecules/order-forms/types";
import { checkCompletedDueDiligence } from "utils/dueDiligenceCheck";
import { currentFormat } from "utils/convert";
import { TitleCard } from "../atoms/titleCard";
import { PaymentButton } from "components/molecules/payment-button";
import type { ButtonProps } from "@mui/material";

type Props = {
  loading: boolean;
  shipping?: number;
  insure?: number;
  total?: number;
  isView?: boolean;
  dueDiligence?: Due_diligenceProps;
  order: any;
  isForQuote?: boolean;
  saveAsDraft?: (() => void);
};

type Params = {
  orderId?: string;
};

type MutationError = {
  status: number;
  listErrors: string[];
};

export const Total = ({
  loading,
  total = 0,
  insure = 0,
  shipping,
  isView,
  dueDiligence,
  order,
  saveAsDraft,
  isForQuote
}: Props) => {
  const history = useHistory();
  const { orderId } = useParams<Params>();
  const queryClient = useQueryClient();

  const { mutate, error } = useMutation<void, MutationError, string>(
    (id: string) => createGuide(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orders", "draft"] });
        queryClient.invalidateQueries({ queryKey: ["orders", "draft", 1] });
        queryClient.invalidateQueries({ queryKey: ["orders", "completed"] });
        queryClient.invalidateQueries({ queryKey: ["orders", "completed", 1] });

        history.push(`/shipments/realizados`);
      },
    }
  );
  const textFiledStyle = useMemo(
    () => ({
      fontSize: "12px!important",
      color: "#677787",
    }),
    []
  );

  const cancelButtonStyles = useMemo<ButtonProps["sx"]>(
    () => ({
      color: "black",
    }),
    []
  );

  return (
    <>
      <Card sx={{ px: "0px !important", py: 0 }}>
        <TitleCard title="Resumen" />

        <CardContent>
          <Grid container sx={{ justifyContent: loading ? "center" : "end" }}>
            {loading ? (
              <Stack justifyContent="center" alignItems="center">
                <CircularProgress />
              </Stack>
            ) : (
              <Stack display="flex" flexDirection="column" gap="8px">
                <Box sx={styles.boxText}>
                  <Typography sx={textFiledStyle} >
                    Envío:
                  </Typography>
                  <Typography sx={style.value}>
                    {currentFormat(shipping || 0)}
                  </Typography>
                </Box>

                <Box sx={styles.boxText}>
                  <Typography sx={textFiledStyle} >
                    Seguro:
                  </Typography>
                  <Typography sx={style.value}>
                    {currentFormat(insure || 0)}
                  </Typography>
                </Box>

                <Box sx={styles.boxText}>
                  <Typography sx={{ ...textFiledStyle, fontWeight: 'bold' }} variant="subtitle2">
                    Total:
                  </Typography>
                  <Typography variant="subtitle2" sx={style.value}>
                    {currentFormat(total)}
                  </Typography>
                </Box>


                <Box
                  display="flex"
                  sx={{ marginTop: 2, alignSelf: "end", gap: "1rem" }}
                >
                  {!isView &&
                    Boolean(total) &&
                    dueDiligence &&
                    checkCompletedDueDiligence(dueDiligence) && (isForQuote ?
                      <Button variant="contained" startIcon={<SyncAltIcon />} onClick={saveAsDraft}>CONVERTIR A ENVÍO</Button> :
                      <Button variant="text" type="button" sx={cancelButtonStyles} onClick={saveAsDraft}>Guardar</Button>
                    )}
                  {!isForQuote &&
                    <PaymentButton order={order} />
                  }
                </Box>
                {!checkCompletedDueDiligence(dueDiligence!) &&
                  <Typography sx={{
                    fontSize: "0.8rem!important",
                    textAlign: 'right',
                    lineHeight: "1rem!important",
                    my: 2,
                    ml: 2,
                    maxWidth: '250px'
                  }}>
                    Para pagar debes completar los campos.
                    <br />
                    Tienes problemas?
                    <Link href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}&text=Hola!%20Tengo%20dudas%20sobre%20mi%20orden%20%20con%20el%20id%3A%20${orderId}`} target="_blank" sx={{ pl: 1, color: '#7E00FB', fontWeight: 'bold' }} color="blue">
                      Pidenos ayuda!
                    </Link>
                  </Typography>
                }
              </Stack>
            )}
          </Grid>
        </CardContent>
      </Card>
      {error && <PaymentError total={total} error={error} />}
    </>
  );
};
