import React, { useCallback, useMemo } from "react";
import {
  Button,
  Grid,
  Card,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@mui/material";
import dayjs from "dayjs";
import { currentFormat } from "utils/convert";
import { MdOutlineCheck, MdFiberManualRecord } from "react-icons/md";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { shipalLogo5, coordinadoraLogo, dhlLogo, fedexLogo } from "assets/imgs";
import { OrderStatus } from "services/serviceV2/orders/types";
const { REACT_APP_AWS_BRANCH } = process.env;

type Props = {
  orderData: {
    _id: string;
    label_image_id: string;
    airway_bill_number: string;
    createdAt: string;
    order_status: string;
    consignee: {
      country_code: string;
      city: string;
      state: string;
      postal_code: string;
    };
    client_name: string;
    due_diligence: {
      carrier_selected: boolean;
    };
    total_payment: number;
    shipal: {
      selected_carrier: string;
      insurance: boolean;
    };
    pick_up_info: {
      date: string;
    };
    is_international_shippment: boolean;
  };
  handlePickup: () => void;
};

export const OrderSummary = ({
  orderData,
  handlePickup
}: Props) => {

  const instructions = [
    'Descarga tu guía en el tab de “Envíos” e imprime dos copias para adjuntar a tu envío, recuerda que todos los envíos internacionales deben también llevar adjuntas dos copias de la factura original que deben coincidir con el contenido y valor declarado de la guía, en caso de no tener una factura a nombre de tu empresa puedes descargar nuestro template <a href="https://shipal.co/como-crear-una-factura-comercial/" target="_blank" style="font-size: 16px !important">aquí</a>. Debes tener en cuenta que algunas aduanas del mundo solo aceptan facturas originales.',
    'Todos los envíos internacionales deben hacer un proceso de aduanas en el país de destino, lo que puede generar cobros de impuestos y aranceles que serán cobrados al receptor. Por favor ten en cuenta que estos cobros no dependen de la transportadora y deben ser pagados obligatoriamente para la entrega del paquete.',
    'Si requieres programar una recolección lo podrás hacer desde el tab de envíos, es muy importante tener tu envío listo para la fecha de la solicitud pues generalmente los couriers no esperan más de 5 minutos en la dirección de recolección.',
    'Según las normativas vigentes de envíos internacionales existen “productos prohibidos” de enviar, puedes consultar la lista completa <a href="https://shipal.co/objetos-prohibidos" target="_blank" style="font-size: 16px !important">aquí</a> para evitar tener problemas con tu envío.',
    'Recuerda que los envíos internacionales están sujetos a cargos extras tales como: áreas remotas o cambios de direcciones en destino en caso de ser solicitadas por el receptor. Estos cargos serán cobrados por Shipal al responsable de la cuenta en Colombia.',
    '¡MUY IMPORTANTE! La información suministrada en el momento de la creación de la guía debe ser real y exacta, cualquier error o falta de información puede causar retraso en el tránsito del paquete, sobrecargos en el servicio, devolución o incluso decomiso.',
    'Si tienes alguna duda sobre nuestras políticas detérminos y condiciones las puedes consultar <a href="https://shipal.co/terminos-y-condiciones" target="_blank" style="font-size: 16px !important">aquí</a> o puedes contactarte con soporte.'
  ];

  const carrierPlaceHolderStyle: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
    }),
    []
  );

  const generateLabelLink = useCallback(
    (labelId: string) =>
      `${REACT_APP_AWS_BRANCH === 'prod' ? 'https://shipment-labels-production.s3.amazonaws.com/' : 'https://shipment-labels-dev.s3.amazonaws.com/'}${labelId}`,
    []
  );

  const getCarrierLogo = (carrierLogo: string) => {
    let selectedCarrierLogo: string;

    switch (carrierLogo) {
      case "COORDINADORA":
        selectedCarrierLogo = coordinadoraLogo;
        break;
      case "DHL":
        selectedCarrierLogo = dhlLogo;
        break;
      case "FEDEX":
        selectedCarrierLogo = fedexLogo;
        break;
      default:
        selectedCarrierLogo = shipalLogo5;
        break;
    };

    return (
      <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', }}>
        <Grid item xs={2}>
          <img src={selectedCarrierLogo} style={carrierPlaceHolderStyle} alt="" />
        </Grid>
        <Grid item xs={5} sx={{ textAlign: 'left' }}>
          <Typography variant="h4">
            {carrierLogo}
          </Typography>
          <Typography variant="body1">
            {orderData?.shipal?.insurance && 'Seguro incluido'}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container sx={{
      alignItems: 'start'
    }} spacing={2}>
      <Grid item xs={7}>
        <Card sx={{ width: '100%' }}>
          <Grid container padding={2} sx={{ background: '#E1FAEF' }}>
            <Grid item xs={6}>
              <Grid container alignItems={'center'}>
                <Grid item xs={2} sx={{ background: '#fff', textAlign: 'center' }} padding={2}>
                  <MdOutlineCheck color="green" fontSize={24} />
                </Grid>
                <Grid item xs={10} sx={{ textAlign: 'left', paddingLeft: 2 }}>
                  <Typography variant="h4">
                    Tu número de guía ha sido creado
                  </Typography>
                  <Typography variant="body1">
                    Gracias por usar Shipal.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} alignItems={'center'} textAlign={'right'} paddingTop={1}>
              <Typography variant="body1" sx={{ fontSize: '1.2rem!important' }}>
                Número de Guía: <b>{orderData?.airway_bill_number} </b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={4}>
            <Grid item xs={12}>
              <Typography variant="h3" fontSize="1rem">
                Muy importante
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {instructions.map((instruction, i) =>
                  <ListItem key={i} sx={{ alignItems: 'baseline', paddingLeft: 0, color: 'gray' }}>
                    <ListItemIcon>
                      <MdFiberManualRecord />
                    </ListItemIcon>
                    <ListItemText
                      primary={<div dangerouslySetInnerHTML={{ __html: instruction }} />}
                    />
                  </ListItem>)
                }
              </List>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={5}>
        <Card sx={{ width: '100%' }}>
          <Grid container>
            <Grid xs={12} item padding={2} sx={{ background: '#FAFAFA' }}>
              <Typography variant="h4">
                Resumen de tu orden
              </Typography>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid xs={12} item>
              <Grid container padding={2}>
                <Grid xs={6} item>
                  <Typography variant="h4">
                    Fecha de pago
                  </Typography>
                  <Typography variant="body1">
                    {dayjs(orderData?.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant="h4">
                    Total
                  </Typography>
                  <Typography variant="body1">
                    {currentFormat(orderData?.total_payment)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid xs={12} item>
              <Grid container spacing={2} padding={2}>
                <Grid xs={12} item>
                  <Typography variant="h4">
                    Transportadora
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  {getCarrierLogo(orderData?.shipal.selected_carrier)}
                </Grid>
                <Grid xs={12} item>
                  <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid xs={12} textAlign={'center'} item>
                      {orderData?.order_status && orderData?.order_status === OrderStatus.SCHEDULED &&
                        <Typography><b>Fecha estimada de recolección:</b> {orderData.pick_up_info.date}</Typography>
                      }
                      {orderData?.order_status && orderData?.order_status === OrderStatus.ACEPTED &&
                        <Button onClick={() => handlePickup()} variant="outlined" >Programar recolección</Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid xs={12} item padding={4}>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid xs={12} item textAlign={'center'}>
                  <Typography variant="h4" gutterBottom>
                    ¡Descarga tu guía aqui!
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Grid xs={7} item>
                      <Button download sx={{ ":hover": { color: "white" } }} href={generateLabelLink(orderData?.label_image_id)} startIcon={<DescriptionOutlinedIcon />} variant="contained">Descargar</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
