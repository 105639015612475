import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

type SelectPropsT = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
};

export function InputSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  ...other
}: SelectPropsT) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight:
                      typeof maxHeight === "number" ? maxHeight : "unset",
                    "& .MuiMenuItem-root": {
                      px: 0,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                    },
                  }),
                },
              },
            },
            sx: { textTransform: "capitalize" },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}

        >
          {children}
        </TextField>
      )}
    />
  );
}
