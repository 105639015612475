import React from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  ContainerHeader,
  CardHeaderStyled,
  CardStyled,
  CardBodyStyled,
  ContainerIconEmail,
  ContainerStyled,
  ContainerContent,
  Switch,
  ContainerRootStyled,
} from "./styles";

import email from "../../../assets/icons/mail.png";

// import { changeNotificationState } from "../../../store/redux/actions/auth";

export const SettingNotifications = () => {
  const { notification } = useSelector((state) => state.auth);

  return (
    <ContainerRootStyled>
      <ContainerHeader>
        <p className="description">
          Mantenga a sus clientes informados con las notificaciones de envío
        </p>
      </ContainerHeader>
      <CardStyled>
        <CardHeaderStyled>
          Notificaciones del correo electronico
        </CardHeaderStyled>
        <CardBodyStyled>
          <p>
            Correo electrónico de envío: informar a los clientes sobre el envío
            y la información de seguimiento
          </p>
          <p>
            Envíe un correo electrónico de muestra a la dirección de mi cuenta
          </p>
          <ContainerContent>
            <ContainerStyled>
              <ContainerIconEmail>
                <Image src={email} />
              </ContainerIconEmail>
              <p>El autoenvío está desactivado</p>
            </ContainerStyled>
            <Switch
              onChange={(e) =>
                alert('toggle notifications bool of user')
                /* dispatch(changeNotificationState(e.target.checked)) */
              }
              checked={notification}
              type="switch"
              label={notification ? "desactivar" : "activar"}
              id="switch-active"
            />
          </ContainerContent>
        </CardBodyStyled>
      </CardStyled>
    </ContainerRootStyled>
  );
};
