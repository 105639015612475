import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from '../../../constant/branding';
import { FormInput } from "../../molecules/form-input";

export const Content = styled.div`
  .title {
    color: #000000;
    font-family: Roboto-Bold;
    letter-spacing: 0;
    line-height: 24px;
    
  }
  .description {
    color: #474747;
    font-family: Roboto-Regular;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 2rem;
  }
`;


export const StyledTextRegister = styled.p`
    font-family: Roboto-Regular;
    padding:0;
    margin:0;
`

export const StyledLogin = styled(Link) `
    font-family: Roboto-Bold;
    color: ${colors.purpule};
    text-decoration: none;
    margin-top: 2rem;
    &:hover{
      color:${colors.purpule};
    }
`

export const Styledloginfield = styled(FormInput) `
    height: 60px;
    font-size: 17px;
`